import React, { useState } from 'react';
import './AutoLinkWidget.css';
import { IonAlert } from '@ionic/react';
import { Utilities } from '../services/Utilities';
interface ContainerProps{
    text:string
}
const AutoLinkWidget : React.FC<ContainerProps> = (props)=>{
  const [showAlert, setShowAlert] = useState(false);
  const [link, setLink] = useState("#");
  const prepareAlert = (component:string) => {
    setShowAlert(true);
    setLink(component);
  }
  return (
    <div>
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Confirm'}
            message={'You are leaving the app to view ' + link + ', would you like to continue?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary'
                },
                {
                    text: 'Continue',
                    handler: () => {
                        const url = link
                        window.open(url,'_blank');
                    }
                }
            ]}
        />
        {Utilities.autoLink(props.text)?.map((component, index) => {
            if(index%2 === 0){
                var newLineSplits = component?.split("\n");
                return newLineSplits.map((line:any, idx:any) => {
                    return <React.Fragment key={'line-' + idx}>{line}{idx<(newLineSplits.length-1) && <br/>}</React.Fragment>
                });
            }
            else{
                return <a key={'link-'+index} className="clickable underline" onClick={ e => prepareAlert(component)}>{component}</a>
            }
        })}
        
    </div>
    )
}
export default AutoLinkWidget;