export class Utilities {
    public static buildQuery(queryObj:any):string{
        let queryParams = Object.assign(queryObj);
        for (let key in queryParams){
            const obj = (queryObj as any)[key];
            if (Array.isArray(obj)){
                queryParams[key] = obj.join(',');
            }
            else {
                queryParams[key] = obj as string;
            }
        } 
        return Object.keys(queryParams).filter((key:string)=>queryParams[key]).map((key:string)=>{
            return key+"="+encodeURIComponent(queryParams[key]);
        }).join("&");
    }
    public static async toBase64(aFile:any) {
        function getBase64(file:any) {
          const reader = new FileReader()
          return new Promise(resolve => {
            reader.onload = ev => {
              resolve(ev.target!.result)
            }
            reader.readAsDataURL(file)
          })
        }
        const promises = []
        promises.push(getBase64(aFile.event.target.files[0]))
      
        aFile.base64=(await Promise.all(promises))[0];
        return aFile;
    }
    public static getFileMetadata(event:any) {
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
          return;
        }
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        return {
            name:fileName,
            extension:ext
        }
    }
    public static getDateTimeRange(start:string,end:string){
        if (!start || !end){
            return {pretty:'',multiday:false,components:[]};
        }
        start = start.indexOf("Z") !== -1 ? start : start + "Z";
        end = end.indexOf("Z") !== -1 ? end : end + "Z";
        
        const month = {
            "0":"January",
            "1":"February",
            "2":"March",
            "3":"April",
            "4":"May",
            "5":"June",
            "6":"July",
            "7":"August",
            "8":"September",
            "9":"October",
            "10":"November",
            "11":"December"
        } as any;
        let daysuffix = {
            "1":"st",
            "2":"nd",
            "3":"rd"
        } as any;
        let startRange = new Date(start);
        let endRange = new Date(end);
        let startYear = startRange.getFullYear();
        let startMonth = startRange.getMonth();
        let startMonthVerb = month[startMonth+""];
        let startDay = startRange.getDate();
        let startDaySuffix = (startDay+"").slice(-1,1) in daysuffix ? daysuffix[(startDay+"").slice(-1,1)] : "th";
        let startHours = startRange.getHours();
        let startMinutes = startRange.getMinutes();
        let startAMPM = startRange.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12:true });
        let endYear = endRange.getFullYear();
        let endMonth = endRange.getMonth();
        let endMonthVerb = month[endMonth+""];
        let endDay = endRange.getDate();
        let endDaySuffix = (endDay+"").slice(-1,1) in daysuffix ? daysuffix[(endDay+"").slice(-1,1)] : "th"; 
        let endHours = endRange.getHours();
        let endMinutes = endRange.getMinutes();
        let endAMPM = endRange.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12:true });
        let components = [{
            year:startYear,
            month:startMonth,
            monthstr:startMonthVerb,
            day:startDay,
            daysuffix:startDaySuffix,
            hours:startHours,
            minutes:startMinutes
        },{
            year:endYear,
            month:endMonth,
            monthstr:endMonthVerb,
            day:endDay,
            daysuffix:endDaySuffix,
            hours:endHours,
            minutes:endMinutes
        }];
        if(startRange.getFullYear() === endRange.getFullYear() && startRange.getMonth() === endRange.getMonth() && startRange.getDate() === endRange.getDate() ){
            let rangeStr = `${startMonthVerb} ${startDay+startDaySuffix}, ${startYear} (${startAMPM}-${endAMPM})`; 
            let multiday = false;
            return {
                pretty:rangeStr,
                multiday:multiday,
                components:components
            };
        }
        else{
            let rangeStr = `${startMonthVerb} ${startDay+startDaySuffix}, ${startYear} ${startAMPM}-${endMonthVerb} ${endDay+endDaySuffix}, ${endYear} ${endAMPM}`;
            let multiday = true;
            return {
                pretty:rangeStr,
                multiday:multiday,
                components:components
            };
        }
    }
    public static autoLink(str:string) {
        if (!str){ return [];}
        let currentStr = str + "";
        let regexIter = str.matchAll(/((http|https):\/\/){0,1}[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/gmi);
        var result;
        let components = [];
        while(result = regexIter.next().value){
            const myLink = result[0];
            const parts = currentStr.split(myLink);
            const textBefore = parts[0];
            currentStr = parts.slice(1).join(myLink);
                        
            components.push(textBefore);
            components.push(myLink);
        }
        components.push(currentStr);
        return components;
    }
    public static getUrl (website:string){
        if (website && website?.indexOf('http') === -1){
            return 'http://' + website;
        }
        return website;
    }
}