import React, { useContext, useEffect, Fragment } from 'react';
import './OrganizationsList.css';
import {IonIcon, IonButton, IonList, IonLabel, IonSkeletonText, IonItem, IonListHeader, IonCard, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonImg, IonAvatar, IonText } from '@ionic/react';

import { useHistory, } from 'react-router';
import { AppContext, AppActionTypes, AppAction, AppNetworkStatusTypes } from '../contexts/AppContext';
import { heart, heartOutline } from 'ionicons/icons';
import { FavoriteDTO } from '../models/FavoriteDTO';
import { Organization } from '../models/Organization';

import { LookupAPI } from '../services/LookupAPI';
import { UserAPI } from '../services/UserAPI';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { OrgSearch } from '../models/OrgSearch';
import { Utilities } from '../services/Utilities';
import { FileAPI } from '../services/FileAPI';
interface ContainerProps {
    option?:string
}

const OrganizationsList: React.FC<ContainerProps> = ({option}) => {
    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();
    //const params = useParams<{ orgId: string; causeId: string; }>();
    const userAPI = new UserAPI(state.User);
    const organizationAPI = new OrganizationAPI(state.User);
    const lookupAPI = new LookupAPI();
    const fileAPI = new FileAPI(state?.User);
    const onInit = () => { 
        const processCauses = async () => {
            dispatch({ type: AppActionTypes.START_LOADING } as AppAction);
            try {
                let res:Organization[] = [];
                if (state?.User?.UserID && option === 'favorite'){
                    res = (await organizationAPI.getFavoriteOrganizations())
                            ?.filter((org)=>org.IsFavorite)
                            ?.sort((a,b)=>a.Name?.localeCompare(b.Name));
                }
                else {
                    if (state?.User?.UserID){
                        res = (await organizationAPI.getFavoriteOrganizations())?.sort((a,b)=>a.Name?.localeCompare(b.Name));
                    }
                    else {
                        res = (await lookupAPI.getOrganizations({} as OrgSearch))?.sort((a,b)=>a.Name?.localeCompare(b.Name));
                    }

                }
                dispatch({ type: AppActionTypes.UPDATE_ORGS, payload: { Organizations: res } });
            }
            catch (err) {
                dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (OrganizationsList)', Error: err}} as AppAction);
            }
            finally {
                dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
            }
        }
        processCauses();
        return () => {
            userAPI.cancel();
            organizationAPI.cancel();
            lookupAPI.cancel();
        };
    }
    useEffect(onInit, [state?.Lookup?.Contents]);

    const detailsOrgClicked = (ev: any, orgId: number) => {
        ev.preventDefault();
        history.push(`../../organization/details/${orgId}`);
    };
    const favoriteOrgClicked = async (ev: any, orgId: number) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!state?.User?.UserID) {
            history.push('../../register/volunteer');
            return;
        }
        const payload: FavoriteDTO = {
            UserID: state?.User?.UserID,
            OrgID: orgId
        };
        try {
            let isOrgFavorite = state?.Organizations?.find((org:Organization)=>org.OrgID === orgId)?.IsFavorite;
            if (isOrgFavorite) {
                await userAPI.deleteFavorite(payload);
            }
            else {
                await userAPI.addFavorite(payload);
            }
            dispatch({
                type: AppActionTypes.UPDATE_ORG_FAVORITE,
                payload: { OrgID: orgId, IsFavorite: !isOrgFavorite }
            });
        }
        catch (err) {
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (OrganizationsList - Favorite for Org)', Error: err}} as AppAction);
        }
        finally {
            dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
        }
    };

    return (
        <Fragment>
           <IonList  hidden={state?.NetworkStatus !== AppNetworkStatusTypes.PROGRESS}>
            {Array(20).fill(1).map((item,idx)=>
                    <IonItem detail={true} key={`list-item-${idx}`}>
                            <IonLabel>
                                <IonSkeletonText animated />
                            </IonLabel>
                    </IonItem>
            )}
            </IonList>
            <IonList className="ion-no-padding">
            {/* <IonListHeader className="causes-search-header">
                <IonLabel className="ion-text-uppercase">Organizations</IonLabel>
            </IonListHeader> */}
            {state?.Organizations?.filter((item:Organization)=>!item.Hidden)?.map((item:any, index:number) =>
 
                <IonCard>
                    <IonList class="ion-no-padding">
                        <IonItem lines="none" className="clickable" onClick={(ev:any)=>{detailsOrgClicked(ev,item?.OrgID)}}>
                            <IonAvatar  slot="start" >
                                {(
                                    (item && item?.PhotoID && item?.PhotoID!==0) ? 
                                    <IonImg src={fileAPI.buildFileEndpoint(item?.PhotoID+"",36,36)}></IonImg> : 
                                    <IonImg src="assets/organization-photo.jpg"></IonImg>
                                )}
                            </IonAvatar>
                            <IonLabel className="heading">
                                <IonText color="Primary"><h2><strong>{item?.Name}</strong></h2></IonText>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </IonCard>

                // <IonCard 
                //         className="causes-card clickable"
                //         hidden={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS} 
                //         key={'row-' + item.OrgID} 
                //         onClick={(event) => detailsOrgClicked(event, item?.OrgID)}>
                //     <IonFab horizontal="end">
                //             <IonButton className="fab-button"
                //                 color={item?.IsFavorite ? "secondary" : "light"}
                //                 fill="clear"
                //                 size="large"
                //                 onClick={(event) => favoriteOrgClicked(event, item.OrgID)}>
                //                 <IonIcon icon={item?.IsFavorite ? heart : heartOutline} />
                //             </IonButton>
                //     </IonFab>
                //     <IonImg src={(item?.PhotoID ? fileAPI.buildFileEndpoint(item.PhotoID+"",418,260):"assets/event-photo.jpg")}></IonImg>
                //     <IonCardHeader className="causes-header">
                //         <IonCardTitle className={'causes-title causes-title-'+(parseInt(''+item?.Name?.length / 5))}>{item?.Name}</IonCardTitle>
                //     </IonCardHeader>
                //     <IonCardContent className="causes-body">
                //         <IonItem detail={false} lines="none" className="causes-item">
                //         </IonItem>
                //     </IonCardContent>
                // </IonCard>
            )}
            {(state?.Organizations?.filter((item:Organization)=>!item.Hidden)?.length === 0 &&
                <IonItem>
                <IonLabel><h2>No Organization(s) Found</h2></IonLabel>
                </IonItem>
            )}
            </IonList>
    
        </Fragment>
    );
};

export default OrganizationsList;
