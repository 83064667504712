import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import './OrganizationDetailsContainer.css';
import {
  IonContent, IonLabel, IonGrid, IonRow, IonCol,
  IonButton,  IonText,  IonIcon, IonListHeader, IonImg, IonCard
} from '@ionic/react';
import { AppContext } from '../contexts/AppContext';
import { Organization } from '../models/Organization';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { FileAPI } from '../services/FileAPI';
import { arrowBackCircleSharp } from 'ionicons/icons';
import CausesList from './CausesList';
import { Cause } from '../models/Cause';
import { CauseAPI } from '../services/CauseAPI';
import { LookupAPI } from '../services/LookupAPI';
import { Utilities } from '../services/Utilities';

interface ContainerProps {}
const OrganizationDetailsContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { orgId } = useParams<{ orgId?: string; }>();
  const { state, dispatch } = useContext(AppContext);
  const [profile, setProfile] = useState<Organization>();
  const [cause, setCauses] = useState<Cause>(); // hold first cause for Contact Info
  //  const [isLoading, setIsLoading] = useState(false); // TODO: finish
  const orgApi = new OrganizationAPI(state.User);
  const couseApi = new CauseAPI(state.User);
  const lookupApi = new LookupAPI(state.User);
  const fileApi = new FileAPI(state.User);

  const onInit = () => {
    const processEffect = async () => {
      const foundOrg = await orgApi.getOrganization(parseInt(orgId || "0"));
      setProfile(foundOrg);
      let causes = new Array<Cause>();
      if (state?.User?.UserID){
        causes = await couseApi.searchCauses({ OrgID: parseInt(orgId || "0") });
      }
      else {
        causes = await lookupApi.searchCauses({ OrgID: parseInt(orgId || "0") });
      }
      if (causes.length > 0) {
        const foundCause = causes.sort((a, b) => a.CauseID - b.CauseID)[0];
        setCauses(foundCause);
      }
    };
    processEffect();
    return () => {
      orgApi.cancel();
      fileApi.cancel();
      console.log("cancelled");
    };
  }
  useEffect(onInit, []);

  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };

  
  return (
    <IonContent>
      <IonCard>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol className="ion-padding-top">
            <IonButton className="slim-button"
              color="medium"
              fill="clear"
              size="large"
              onClick={(event) => goBackClicked(event)}>
              <IonIcon icon={arrowBackCircleSharp} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <strong>Organization</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol></IonCol>
          <IonCol>
            {( (profile?.PhotoID) ? 
              <IonImg src={fileApi.buildFileEndpoint(profile?.PhotoID+"",150,150)}></IonImg> : 
              <IonImg src="assets/blank.svg"></IonImg>
            )} 
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <h1><IonText color="primary">{profile?.Name}</IonText></h1>
            <hr className="gray-separator ion-margin-start ion-margin-end" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-padding">
            {profile?.Description}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-padding">
            <a href={Utilities.getUrl(profile?.Website || '')} target="_blank">{profile?.Website}</a>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <CausesList filter="organization" orgId={parseInt(orgId || "0")} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonListHeader>
              <IonLabel className="ion-text-uppercase">Contact</IonLabel>
            </IonListHeader>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-padding">
            <IonText hidden={!cause?.ContactName}><strong>{cause?.ContactName}</strong><br/><br/></IonText>
            <IonText hidden={!cause?.ContactTitle} color="medium">{cause?.ContactTitle}<br/><br/></IonText>
            <a href={'mailto:' + (cause?.ContactEmail || profile?.Email)}>{cause?.ContactEmail || profile?.Email}<br /><br /></a>
            <a href={'tel:' + cause?.ContactPhone}>{cause?.ContactPhone}</a><br />
            <br/>
          </IonCol>
        </IonRow>
      </IonGrid>
      </IonCard>
    </IonContent>
  );
};

export default OrganizationDetailsContainer;