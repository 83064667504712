import React, { useContext, useEffect, useState } from 'react';
import './ProfileContainer.css';
import { IonIcon, IonHeader, IonContent, IonList, IonItem, IonLabel, IonButton, IonBadge, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { User } from '../models/User';
import { FileAPI } from '../services/FileAPI';
import { personOutline, businessOutline, lockOpenOutline, addCircleOutline, trophyOutline, warningOutline } from 'ionicons/icons';
import MediaWidget from '../widgets/MediaWidget';
import { Organization } from '../models/Organization';


// Note: https://ionicframework.com/blog/using-react-hooks-in-an-ionic-react-app/
interface ContainerProps {
}
const ProfileContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const [media,setMedia] = useState<File[]>([] as File[]);
  const [profile, setProfile] = useState<User>(state.User);
  /* const [isLoading, setIsLoading] = useState<Boolean>(false); */
  const userApi = new UserAPI(state.User);
  const fileApi = new FileAPI(state.User);
  
  const onInit = () => { 
    const process = async ()=>{
      const usr:User = await userApi.getUser();
      const files = (await fileApi.getFiles()).map((media)=>{ 
        return {
          name: media.Name,
          src:  media.FileID,
          fn: (src:string)=>{ return fileApi.buildFileEndpoint.bind(fileApi)(src,300,300); },
          selected: profile.PhotoID === media.FileID ? true : false 
        } as any
      })
      setMedia(files);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: usr }} as AppAction);
      return ()=>{
        userApi.cancel();
        fileApi.cancel();
        console.log("cancelled");
      };
    };
    process();
  }
  useEffect(onInit, []);

  const logoutClicked = async (ev: any)=>{
    dispatch({ type: AppActionTypes.LOGOUT } as AppAction);
    history.push('../login');
    return true;
  }
  const handleProfileChange = (name: string, value: string) => {
    let usr: any = {
      ...profile,
      [name]: value
    };
    setProfile(usr as User);
  };

  const doProfileSave = async (user:User) =>{
    //setIsLoading(true);
    // clean up and reconstruct payload
    const usr: User = user
    try {
      await userApi.updateUserProfile(usr);
      setProfile(usr);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: usr } } as AppAction);
      dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"User profile updated"}} as AppAction);
    }
    catch (err) {
      console.log(err);
      alert('error (User)');
    }
    finally {
      //setIsLoading(false);
    }
  }
  return (
    <IonContent>
      <IonList>
        <IonItem lines="none" className="ion-text-center ion-no-padding ion-no-margin">
          <MediaWidget 
            value={(media as any)}
            post={(async (media)=>{ return await fileApi.storeFile(media)})}
            put={(async (media)=>{ 
              handleProfileChange('PhotoID',media.src);
              doProfileSave({
                ...state.User,
                PhotoID:media.src
              });
              return media;
            })}
            restriction={({
              extensions:["png","gif","jpg","jpeg"],
              size:5242880
            })}
            slim={true}
            avatar={true}
            defaultImg="assets/profile.jpg"
            ></MediaWidget>
        </IonItem>
        <IonItem className="ion-text-center">
          <IonLabel className="ion-text-center ion-no-padding ion-no-margin ion-margin-bottom">
            <h1><strong>{ state.User.FirstName } { state.User.LastName }</strong></h1> 
            <IonText color="primary">
              <h2><strong>{(state?.User?.UserType === 'V') ? "Volunteer":(state?.User?.UserType === 'O' ? "Organization":"Admin")}</strong></h2>
            </IonText>
          </IonLabel>
        </IonItem>
        { (state.User?.Organizations?.length > 0 && state.User?.Organizations?.find((org:Organization)=>!org.IsConfirmed)) &&
          <IonItem className="ion-text-center">
            <IonLabel className="ion-text-center ion-no-padding ion-no-margin ion-margin-bottom ion-margin-top">
              <IonText color="warning" className="ion-text-wrap">
                <IonIcon icon={warningOutline} size="large"></IonIcon><br/>
                <strong>Your organization verification process is in progress. All posted events will be published after verification is completed.</strong> 
              </IonText>
              </IonLabel>
          </IonItem>
        }
        {(state.User?.Tags?.length > 0 &&
        <IonItem>
            <IonLabel className="ion-padding-top">
                <div className="ion-text-wrap">
                  {(state.User?.Tags?.map((tag:string)=><IonBadge className="tag" key={tag}>{tag}</IonBadge>))}
                </div>
            </IonLabel>
        </IonItem>
        )}
        <IonItem lines="none">
          <IonHeader><strong>Account Settings</strong></IonHeader>
        </IonItem>
        <IonItem routerLink="user/profile">
            <IonLabel>Personal Information</IonLabel>
            <IonIcon icon={personOutline} slot="start" />
        </IonItem>
        <IonItem routerLink="volunteer">
          <IonLabel>Registered</IonLabel>
          <IonIcon icon={trophyOutline} slot="start" />
        </IonItem>
        {( state.User?.Organizations?.length > 0 ? <IonItem routerLink="user/organizations">
          <IonLabel>Organizations and Events</IonLabel>
          <IonIcon icon={businessOutline} slot="start" />
        </IonItem> : null )}
        {( state.User?.Organizations?.length > 0 ? <IonItem routerLink={"organization/"+state.User.Organizations[0].OrgID+"/cause"}>
          <IonLabel>Add Event</IonLabel>
          <IonIcon icon={addCircleOutline} slot="start" />
        </IonItem> : null )}
        <IonItem routerLink="password">
          <IonLabel>Change Password</IonLabel>
          <IonIcon icon={lockOpenOutline} slot="start" />
        </IonItem>
        <IonButton className="ion-margin-top ion-margin-bottom" color="danger" expand="full" onClick={logoutClicked}>Logout</IonButton>
      </IonList>
    </IonContent>
  );
};

export default ProfileContainer;
