import React, { useContext, useState, useEffect, Fragment } from 'react';
import './NewLandingContainer.css';
import { IonImg, IonContent, IonRow, IonCol, IonLabel, IonGrid, IonCardHeader, IonCardContent, IonCard, IonText, IonButton } from '@ionic/react';
import { AppAction, AppActionTypes, AppContext } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { useHistory } from 'react-router';
import { Geolocation } from '@capacitor/core';
import { Coordinate } from '../models/Coordinate';
import { FileAPI } from '../services/FileAPI';

interface ContainerProps {
}
const LandingContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { state, dispatch} = useContext(AppContext);
  //const [ hoursServed, setHoursServed ] = useState(0);
  const [ volunteers, setVolunteers] = useState(0);
  const hsFinal = parseInt(state?.Lookup?.Contents?.HOURS_SERVED_NUM?.replace(/,/gi,''));
  const voFinal = parseInt(state?.Lookup?.Contents?.VOLUNTEERS_NUM?.replace(/,/gi,''));
  const userAPI = new UserAPI(state?.User);
  const fileAPI = new FileAPI(state?.User);
  const landingContents = [
    {
      Title: state?.Lookup?.Contents?.FEATURED_ITEM_1_TITLE,
      Body: state?.Lookup?.Contents?.FEATURED_ITEM_1_BODY,
      ID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_1_ID)
    },
    {
      Title: state?.Lookup?.Contents?.FEATURED_ITEM_2_TITLE,
      Body: state?.Lookup?.Contents?.FEATURED_ITEM_2_BODY,
      ID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_2_ID)
    }
  ];
  
  // const videoRef = useRef(null);
  const onInit = () => { 
    let timer:NodeJS.Timeout;
    setTimeout(()=>{
      const totalDuration = 5000;
      const max = 31; // Note: avoid crisp division like 30
      let counter = 0;
      timer = setInterval(()=>{
        if (hsFinal && hsFinal > 0){
          //setHoursServed(parseInt('' + hsFinal * counter / max));
          setVolunteers(parseInt('' + voFinal * counter / max));
          counter++;
        }
        if (counter >= max){
          //setHoursServed(hsFinal);
          setVolunteers(voFinal);
          clearInterval(timer);
        }
      },totalDuration/max);

      // // Play Video
      // (videoRef.current as any)?.play();
    },1000);
    
    // Geocoding Ahead
    const initCoord = async () => {
      try {
        const info =  await Geolocation.getCurrentPosition();
        const coord = (info?.coords as Coordinate);
        if (coord){          
          dispatch({ type: AppActionTypes.UPDATE_COORDINATE, payload: { Coordinate: coord} } as AppAction);
        }
      }
      catch {
        // do nothing
      }
    };
    initCoord();

    return ()=>{
      
      fileAPI.cancel();
      clearInterval(timer);
    };
  }
  useEffect(onInit, [state?.Lookup?.Contents]);
  const cardClicked = (ev:any, index:Number) => {
    ev.preventDefault();
    if (index > 0){
      history.push('content');
    }
  }
  const volunteerClicked = (ev:any) => {
    ev.preventDefault();
    history.push('./search', {viewType:'search'});
    return false;
  }
  const orgClicked = (ev:any) => {
    ev.preventDefault();
    history.push('./search', {viewType:'organizations-list'});
    return false;
  }
  return (
    <IonContent slot="fixed" className="page background-tint">
      <IonImg src="assets/logo2.png" className="new-logo"></IonImg>
      <IonGrid className="ion-no-margin">
        <IonRow><IonCol><br/></IonCol></IonRow>
        <IonRow>
          <IonCol>
            <h1 className="ion-text-center ion-text-capitalize ion-no-margin landing-header">
              <strong>
              VOLUNTEER OPPORTUNITIES FOR YOU.
              </strong>
            </h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <label className="ion-text-center ion-text-capitalize ion-no-margin landing-subheader">
                LOOKING FOR OPPORTUNITIES?
            </label>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton color="warning" expand="block" className="ion-text-uppercase landing-button" size="large" 
            onClick={(event)=>volunteerClicked(event)}><strong>Volunteer</strong></IonButton>
          </IonCol>
        </IonRow>
        <IonRow><IonCol><br/></IonCol></IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <label className="ion-text-center ion-text-capitalize ion-no-margin landing-subheader">
             HAVE AN OPPORTUNITY TO SHARE?
            </label>
          </IonCol>
        </IonRow>
        <IonRow>
        <IonCol className="ion-text-center" >
            <IonButton color="primary" expand="block" className="ion-text-uppercase landing-button" size="large" 
            onClick={(event)=>orgClicked(event)}><strong>Organizations</strong></IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <h1 className="ion-text-center ion-text-capitalize ion-no-margin landing-header">
              <strong>
              SERVING 970 MILLION VOLUNTEERS
              </strong>
            </h1>
            </IonCol>
        </IonRow>
        <IonRow><IonCol><br/></IonCol></IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default LandingContainer;
