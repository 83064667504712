//https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
//https://medium.com/@thehappybug/using-react-context-in-a-typescript-app-c4ef7504c858
//https://hackernoon.com/how-to-use-the-new-react-context-api-fce011e7d87

// https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/AppContext.tsx
import React, { createContext, useReducer } from "react";
import * as Sentry from '@sentry/browser';
import {User} from '../models/User';
import {Lookup} from '../models/Lookup';
import { UserNonce } from "../models/UserNonce";
import { Cause } from "../models/Cause";
import { Notification } from "../models/Notification";
import { ErrorTypes } from "../services/BaseAPI";
import { Organization } from "../models/Organization";
import { Coordinate } from "../models/Coordinate";

// Reference: https://redux.js.org/recipes/usage-with-typescript

/////////////////////////////////////////////////////////////////////////////////////////////////
// State
interface IAppState {
  User: User;
  Lookup: Lookup;
  UserNonce: UserNonce;
  Causes:Array<Cause>;
  Organizations:Array<Organization>;
  Notification:Notification;
  NetworkStatus:AppNetworkStatusTypes;
  Coordinate:Coordinate;
  // other stored properties...
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// Action
export enum AppActionTypes {
  LOGOUT = 'ACTION_LOGOUT',
  UPDATE_PROFILE = 'ACTION_UPDATE_PROFILE',
  LOOKUP = 'ACTION_LOOKUP',
  UPDATE_USER_NONCE = 'ACTION_UPDATE_USER_NONCE',
  UPDATE_USER_ORGANIZATIONS = 'ACTION_UPDATE_USER_ORGANIZATIONS',
  UPDATE_CAUSES = 'ACTION_UPDATE_CAUSES',
  UPDATE_ORGS = 'ACTION_UPDATE_ORGS',
  UPDATE_CAUSE_FAVORITE = 'ACTION_UPDATE_CAUSE_FAVORITE',
  UPDATE_ORG_FAVORITE = 'ACTION_UPDATE_ORG_FAVORITE',
  UPDATE_CAUSE_VOLUNTEER= 'ACTION_UPDATE_CAUSE_VOLUNTEER',
  UPDATE_CAUSES_SEARCH = 'ACTION_UPDATE_CAUSES_SEARCH',
  UPDATE_ORGS_SEARCH = 'ACTION_UPDATE_ORGS_SEARCH',
  UPDATE_COORDINATE = 'ACTION_UPDATE_COORDINATE',
  RESET_SEARCH = 'ACTION_RESET_SEARCH',
  SHOW_REGULAR_MESSAGE = 'ACTION_SHOW_REGULAR_MESSAGE',
  SHOW_WARNING_MESSAGE = 'ACTION_SHOW_WARNING_MESSAGE',
  HANDLE_ERROR_MESSAGE = 'ACTION_HANDLE_ERROR_MESSAGE',
  HIDE_MESSAGE = 'ACTION_HIDE_MESSAGE',
  START_LOADING = 'ACTION_START_NETWORK_LOADING',
  END_LOADING = 'ACTION_END_NETWORK_LOADING',
  NETWORK_ONLINE= 'ACTION_NETWORK_ONLINE',
  NETWORK_OFFLINE= 'ACTION_NETWORK_OFFLINE',
}

// Status
export enum AppNetworkStatusTypes {
  READY = 'NETWORK_STATUS_READY',
  PROGRESS = 'NETWORK_STATUS_PROGRESS',
  OFFLINE = 'NETWORK_STATUS_OFFLINE',
}

export interface AppAction {
  type: string,
  payload: any
}

// TODO: typing action - expand this later. This could be too complex for now
//export const ACTION_LOGIN = 'ACTION_LOGIN';
//export const ACTION_LOGOUT = 'ACTION_LOGOUT';

// interface LoginAction {
//   type: typeof ACTION_LOGIN 
//   payload: IAppState
// }
// interface LogoutAction {
//   type: typeof ACTION_LOGOUT 
// }
// export type AppActionTypes = LoginAction | LogoutAction;


/////////////////////////////////////////////////////////////////////////////////////////////////
// Reducer
const reducer = (state:IAppState, action:AppAction) => {
  console.log("reducer",action.type);
  if (action.type === AppActionTypes.LOGOUT){
    localStorage.clear();
    return {
      ...state,
      User: null
    }
  }
  else if (action.type === AppActionTypes.UPDATE_PROFILE){
    localStorage.setItem('User',JSON.stringify(action.payload.User));
    Sentry.setUser({id: action?.payload?.User?.UserID});
    return {
      ...state,
      User: action.payload.User
    }
  }
  else if (action.type === AppActionTypes.UPDATE_COORDINATE){
    return {
      ...state,
      Coordinate: action.payload.Coordinate
    }
  }
  else if(action.type === AppActionTypes.LOOKUP){
    return {
      ...state,
      Lookup: action.payload.Lookup
    };
  }
  else if(action.type === AppActionTypes.UPDATE_USER_NONCE){
    return {
      ...state,
      UserNonce: action.payload.UserNonce
    }
  }
  else if(action.type === AppActionTypes.UPDATE_CAUSES){
    return {
      ...state,
      Causes: action.payload.Causes,
    };
  }
  else if(action.type === AppActionTypes.UPDATE_ORGS){
    return {
      ...state,
      Organizations: action.payload.Organizations,
    };
  }
  else if(action.type === AppActionTypes.UPDATE_CAUSE_FAVORITE){
    return {
      ...state,
      Causes: state?.Causes.map((cause:Cause)=>{
        if (cause.CauseID === action.payload.CauseID){
          cause.IsFavorite = action.payload.IsFavorite;
        }
        return cause;
      })
    };
  }
  else if(action.type === AppActionTypes.UPDATE_ORG_FAVORITE){
    return {
      ...state,
      Organizations: state?.Organizations.map((org:Organization)=>{
        if (org.OrgID === action.payload.OrgID){
          org.IsFavorite = action.payload.IsFavorite;
        }
        return org;
      }),
    };
  }
  else if(action.type === AppActionTypes.UPDATE_CAUSE_VOLUNTEER){
    return {
      ...state,
      Causes: state?.Causes.map((cause:Cause)=>{
        if (cause.CauseID === action.payload.CauseID){
          cause.IsVolunteer = action.payload.IsVolunteer;
        }
        return cause;
      })
    };
  }
  else if(action.type === AppActionTypes.UPDATE_CAUSES_SEARCH){
    const keyword = action.payload.keyword || '';
    const reg = new RegExp(keyword,"gi");
    return {
      ...state,
      Causes: state?.Causes.map((cause:Cause)=>{
        cause.Hidden = true;
        if (!action.payload.keyword
           || action.payload.keyword.length < 2
           || cause.Title.search(reg) > -1
           || cause.Body.search(reg) > -1
           || cause.City?.search(reg) > -1
           || cause.OrgName?.search(reg) > -1
           || cause.Tags?.reduce((acc,cur)=>{ return (cur.search(reg) > -1) ? acc+1 : acc; },-1) > -1
           || ((keyword.length === 2) && cause.State.toUpperCase() === keyword.toUpperCase())
           || cause.Zip?.search(reg) > -1){
          cause.Hidden = false;
        }
        return cause;
      })
    };
  }
  else if(action.type === AppActionTypes.UPDATE_ORGS_SEARCH){
    const reg = new RegExp(action.payload.keyword,"gi");
    return {
      ...state,
      Organizations: state?.Organizations.map((org:Organization)=>{
        org.Hidden = true;
        if (!action.payload.keyword
           || action.payload.keyword.length < 3
           || org.Name.search(reg) > -1){
          org.Hidden = false;
        }
        return org;
      })
    };
  }
  else if (action.type === AppActionTypes.RESET_SEARCH){
    const causes = state?.Causes?.map((item)=>{
      item.Hidden = false;
      return item;
    });
    return {
      ...state,
      Causes: causes,
    }
  }
  else if(action.type === AppActionTypes.SHOW_REGULAR_MESSAGE){
    return {
      ...state,
      Notification:{
        Message: action.payload.Message,
        Color: 'dark', 
        Duration: 4000,
        Visible: true
      } as Notification
    };
  }
  else if(action.type === AppActionTypes.SHOW_WARNING_MESSAGE){
    return {
      ...state,
      Notification:{
        Header:action.payload.Title || '',
        Message: action.payload.Message,
        Color: 'warning', 
        Duration: 4000,
        Visible: true
      } as Notification
    };
  }
  else if(action.type === AppActionTypes.HANDLE_ERROR_MESSAGE){
    // TODO: Handling more selectively for Cancel, Sentry etc
    // Only ignore if it's canceled ones
    if (action.payload.Error?.ErrorType === ErrorTypes.CANCEL){
      return state; // no action
    }
    if (!action.payload.Error?.message){
      console.log('unknown error',JSON.stringify(action.payload.Error));
      return state; // noop? sometimes get exception with just "{}"
    }
    Sentry.captureException(action.payload.Error);
    return {
      ...state,
      Notification:{
        Header:'ERROR',
        Message: `${action.payload.Message || 'ERROR'} - ${action.payload?.Error?.ErrorType} ${action.payload?.Error?.message} Please ensure you are connected to the internet and try again.`,
        Color: 'danger', 
        Duration: 4000,
        Visible: true
      } as Notification
    };
  }
  else if(action.type === AppActionTypes.HIDE_MESSAGE){
    return {
      ...state,
      Notification:{
        Visible: false
      } as Notification
    };
  }
  else if(action.type === AppActionTypes.START_LOADING){
    return {
      ...state,
      NetworkStatus : AppNetworkStatusTypes.PROGRESS
    };
  }
  else if(action.type === AppActionTypes.END_LOADING || action.type === AppActionTypes.NETWORK_ONLINE){
    // TOOD: log if ONLINE
    return {
      ...state,
      NetworkStatus : AppNetworkStatusTypes.READY
    };
  }
  else if(action.type === AppActionTypes.NETWORK_OFFLINE){
    // TOOD: log
    return {
      ...state,
      NetworkStatus : AppNetworkStatusTypes.OFFLINE
    };
  }
  else {
    throw new Error('Invalid action specified at reducer');
  }

};

/////////////////////////////////////////////////////////////////////////////////////////////////
// Context
// Note : This AppState can contain multiple reducers see combineReducers https://redux.js.org/recipes/usage-with-typescript
type AppState = ReturnType<typeof reducer>;

interface AppContextState {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
};

const initialState:AppState = {
  User:({} as User),
  Lookup:({} as Lookup),
  UserNonce:({} as UserNonce),
  Causes:([] as Array<Cause>),
  Organizations:([] as Array<Organization>),
  Notification: ({} as Notification),
  NetworkStatus: AppNetworkStatusTypes.READY,
  Coordinate: ({} as Coordinate),
};

const AppContext = createContext<AppContextState>({
  state: initialState,
  dispatch: () => undefined
});

/////////////////////////////////////////////////////////////////////////////////////////////////
// Provider
const AppContextProvider: React.FC = (props => {
  let loadedState = initialState;
  let usrJSON = localStorage.getItem('User');
  if (usrJSON){
    const usrObj = JSON.parse(usrJSON);
    loadedState = {
      ...loadedState,
      User:usrObj
    }
    Sentry.setUser({id: usrObj?.UserID});
  }
  const [store, dispatch] = useReducer(reducer, loadedState);

  return (
    <AppContext.Provider value={{
      state: store,
      dispatch
    }}>
      {props.children}
    </AppContext.Provider>
  )
});
const AppContextConsumer = AppContext.Consumer;
export { AppContext, AppContextProvider, AppContextConsumer };