import React, { useEffect, useState, useContext } from 'react';
import './LoginContainer.css';
import { IonContent, IonImg, IonList, IonItem, IonLabel, IonGrid, IonIcon, IonRow, IonCol, IonButton, IonInput, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { UserAPI } from '../services/UserAPI';
import { AuthDTO } from '../models/AuthDTO';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { keyOutline, mailOutline } from 'ionicons/icons';

interface ContainerProps {
  role: string;
}
const LoginContainer: React.FC<ContainerProps> = () => {
  const userApi = new UserAPI();
  const { dispatch } = useContext(AppContext);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [message, setMessage] = useState<string>();
  const history = useHistory();
  
  const onInit = () => { 
    return () => {
      userApi.cancel();
      console.log("cancelled");
    };
  }
  useEffect(onInit, []);

  const loginClicked = async (ev: any) => {
    ev.preventDefault();

    if (!email || !password) {
      setMessage("Please enter Email and Password");
      return;
    }
    const payload: AuthDTO = {
      Username: email!,
      Password: password!
    };
    try {
      // TODO: Spinner on
      const usr = await userApi.loginUser(payload);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: usr } } as AppAction);
      history.push('search');
    }
    catch (err) {
      setMessage("ERROR: " + (err as Error)?.message);
    }
    finally {
      // TODO: spinner off
    }
    return true;
  };
  const registerClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('home')
  };
  const forgotPasswordClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('forgotpassword')
  };
  return (
    <IonContent className="page background-tint">
      <IonImg src="assets/logo2.png" className="new-logo"></IonImg>
      <IonGrid className="ion-no-margin">
        <IonRow><IonCol><br/></IonCol></IonRow>
        <IonRow>
          <IonCol>
            <h1 className="ion-text-center ion-text-capitalize ion-no-margin login-header">
              <strong>
              Login
              </strong>
            </h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonItem className="login-text">
            <IonInput required placeholder="Email" type="email" value={email} 
            onIonChange={e => setEmail(e.detail.value!)}>
            </IonInput>
            <IonIcon icon={mailOutline} slot="start"/>
            </IonItem>

          </IonCol>
        </IonRow>
        <IonRow><IonCol><br/></IonCol></IonRow>
        <IonRow>
        <IonCol className="ion-text-center" >
          <IonItem className="login-text">
            <IonInput required placeholder="Password" type="password" value={password} 
            onIonChange={e => setPassword(e.detail.value!)}></IonInput>
            <IonIcon icon={keyOutline} slot="start"/>
          </IonItem>
        </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton color="primary" expand="block" className="ion-text-uppercase login-button" size="large" 
            onClick={(event)=>loginClicked(event)}><strong>LOGIN</strong></IonButton>
            {(message && <IonItem lines="none">
                            <IonText color="danger">{message}</IonText>
                          </IonItem>)}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
          <IonLabel className="text-link clickable ion-text-center" color="primary" onClick={(event) => forgotPasswordClicked(event)}>Forgot password?</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow><IonCol><hr className="white-separator-md"/></IonCol></IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton color="warning" expand="block" className="ion-text-uppercase landing-button" size="large" 
            onClick={(event)=>registerClicked(event)}><strong>JOIN NOW</strong></IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default LoginContainer;
