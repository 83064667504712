import React, { useEffect, useState, useContext } from 'react';
import './AdminOrganizationContainer.css';
import { useHistory, useParams } from 'react-router';
import { IonContent, IonLabel, IonGrid, IonRow, IonCol, IonButton, 
  IonCard, IonCardHeader, 
  IonCardContent, IonIcon, IonText, IonChip, IonAvatar, IonImg, IonList, IonItem, IonFab, IonFabButton } from '@ionic/react';
import { AppContext } from '../contexts/AppContext';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { Organization } from '../models/Organization';
import { FileAPI } from '../services/FileAPI';
import { checkmarkCircleOutline, shieldOutline, closeCircleOutline, shieldCheckmarkOutline, listOutline, createOutline, checkmarkCircle, radioButtonOff, shieldCheckmark, businessOutline, trash,  newspaperOutline } from 'ionicons/icons';
interface ContainerProps {}
const AdminOrganizationContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const params = useParams<{ workbench: string; view: string; }>();
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState(state?.User?.Organizations as Organization[]);
  const orgApi = new OrganizationAPI(state.User);
  const fileAPI = new FileAPI(state?.User);

  const onInit = () => { 
    refreshOrgInfo();
    return ()=>{
      orgApi.cancel();
      fileAPI.cancel();
    }
  }
  useEffect(onInit, [params.view]);

  const newSortFn = (a:Organization,b:Organization)=>{
    return b.CreatedOn.localeCompare(a.CreatedOn);
  };
  const approvedSortFn = (a:Organization,b:Organization)=>{
    return a.IsActive===b.IsActive ? 0 : a.IsActive ? -1 : 1;
  };
  const verifiedSortFn = (a:Organization,b:Organization)=>{
    return a.IsConfirmed===b.IsConfirmed ? approvedSortFn(a,b) : a.IsConfirmed ? -1 : 1;
  }

  const refreshOrgInfo = async ()=>{
    setIsLoading(true)
    let masterOrgs = await orgApi.getOrganizations();
    if(params.view.toLowerCase() === "new"){
      masterOrgs.sort(newSortFn);
    }
    else if(params.view.toLowerCase() === "deactivated"){
      masterOrgs = masterOrgs.sort(approvedSortFn).reverse().filter((org)=>{
        return org.IsActive===false
      });
    }
    else if(params.view.toLowerCase() === "activated"){
      masterOrgs = masterOrgs.sort(approvedSortFn).filter((org)=>{
        return org.IsActive===true
      });
    }
    else if(params.view.toLowerCase() === "unverified"){
      masterOrgs = masterOrgs.sort(verifiedSortFn).reverse().filter((org)=>{
        return org.IsConfirmed===false
      });
    }
    else if(params.view.toLowerCase() === "verified"){
      masterOrgs = masterOrgs.sort(verifiedSortFn).filter((org)=>{
        return org.IsConfirmed===true
      });
    }
    setOrganizations(masterOrgs);
    setIsLoading(false);
  }
  const detailsClicked = async (ev:any,organization:Organization)=>{
    history.push('../../organization/profile',{organization:organization});
  }
  const causeListClicked = async (ev:any,organization:Organization)=>{
    history.push('../../organization/'+organization.OrgID+'/causes');
  }
  const contentClicked = async (ev:any)=>{
    history.push('../../admin/content');
  }
  const approveToggle = async ( ev:any,organization:Organization)=>{
    await orgApi.approveOrgProfileToggle(organization);
    refreshOrgInfo();
  }
  const verifyToggle = async (ev:any,organization:Organization)=>{
    await orgApi.verifyOrgProfileToggle(organization);
    refreshOrgInfo();
  }
  const deleteOrganization = async (ev:any,organization:Organization)=>{
    await orgApi.deleteOrganization(organization);
    refreshOrgInfo();
  }
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-no-padding ion-padding-horizontal">
          <IonCol>     
            <IonLabel color="light">
              <h1 className="ion-text-center"><IonIcon icon={businessOutline}></IonIcon> <strong>Manage Organizations</strong></h1>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {(organizations.length === 0 && <h3 className="ion-text-center"><IonLabel color="medium">no organizations found</IonLabel></h3>)}
            {
              organizations.map( (organization: Organization) => {
                return <IonCard disabled={isLoading}
                      color="light"
                      key={'row-' + organization?.OrgID}>
                  <IonCardHeader className="admin-header ion-padding-start ion-padding-end">
                    <IonButton fill="outline" size="small" color="dark" onClick={(event)=>causeListClicked(event, organization)}><IonIcon color="dark" className="icon-right-padding" icon={listOutline} />Review Events</IonButton>
                    <IonText className="ion-float-end">
                      <IonChip>
                        <IonIcon size="small" color={organization.IsActive?"success":"medium"} icon={organization.IsActive?checkmarkCircle:radioButtonOff} /> 
                        <IonIcon size="small" color={organization.IsConfirmed?"success":"medium"} icon={organization.IsConfirmed?shieldCheckmark:shieldOutline} />
                      </IonChip>
                    </IonText>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid className="ion-no-padding">
                      <IonRow className="ion-no-padding ion-padding-top ion-padding-bottom">
                        <IonCol>
                        <IonList className="light">
                          <IonItem lines="none" color="light" onClick={(event)=>detailsClicked(event, organization)}>
                            <IonAvatar slot="start">
                              {((organization && organization?.PhotoID && organization?.PhotoID!==0) ? <IonImg src={fileAPI.buildFileEndpoint(organization?.PhotoID+"",36,36)}></IonImg> : <IonImg src="assets/organization-photo.jpg"></IonImg>)} 
                            </IonAvatar>
                            <IonLabel className="ion-no-padding ion-text-wrap"><h3><span><strong>{organization?.Name}</strong> <IonIcon size="small" icon={createOutline} /></span></h3></IonLabel>
                          </IonItem>
                        </IonList>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton fill="solid" expand="block" size="small" color={organization.IsActive?"danger":"primary"} onClick={(event)=>approveToggle(event, organization)}><IonIcon className="icon-right-padding" icon={organization.IsActive?closeCircleOutline:checkmarkCircleOutline} />{organization.IsActive?"Deactivate":"Activate"}</IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton fill="solid" expand="block" size="small" color={organization.IsConfirmed?"danger":"primary"} onClick={(event)=>verifyToggle(event, organization)}><IonIcon className="icon-right-padding" icon={organization.IsConfirmed?shieldOutline:shieldCheckmarkOutline} />{organization.IsConfirmed?"Unverify":"Verify"}</IonButton>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton fill="solid" expand="block" size="small" color={organization.IsActive?"danger":"primary"} onClick={(event)=>deleteOrganization(event, organization)}><IonIcon className="icon-right-padding" icon={trash} />Delete</IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              })
            }
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="primary" onClick={ev=>contentClicked(ev)}>
          <IonIcon icon={newspaperOutline}/>
        </IonFabButton>
      </IonFab>
    </IonContent>
  );
};

export default AdminOrganizationContainer;
