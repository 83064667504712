import React, { useContext, useEffect, Fragment, useState } from 'react';
import './CausesList.css';
import {
    IonCard, IonCardHeader,
    IonCardSubtitle, IonCardTitle,
    IonCardContent, IonImg,
    IonFab, IonIcon, IonButton, IonList, 
    IonLabel, IonSkeletonText, IonThumbnail, IonListHeader, IonItem, IonAvatar, 
    IonRefresher, IonRefresherContent, IonGrid, IonRow, IonCol, IonText 
} from '@ionic/react';

import { useHistory, useParams } from 'react-router';
import { AppContext, AppActionTypes, AppAction, AppNetworkStatusTypes } from '../contexts/AppContext';
import { heart, heartOutline, chevronForwardOutline, peopleCircleOutline, mail, pinOutline, calendarOutline } from 'ionicons/icons';
import { FavoriteDTO } from '../models/FavoriteDTO';
import { Organization } from '../models/Organization';
import { Cause } from '../models/Cause';

import { LookupAPI } from '../services/LookupAPI';
import { UserAPI } from '../services/UserAPI';
import { CauseAPI } from '../services/CauseAPI';
import { Utilities } from '../services/Utilities';
import { CauseSearch } from "../models/CauseSearch";
import { FileAPI } from '../services/FileAPI';
import { OrgSearch } from '../models/OrgSearch';
interface ContainerProps {
    filter: string;
    orgId?: number;
}


// Note: filter - search, favorite, manage, landing
const CausesList: React.FC<ContainerProps> = ({ filter = 'search', orgId = 0 }) => {
    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const params = useParams<{ orgId: string; causeId: string; }>();
    const [causes,setCauses] = useState<Cause[]>([]);
    const causeAPI = new CauseAPI(state.User);

    const userAPI = new UserAPI(state.User);
    const lookupAPI = new LookupAPI();
    const fileAPI = new FileAPI(state?.User);

    // programmaticaly passed e.g. Landing page buttons
    // const passedParams = history.location?.state as ContainerProps;
    // if (passedParams?.filter){
    //     filter = passedParams.filter;
    // }

    //console.log(filter,orgId);

    const landingSamples = [
        {
            Title: state?.Lookup?.Contents?.FEATURED_ITEM_1_TITLE,
            Body: state?.Lookup?.Contents?.FEATURED_ITEM_1_BODY,
            CauseID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_1_ID)
        },
        {
            Title: state?.Lookup?.Contents?.FEATURED_ITEM_2_TITLE,
            Body: state?.Lookup?.Contents?.FEATURED_ITEM_2_BODY,
            CauseID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_2_ID)
        }
    ];

    let carouselContents = new Array<any>();
    // Build Carousel Cards
    for (let i = 1; i<20; i++){
        if (state?.Lookup?.Contents && state?.Lookup?.Contents[`CAROUSEL_${i}_ID`]){
            carouselContents.push({
                ID: state?.Lookup?.Contents[`CAROUSEL_${i}_ID`],
                Title: state?.Lookup?.Contents[`CAROUSEL_${i}_TITLE`],
                CauseID: state?.Lookup?.Contents[`CAROUSEL_${i}_CAUSE_ID`]
            });
        }
        else {
            break;
        }
    }


    const onInit = () => { 
        processCauses();
        return () => {
            userAPI.cancel();
            causeAPI.cancel();
            lookupAPI.cancel();
            fileAPI.cancel();
        };
    }
    useEffect(onInit, [state?.Lookup?.Contents]);


    const processCauses = async () => {
        dispatch({ type: AppActionTypes.START_LOADING } as AppAction);
        
        try {
            let res: Array<Cause> = [];
            if (filter === 'favorite') {
                res = await userAPI.getFavorite();
            }
            else if (filter === 'volunteer') {
                res = await userAPI.getMyVolunteer();
            }
            // obsolete?
            else if (filter === 'landing') {
                if (state?.User?.UserID) {
                    res = await causeAPI.getCauses();
                }
                else {
                    res = await lookupAPI.getCauses();
                }
                res = res.filter((item) => item.CauseID === landingSamples[0].CauseID || item.CauseID === landingSamples[1].CauseID);
                
            }
            else if (filter === 'organization'){
                if (state?.User?.UserID){
                    res = await causeAPI.searchCauses({ OrgID:orgId });
                }
                else {
                    res = await lookupAPI.searchCauses({ OrgID:orgId });
                }
            }
            else if (filter === 'carousel'){
                // do nothing
                /*
                if (state?.User?.UserID) {
                    res = await causeAPI.searchCauses({isFavorite:true});
                    res.sort((causeA,causeB)=>{
                        return causeA?.Matches! > causeB?.Matches! ? -1 : 0;
                    });
                }
                else {
                    res = await lookupAPI.getCauses();
                }
                
                res = res.slice(0,3);
                */
            }
            else if (filter === 'manage') {
                res = await causeAPI.searchCauses({ OrgID: parseInt(params.orgId), isPublic: false });
            }
            // search / organization 
            else {
                let filters = {} as CauseSearch;
                if (state?.Coordinate?.latitude) {
                    filters = {Lat:state?.Coordinate?.latitude, 
                               Lng: state?.Coordinate?.longitude, 
                               Radius: 50, 
                               Buffer:true,
                               Tagged: state?.User?.Tags || []
                              } as CauseSearch;
                }
                if (state?.User?.UserID) {
                    res = await causeAPI.searchCauses(filters);
                }
                else {
                    res = await lookupAPI.searchCauses(filters);
                }
            }
            setCauses(res);
            dispatch({ type: AppActionTypes.UPDATE_CAUSES, payload: { Causes: res } });
            let orgs: Array<Organization> = [];
            orgs = (await lookupAPI.getOrganizations({} as OrgSearch))?.sort((a,b)=>a.Name?.localeCompare(b.Name));
            dispatch({ type: AppActionTypes.UPDATE_ORGS, payload: { Organizations: res } });

        }
        catch (err) {
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (CausesList)', Error: err}} as AppAction);
        }
        finally {
            dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
        }
    }


    const manageClicked = (ev: any, item: Cause) => {
        ev.preventDefault();
        history.push(`../../organization/${params.orgId}/cause/${item.CauseID}`);
    }

    const detailsClicked = (ev: any, item: Cause) => {
        ev.preventDefault();
        history.push(`../../details/${item.CauseID}`, { cause: item });
    };


    const favoriteClicked = async (ev: any, inItem: Cause) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!state?.User?.UserID) {
            history.push('../../register/volunteer');
            return;
        }
        const payload: FavoriteDTO = {
            UserID: state?.User?.UserID,
            CauseID: inItem.CauseID
        };
        try {
            if (inItem.IsFavorite) {
                await userAPI.deleteFavorite(payload);
            }
            else {
                await userAPI.addFavorite(payload);
            }
            dispatch({
                type: AppActionTypes.UPDATE_CAUSE_FAVORITE,
                payload: { CauseID: inItem.CauseID, IsFavorite: !inItem.IsFavorite }
            });
        }
        catch (err) {
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (CausesList - Favorite for Cause)', Error: err}} as AppAction);
        }
        finally {
            dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
        }
    };

    const viewVolunteers = async  (ev: any, item: Cause) => {
        ev.preventDefault();
        ev.stopPropagation();
        history.push(`../../cause/${item.CauseID}/volunteers`);
    };

    const carouselClicked = (ev: any, causeId?:string)=>{
        ev.preventDefault();
        ev.stopPropagation();
        if (state?.Lookup?.Contents?.CAROUSEL_CLICK){
            if (state?.Lookup?.Contents?.CAROUSEL_CLICK === 'each'){
                history.push(`../../details/${causeId}`);
            }
            else {
                history.push('../search',{viewType:state?.Lookup?.Contents?.CAROUSEL_CLICK});
            }
        }
    };

    const showServiceEventsHeader = () => {
        if (filter === 'search') {
            return true;
        }
        else if (filter === 'organization'){
            // if (causes?.length === 0){
            //     return false;
            // }
            return true;
        }
        return false;
    };
    const doRefresh = async (event: any) => {
        await processCauses();
        event.detail.complete();
    };
    const  showSendMessage = (ev:any, causeId:number) => {
        ev.preventDefault();
        ev.stopPropagation();
        history.push(`../../message/${causeId}`)
    }
    return (
        <Fragment>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh} className="cause-refresher">
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {Array(20).fill(1).map((item,idx)=><IonList key={`list-item-${idx}`} hidden={state?.NetworkStatus !== AppNetworkStatusTypes.PROGRESS}>
                <IonItem hidden={filter !== 'search'} detail={false}>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            {Array(4).fill(1).map((item,idx)=><p key={`p-${idx}`}><IonSkeletonText animated style={{ width: '100%' }} /></p>)}
                        </IonLabel>
                </IonItem>
                <IonCard hidden={filter === 'search'}>
                    <IonCardHeader>
                        <IonThumbnail style={{width:'100%', height:'100px'}}>
                            <IonSkeletonText animated />
                        </IonThumbnail>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonLabel>
                            {Array(5).fill(1).map((item,idx)=><p key={`p-${idx}`}><IonSkeletonText animated style={{ width: '100%' }} /></p>)}
                        </IonLabel>
                    </IonCardContent>
                </IonCard>               
            </IonList>)}
            {/* <IonListHeader hidden={!showServiceEventsHeader()} className="causes-search-header">
                <IonLabel className="ion-text-uppercase">SELECT YOUR INTERESTS/CAUSES</IonLabel>
            </IonListHeader>             */}
            <IonList hidden={causes.length===0 && filter !== 'carousel'} className={"causes-list "+(filter==='carousel'?"cause-carousel":"")}>

            {causes?.filter((item: Cause) => !item.Hidden && (filter === 'search' || filter === 'organization')).map((item: Cause, index: number) =>
                <IonCard 
                        className="clickable"
                        hidden={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS} 
                        key={'row-' + item?.CauseID} 
                        onClick={(filter === 'manage' ? (event) => manageClicked(event, item) : (event) => detailsClicked(event, item))}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonImg src={(item?.FileID !== -1 ? fileAPI.buildFileEndpoint(item.FileID+"",209,130):"assets/event-photo.jpg")}></IonImg>
                            </IonCol>
                            <IonCol className="cause-desc-col">
                                <IonList class="ion-no-padding">
                                    <IonItem lines="none">
                                        <IonAvatar  slot="start" >
                                        {(
                                            (state?.Organizations.find( (x:Organization)=>x.OrgID === item?.OrgID)?.FileID > 0 ? 
                                            <IonImg src={fileAPI.buildFileEndpoint(state?.Organizations.find( (x:Organization)=>x.OrgID === item?.OrgID)?.FileID + "",36,36)}></IonImg> 
                                            : 
                                            <IonImg src="assets/organization-photo.jpg"></IonImg>)
                                        )}
                                            
                                        </IonAvatar>
                                        <p color="medium">{item?.OrgName}</p>
                                    </IonItem>
                                </IonList>
                                <p className="causes-title">{item?.Title}</p>
                                <p className="causes-location"><IonIcon icon={pinOutline} className="ion-padding-end" /><span>{item?.Address} {item?.City} {item?.State} {item?.Zip}</span></p>
                                <p className="causes-datetime"><IonIcon icon={calendarOutline} className="ion-padding-end" /> <span>{(item?.EventStart === '0001-01-01T00:00:00' ? 'Date & Time N/A' : Utilities.getDateTimeRange(item.EventStart!,item.EventEnd!).pretty)}</span></p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
            )}
            
            {(filter === 'carousel') && <div className="cause-carousel-wrapper">
                {carouselContents.map((item:any, index: number) => 
                    <IonCard
                        key={'row-' + index + '-carousel'}
                        className={'cause-carousel-item ' + (state?.Lookup?.Contents?.CAROUSEL_CLICK ? ' clickable ' : '')} onClick={(event)=>carouselClicked(event, state?.Lookup?.Contents[`CAROUSEL_${(1+index)}_CAUSE_ID`])}>
                        <img width="100%" height="100%" alt="Carousel Thumbnail" src={fileAPI.buildFileEndpoint(item.ID?item.ID+"":"assets/event-photo.jpg",300,300)}/>
                        <IonCardContent className="cause-bottom-content">
                            <IonCardTitle className={'causes-title causes-title-'+(parseInt(''+item?.Title?.length / 5))}>{item?.Title}</IonCardTitle>
                        </IonCardContent>
                    </IonCard>
                )}
            </div>}

            {causes?.filter((item: Cause) => !item.Hidden && (filter === 'manage' || filter === 'landing' || filter === 'favorite' || filter === 'volunteer')).map((item: Cause, index: number) =>    
                <IonCard 
                    hidden={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS }
                    key={'row-' + item?.CauseID} 
                    onClick={(filter === 'manage' ? (event) => manageClicked(event, item) : (event) => detailsClicked(event, item))}
                    className={(filter === 'landing' ? (index % 2 ? 'featured-card clickable' : 'featured-card-alt clickable') : 'causes-card clickable')}>
                    {(filter !== 'landing') ? (
                        <Fragment>
                            <IonFab horizontal="end">
                                <IonButton className="fab-button"
                                    color={item?.IsFavorite ? "secondary" : "light"}
                                    fill="clear"
                                    size="large"
                                    onClick={(event) => favoriteClicked(event, item)}>
                                    <IonIcon icon={item?.IsFavorite ? heart : heartOutline} />
                                </IonButton>
                            </IonFab>
                            <IonFab horizontal="start" className="cause-begin-button">
                                <IonButton className="fab-button"
                                    color="light"
                                    fill="clear"
                                    hidden={state?.User?.UserType!=='A' && !(state?.User?.Organizations?.find((org:Organization)=>{return item?.OrgID === org.OrgID})) }
                                    size="large"
                                    onClick={(event) => viewVolunteers(event, item)}>
                                    <IonIcon icon={peopleCircleOutline}/>
                                </IonButton>
                            </IonFab>
                            <IonFab horizontal="start" className="cause-second-button">
                                <IonButton className="fab-button"
                                    color="light"
                                    fill="clear"
                                    hidden={state?.User?.UserType!=='A' && !(state?.User?.Organizations?.find((org:Organization)=>{return item?.OrgID === org.OrgID})) }
                                    size="large"
                                    onClick={(event) => showSendMessage(event, item.CauseID)}>
                                    <IonIcon icon={mail}/>
                                </IonButton>
                            </IonFab>
                            <IonImg src={(item?.FileID !== -1 ? fileAPI.buildFileEndpoint(item.FileID+"",209,130):"assets/event-photo.jpg")}></IonImg>
                            <IonCardHeader className="causes-header">
                                <IonCardTitle className={'causes-title causes-title-'+(parseInt(''+item?.Title?.length / 5))}>{item?.Title}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="causes-body">
                                <IonItem detail={false} lines="none" className="causes-item">
                                    <p className="causes-datetime">{(item?.EventStart === '0001-01-01T00:00:00' ? 'Date & Time N/A' : Utilities.getDateTimeRange(item.EventStart!,item.EventEnd!).pretty)}</p>
                                </IonItem>
                            </IonCardContent>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <IonCardHeader>
                                <IonCardTitle className="landing-title"><strong>{landingSamples[index]?.Title}</strong></IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent color="light" className="landing-body">
                                {landingSamples[index]?.Body}
                                <br /><br />
                        LEARN MORE <IonIcon icon={chevronForwardOutline}></IonIcon>
                            </IonCardContent>
                        </Fragment>
                    )}
                </IonCard>
            )}
            </IonList>
            {/* Not Found */}
            { (filter !== 'carousel') && (causes?.filter((item: Cause) => !item.Hidden).length === 0 &&
                <Fragment>
                    <IonItem hidden={filter !== 'organization'}></IonItem>
                    <IonCard hidden={filter === 'organization'}>
                        <IonCardContent className="ion-text-center">
                            <IonCardTitle className="ion-margin-bottom" color="muted">
                                No Results
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {filter} item(s) not found
                            </IonCardSubtitle>
                        </IonCardContent>
                    </IonCard>
                </Fragment>
            )}
    
        </Fragment>
    );
};

export default CausesList;
