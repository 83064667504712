import React, { useEffect, useState, useContext } from 'react';
import './ForgotPasswordContainer.css';
import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { UserAPI } from '../services/UserAPI';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { User } from '../models/User';
import { ErrorTypes } from '../services/BaseAPI';
interface ContainerProps {
  role: string;
}
const ForgotPasswordContainer: React.FC<ContainerProps> = () => {
  const userApi = new UserAPI();

  const { dispatch } = useContext(AppContext);

  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const history = useHistory();
  
  const onInit = () => { 
    return ()=>{
      userApi.cancel();
      console.log("cancelled");
    };
  }
  useEffect(onInit, []);

  const sendCodeClicked = async (ev: any) => {
    ev.preventDefault();

    if (!email) {
      setMessage("Please enter your Email");
      return;
    }

    try{
      const requestNonce = await userApi.forgotPassword({Email: email} as User);
      dispatch({ type: AppActionTypes.UPDATE_USER_NONCE, payload: { UserNonce: requestNonce } } as AppAction);
      history.push('verification');
    }
    catch (err){
      if(err.ErrorType===ErrorTypes.NETWORK){
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error Encountered",Error:err}} as AppAction);
      }
      else{
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Validation Error Encountered",Error:err}} as AppAction);
      }
    }
    finally{

    }
  };
  const loginClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('login')
  };
  return (
    <IonContent slot="fixed">
      <IonGrid>
        <IonRow className="ion-padding-start ion-margin-top">
          <IonCol>
            <IonLabel className="ion-text-start">
              <h1><strong>Forgot Password</strong></h1>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonList lines="full" className="ion-padding">
              <IonItem>
                <IonLabel position="stacked" color="medium">Email <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. john.smith@globalserviceinstitute.org" required type="email" value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
              </IonItem>
              <IonButton expand="full" className="ion-margin-top" color="primary" onClick={(event) => sendCodeClicked(event)}>Send Reset Code</IonButton>
              <IonItem lines="none">
                <IonText color="danger">{message}</IonText>
              </IonItem>
              <IonLabel className="text-link clickable ion-text-center ion-padding-top" color="primary" onClick={(event) => loginClicked(event)}>Login</IonLabel>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default ForgotPasswordContainer;
