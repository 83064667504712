import * as Sentry from '@sentry/browser';
import { Plugins } from '@capacitor/core';
import {
  IonContent, IonHeader, IonSearchbar, IonPage, IonToolbar, 
  IonToast, IonFab, IonFabButton, IonIcon, IonSegment, IonSegmentButton, IonLabel
} from '@ionic/react';
import React, { useContext, useEffect, useState, } from 'react';
import { useParams, Switch, Route, useLocation, useHistory } from 'react-router';

import './Page.css';
import WelcomeContainer from '../components/WelcomeContainer';
import RegisterContainer from '../components/RegisterContainer';
import LoginContainer from '../components/LoginContainer';
import UserOrganizationContainer from '../components/UserOrganizationContainer';
import OrganizationProfile from '../components/OrganizationProfileContainer';
import UserContainer from '../components/UserContainer';
import { AppContext, AppActionTypes, AppAction, AppNetworkStatusTypes } from '../contexts/AppContext';
import DetailContainer from '../components/DetailContainer';
import ProfileContainer from '../components/ProfileContainer';
import ChangePasswordContainer from '../components/ChangePasswordContainer';
import MyVolunteerContainer from '../components/MyVolunteerContainer';
import AboutContainer from '../components/AboutContainer';
import FavoriteContainer from '../components/FavoriteContainer';
import ForgotPasswordContainer from '../components/ForgotPasswordContainer';
import VerificationContainer from '../components/VerificationContainer';
import OrganizationCauseContainer from '../components/OrganizationCausesContainer';
import CauseProfileContainer from '../components/CauseProfileContainer';
import AdminOrganizationContainer from '../components/AdminOrganizationContainer';
import AdminViewControl from '../components/AdminViewControl';
import AdminContentContainer from '../components/AdminContentContainer';
import CauseVolunteerContainer from '../components/CauseVolunteerContainer';
import LandingContainer from '../components/LandingContainer';
import NewLandingContainer from '../components/NewLandingContainer';


import { CauseAPI } from '../services/CauseAPI';
import { LookupAPI } from '../services/LookupAPI';

import { wifiOutline } from 'ionicons/icons';
import { PluginListenerHandle } from '@capacitor/core/dist/esm/web/network';
import ContentContainer from '../components/ContentContainer';
import OrganizationDetailsContainer from '../components/OrganizationDetailsContainer';
import SearchContainer from '../components/SearchContainer';
import OrganizationsList from '../components/OrganizationsList';
import SendMessageContainer from '../components/SendMessage';

const Page: React.FC = () => {
  // Network Status Handler
  const { Network, } = Plugins;
  

  // This parameter is nested routes. See Tabs.tsx
  const params = useParams<{ name: string; id: string; }>();

  // Global State
  const { state, dispatch } = useContext(AppContext);

  // Local State, such as search
  const [searchViewType, setSearchViewType] = useState('search');

  // Figuring out level
  const location = useLocation();
  const isSearch = (location.pathname.toLowerCase().indexOf('/search') > -1);
  const history = useHistory();

  // Network and Local State Management
  const causeAPI = new CauseAPI(state?.User);
  const lookupAPI = new LookupAPI();
  /* const [selectedCause, setSelectedCause] = useState({} as Cause); */
  
  const onInit = () => { 
    let handler:PluginListenerHandle;
    //console.log('params?.id and cause',params?.id, state?.Causes, history.location);
    const processAsync = async () => { 
      dispatch({type:AppActionTypes.START_LOADING} as AppAction);
      try {
        const response = await lookupAPI.getLookup();
        dispatch({ type: AppActionTypes.LOOKUP, payload: { Lookup: response } } as AppAction)
      }
      catch (err) {
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (Lookup)', Error: err}} as AppAction);
      }
      finally {
        dispatch({type:AppActionTypes.END_LOADING} as AppAction);
      }

      // Network On-Off detection. Be careful, you need to know how to clean up the handler
      if (!handler) {
        handler = Network.addListener('networkStatusChange', (status) => {
          console.log("Network status changed", status);
      
          // back online
          if (status.connected) {
            dispatch({type:AppActionTypes.NETWORK_ONLINE} as AppAction);
          }
          // went offline
          else {
            dispatch({type:AppActionTypes.NETWORK_OFFLINE} as AppAction);
            
          }
          Sentry.addBreadcrumb({
            category: 'network',
            message: `${status.connected ? 'Online':'Offline'}`,
            level: Sentry.Severity.Info
          });
        });
      }
    }
    processAsync();
    return () => {
      causeAPI.cancel();
      lookupAPI.cancel();
      Network.removeAllListeners();
    };
  }
  useEffect(onInit, []); 

  const onInitCause = () => { 
    //console.log('on init cause', history.location?.state);
    const passedParams = history.location?.state as any;
    if (passedParams?.viewType){
      setSearchViewType(passedParams.viewType);
    }

    // Only kicks off if the route is details and if Causes are blank. that sounds like you directly came into the details page.
    if (state?.Causes?.length === 0 && history.location?.pathname?.indexOf('details/') > -1){
      //console.log('details/ params?.id and cause - 2nd useEffect',params?.id, state?.Causes, history.location);
      const process = async ()=> {
        dispatch({ type: AppActionTypes.START_LOADING } as AppAction);
        try {
            // since history.location.state is not passed, pull the ID from the url
            const causeId = parseInt(params?.id);
            // Download from the server
            let res; 
            console.log(state?.User?.UserID);
            if(state?.User?.UserID){
                res = await causeAPI.getCause(causeId);
            }
            else {
                res = await lookupAPI.getCause(causeId);
            }
            //setSelectedCause(res);
            // statsh the found only item in to the context. details page will need it.
            dispatch({ type: AppActionTypes.UPDATE_CAUSES, payload: { Causes: [res] } });
        }
        catch (err) {
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (Details)', Error: err}} as AppAction);
        }
        finally {
            dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
        }
      };
      process();
    }
    /* else {
      const foundCause = state?.Causes.find((cause:Cause)=>{
        return cause.CauseID === parseInt(params?.id);
      });
      //setSelectedCause(foundCause);
    } */
    return () => {
    };
  }
  
  useEffect(onInitCause, [params]); // monitor params if any changes happen

  /* const volunteerClicked = async (ev: any, causeId: number) => {
    ev.preventDefault();
    if (!state?.User?.UserID) {
      history.push('../register/volunteer');
    }
    else {
      dispatch({type:AppActionTypes.START_LOADING} as AppAction);
      try {
        const payload = {
          UserID: state?.User?.UserID,
          CauseID: causeId,
        } as VolunteerDTO;
        if (selectedCause?.IsVolunteer) {
          await causeAPI.removeVolunteer(payload);
        }
        else {
          await causeAPI.applyVolunteer(payload);
        }
        dispatch({
          type: AppActionTypes.UPDATE_CAUSE_VOLUNTEER,
          payload: { CauseID: selectedCause?.CauseID, IsVolunteer: !selectedCause?.IsVolunteer }
        });
        if (selectedCause?.IsVolunteer) {          
          dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE,payload:{Message:'We received your request. Thank you!'}} as AppAction);
        }
        else {
          dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE,payload:{Message:'We received your withdrawal request. Thank you.'}} as AppAction);
        }

      }
      catch (err) {
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR - Volunteer', Error: err}} as AppAction);
      }
      finally {
        dispatch({type:AppActionTypes.END_LOADING} as AppAction);
      }
    }
  }; */
  const keywordChanged = (ev: any) => {
    //console.log(ev.target.value);
    if (!ev.target.value){
      dispatch({ type: AppActionTypes.RESET_SEARCH, payload:{}});
    }
    else {
      dispatch({ type: AppActionTypes.UPDATE_CAUSES_SEARCH, payload: { keyword: ev.target.value } });
      dispatch({ type: AppActionTypes.UPDATE_ORGS_SEARCH, payload: { keyword: ev.target.value } });
    }
  };
  const searchCleared = (ev: any)=>{
    dispatch({ type: AppActionTypes.RESET_SEARCH, payload:{}});
  };
  const searchViewChanged = (ev: any)=>{
    console.log('searchViewChanged', ev.detail.value);
    setSearchViewType(ev.detail.value);
  };
  const hideMessage = () => {
    dispatch({type:AppActionTypes.HIDE_MESSAGE} as AppAction);
  };

  return (
    <IonPage className="top-buffer">
      <IonFab hidden={state?.NetworkStatus !== AppNetworkStatusTypes.OFFLINE}
              vertical="bottom" 
              horizontal="end" 
              slot="fixed" 
              className="network-indicator">
        <IonFabButton color="danger" size="small">
          <IonIcon icon={wifiOutline} />
        </IonFabButton>
      </IonFab>
      <IonHeader hidden={isSearch} >
        <Route path="/admin/:workbench/:view" component={AdminViewControl}/>
      </IonHeader>
      <IonHeader hidden={!isSearch}>
        <IonToolbar>
          <IonSearchbar className="no-box-shadow" color="light" onKeyUp={(ev)=>keywordChanged(ev)} onIonClear={(ev)=>searchCleared(ev)}></IonSearchbar>
        </IonToolbar>
        <IonToolbar>
            <IonSegment value={searchViewType} onIonChange={e => searchViewChanged(e)} color="primary">
                <IonSegmentButton value="search">
                    <IonLabel>DISCOVER</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="organizations-list">
                    <IonLabel>Organizations</IonLabel>
                </IonSegmentButton>                      
            </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToast
          isOpen={state?.Notification?.Visible}
          onDidDismiss={() => hideMessage()}
          message={state?.Notification?.Message}
          duration={state?.Notification?.Duration}
          color={state?.Notification?.Color}
          position="top"
        />
        {/* <IonProgressBar hidden={state?.NetworkStatus !== AppNetworkStatusTypes.PROGRESS} type="indeterminate"></IonProgressBar> */}
        <div className="progress-placeholder" hidden={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS}></div>
        <Switch>
          <Route exact path="/welcome">
            <WelcomeContainer name={params?.name || ''} />
          </Route>
          <Route path="/home" component={NewLandingContainer} />
          <Route path="/homeold" component={LandingContainer} />
          <Route path="/content" component={ContentContainer}/>
          <Route path="/register/:role" component={RegisterContainer} />
          <Route path="/search/" render={(props)=><SearchContainer viewType={searchViewType}></SearchContainer>} />
          <Route path="/favorite" component={FavoriteContainer}/>
          <Route path="/login" component={LoginContainer}/>
          <Route path="/user/profile/:userId" component={UserContainer}/>
          <Route path="/user/profile" component={UserContainer}/>
          <Route path="/user/organizations" component={UserOrganizationContainer}/>
          <Route path="/organizations" component={OrganizationsList}/>
          <Route path="/organization/profile/:orgId" component={OrganizationProfile}/>
          <Route path="/organization/profile" component={OrganizationProfile}/>
          <Route path="/organization/details/:orgId" component={OrganizationDetailsContainer}/>
          <Route path="/details/:causeId" component={DetailContainer}/>
          <Route path="/cause/:causeId/volunteers" component={CauseVolunteerContainer}/>
          <Route path="/about" component={AboutContainer}/>
          <Route path="/profile" component={ProfileContainer}/>
          <Route path="/password" component={ChangePasswordContainer}/>
          <Route path="/volunteer" component={MyVolunteerContainer} />
          <Route path="/forgotpassword" component={ForgotPasswordContainer}/>
          <Route path="/verification" component={VerificationContainer}/>
          <Route path="/organization/:orgId/causes/" component={OrganizationCauseContainer}/>
          <Route path="/organization/:orgId/cause/:causeId" component={CauseProfileContainer}/>
          <Route path="/organization/:orgId/cause/" component={CauseProfileContainer}/>
          <Route path="/message/:causeId/" component={SendMessageContainer}/>
          <Route path="/admin/:workbench/:view" component={AdminOrganizationContainer}/>
          <Route path="/admin/content" component={AdminContentContainer}/>
        </Switch>      
      </IonContent>
    </IonPage>
  );
};

export default Page;
