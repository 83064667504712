import { BaseAPI } from './BaseAPI';
import { File } from "../models/File";
import { Cause } from "../models/Cause";
import { Utilities } from "../services/Utilities";

export class FileAPI extends BaseAPI {
    public async getFiles(): Promise<File[]> {
        return await this.doGet<File[]>('user/files');
    }
    public async getCauseFiles(aCause:Cause): Promise<File[]> {
        return await this.doGet<File[]>(`cause/${aCause.CauseID}/files`);
    }
    public async postFile(aFile:File): Promise<File> {
        return await this.doPost<File,File>('file/upload',aFile);
    }
    public async storeFile(aFile:any){
        const media = await Utilities.toBase64(aFile);
        const filePayload = {
            FileID:0,
            Base64:media.base64,
            Extension:Utilities.getFileMetadata(media.event)!.extension,
            Name:media.name
        } as File;
        const storeFileResponse = await this.postFile(filePayload);
        media.base64 = null;
        media.src = storeFileResponse.FileID;
        media.fn = this.buildFileEndpoint.bind(this);
        return media
    }
    public buildFileEndpoint(src:string,width:number=0,height:number=0){
        return `${this.baseUrl}file/download/stream/${src}?${width>0?`width=${width}&`:""}${height>0?`height=${height}`:""}`;
    }
}