import React, { useContext, useState, useEffect, Fragment } from 'react';
import './LandingContainer.css';
import { IonImg, IonContent, IonRow, IonCol, IonLabel, IonGrid, IonCardHeader, IonCardContent, IonCard, IonText } from '@ionic/react';
import CausesList from './CausesList';
import { AppAction, AppActionTypes, AppContext } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { useHistory } from 'react-router';
import { Geolocation } from '@capacitor/core';
import { Coordinate } from '../models/Coordinate';
import { FileAPI } from '../services/FileAPI';

interface ContainerProps {
}
const LandingContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { state, dispatch} = useContext(AppContext);
  //const [ hoursServed, setHoursServed ] = useState(0);
  const [ volunteers, setVolunteers] = useState(0);
  const hsFinal = parseInt(state?.Lookup?.Contents?.HOURS_SERVED_NUM?.replace(/,/gi,''));
  const voFinal = parseInt(state?.Lookup?.Contents?.VOLUNTEERS_NUM?.replace(/,/gi,''));
  const userAPI = new UserAPI(state?.User);
  const fileAPI = new FileAPI(state?.User);
  const landingContents = [
    {
      Title: state?.Lookup?.Contents?.FEATURED_ITEM_1_TITLE,
      Body: state?.Lookup?.Contents?.FEATURED_ITEM_1_BODY,
      ID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_1_ID)
    },
    {
      Title: state?.Lookup?.Contents?.FEATURED_ITEM_2_TITLE,
      Body: state?.Lookup?.Contents?.FEATURED_ITEM_2_BODY,
      ID: parseInt(state?.Lookup?.Contents?.FEATURED_ITEM_2_ID)
    }
  ];
  
  // const videoRef = useRef(null);
  const onInit = () => { 
    let timer:NodeJS.Timeout;
    setTimeout(()=>{
      const totalDuration = 5000;
      const max = 31; // Note: avoid crisp division like 30
      let counter = 0;
      timer = setInterval(()=>{
        if (hsFinal && hsFinal > 0){
          //setHoursServed(parseInt('' + hsFinal * counter / max));
          setVolunteers(parseInt('' + voFinal * counter / max));
          counter++;
        }
        if (counter >= max){
          //setHoursServed(hsFinal);
          setVolunteers(voFinal);
          clearInterval(timer);
        }
      },totalDuration/max);

      // // Play Video
      // (videoRef.current as any)?.play();
    },1000);
    
    // Geocoding Ahead
    const initCoord = async () => {
      try {
        const info =  await Geolocation.getCurrentPosition();
        const coord = (info?.coords as Coordinate);
        if (coord){          
          dispatch({ type: AppActionTypes.UPDATE_COORDINATE, payload: { Coordinate: coord} } as AppAction);
        }
      }
      catch {
        // do nothing
      }
    };
    initCoord();

    return ()=>{
      
      fileAPI.cancel();
      clearInterval(timer);
    };
  }
  useEffect(onInit, [state?.Lookup?.Contents]);
  const cardClicked = (ev:any, index:Number) => {
    ev.preventDefault();
    if (index > 0){
      history.push('content');
    }
  }
  const volunteerClicked = (ev:any) => {
    ev.preventDefault();
    history.push('./search', {viewType:'search'});
    return false;
  }
  const orgClicked = (ev:any) => {
    ev.preventDefault();
    history.push('./search', {viewType:'organizations-list'});
    return false;
  }
  return (
    <IonContent slot="fixed">
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol>
              <IonImg src="assets/intro.gif"></IonImg>
          </IonCol>
        </IonRow>
        <IonRow className="sloagan-row">
          <IonCol className="ion-text-center right-divider clickable" onClick={(event)=>volunteerClicked(event)}>
            <IonText className="ion-text-uppercase" color="dark"><h6 className="ion-no-padding"><strong>Volunteer</strong></h6></IonText>
          </IonCol>
          <IonCol className="ion-text-center clickable"  onClick={(event)=>orgClicked(event)}>
            <IonText className="ion-text-uppercase" color="dark"><h6 className="ion-no-padding"><strong>Organization</strong></h6></IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonText className="ion-text-uppercase" color="dark"><h6 className="ion-no-padding ion-no-margin ion-margin-top"><strong>{state?.Lookup?.Contents?.SUB_TITLE}</strong></h6></IonText>            
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonLabel className="count-num">{volunteers?.toLocaleString()}</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonText className="ion-text-uppercase" color="dark">
              <h6 className="ion-no-padding ion-no-margin ion-margin-bottom">
                <strong>{state?.Lookup?.Contents?.VOLUNTEERS_TITLE}</strong>
                </h6>
           </IonText>            
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* WHY SERVE Index 1 goes first */} 
      <IonCard className="featured-card ion-no-margin ion-margin-start ion-margin-end" onClick={(ev)=>cardClicked(ev,1)}>
        <IonImg src={fileAPI.buildFileEndpoint(landingContents[1]?.ID+"",400,200)}></IonImg>
        <IonCardHeader hidden={!landingContents[1]?.Title} className="landing-header">
            <IonText className="landing-title"><strong>{landingContents[1]?.Title}</strong></IonText>
        </IonCardHeader>
        <IonCardContent color="light" className="landing-body ion-margin-top" hidden={!landingContents[1]?.Body}>
            {landingContents[1]?.Body}
            &nbsp;
            <Fragment>
              <IonText color="secondary"><strong>Search by Interest</strong></IonText>
            </Fragment>
        </IonCardContent>
      </IonCard>
      {/* marketing image - Index 0 goes next */}
      <IonCard className="featured-card-alt clickable" onClick={(event)=>orgClicked(event)}>
        <IonImg src={fileAPI.buildFileEndpoint(landingContents[0]?.ID+"",400,200)}></IonImg>
        <IonCardHeader hidden={!landingContents[0]?.Title} className="landing-header">
            <IonText color="dark"><strong>{landingContents[0]?.Title}</strong></IonText>
        </IonCardHeader>
        <IonCardContent 
                        className="ion-margin-top ion-no-padding ion-padding-start ion-padding-end ion-padding-bottom" 
                        hidden={!landingContents[0]?.Body}>
            <IonText color="dark" >{landingContents[0]?.Body}</IonText>
            &nbsp;
            <Fragment>
              <IonText color="primary"><strong>Search by Organization</strong></IonText>
            </Fragment>
        </IonCardContent>
      </IonCard>
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-no-margin ion-no-padding ion-padding-start">
          <IonCol className="ion-padding-start ion-no-padding">
            <IonLabel className="ion-text-uppercase"><h6 className="ion-no-margin ion-no-padding"><strong>Service In Action</strong></h6></IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
      <CausesList filter="carousel"/>
    </IonContent>
  );
};

export default LandingContainer;
