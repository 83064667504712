import React, { useContext, useState } from 'react';
import './ChangePasswordContainer.css';
import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, IonText, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { PasswordDTO } from '../models/PasswordDTO';
import { arrowBackCircleSharp } from 'ionicons/icons';

// Note: https://ionicframework.com/blog/using-react-hooks-in-an-ionic-react-app/
interface ContainerProps {
}
const ChangePasswordContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const [password, setPassword] = useState<string>('');
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const userApi = new UserAPI(state.User);

  const savePasswordClicked = async (ev: any)=>{
    ev.preventDefault();
    const pass:PasswordDTO= {
      Password: password
    }
    try {
      if(password === verifyPassword){
        await userApi.updatePassword(pass);
        setPassword(password);
        dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Password changed"}} as AppAction);
      }
      else{
        dispatch({type:AppActionTypes.SHOW_WARNING_MESSAGE, payload:{Message:"Please verify your password is the same"}} as AppAction);
      }
    }
    catch(err){
      dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"ERROR",Error:err}} as AppAction);
    }
    finally {

    }
  }
  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  return (
    <IonContent>
        <IonGrid>
          <IonRow className="ion-padding-start">
            <IonCol>
              <IonLabel>
                <h1>
                  <IonButton className="slim-button"
                      color="medium"
                      fill="clear"
                      size="large"
                      onClick={(event) => goBackClicked(event)}>
                      <IonIcon icon={arrowBackCircleSharp} />
                  </IonButton>
                  <strong>Change Password</strong>
                </h1>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonList lines="full" className="ion-margin-top ion-padding">
                <IonItem>
                  <IonLabel position="stacked" color="medium">New Password <IonText color="danger">*</IonText></IonLabel>
                  <IonInput required placeholder="Must be at least 6 characters" type="password" onIonChange={e => setPassword(e.detail.value!)}></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked" color="medium">Verify Password <IonText color="danger">*</IonText></IonLabel>
                  <IonInput required placeholder="Re-Type New Password" type="password" onIonChange={e => setVerifyPassword(e.detail.value!)}></IonInput>
                </IonItem>
                <br/>
                <IonButton color="primary" 
                           expand="full" 
                           onClick={savePasswordClicked}>Change Password</IonButton>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
    </IonContent>
  );
};

export default ChangePasswordContainer;
