import React, { useState, useEffect, Fragment } from 'react';
import './MediaWidget.css';
import { IonList, IonItem, IonLabel, IonImg, 
         IonButton, IonInput, IonText, IonItemGroup, 
         IonIcon, IonFab, IonBadge } from '@ionic/react';
import {
    imageOutline,
    pencilOutline,
    checkmarkOutline
} from 'ionicons/icons';

interface IMedia{
    src: string,
    name?: string,
    fn: (src:string) => string,
    base64?: string,
    selected?: boolean,
    event?:any,
}
interface IMediaWidget {
    value: IMedia[],
    put?: (src:any)=> any,
    post?: (src:any)=> any,
    restriction?: {
        extensions:string[],
        size:number
    },
    slim?:boolean,
    avatar?:boolean,
    disabled?:boolean,
    defaultImg?:string
}
const MediaWidget : React.FC<IMediaWidget> = (props:IMediaWidget)=> {
    const [showData, setShowData] = useState(true);
    const [selected, setSelected] = useState(props.value.filter((media)=>{return media.selected})[0]);
    const [options, setOptions] = useState<IMedia[]>(props.value);
    const [messages, setMessages] = useState<string[]>([])
    const [newEvent,setNewEvent] = useState<any>({target:{value:''}});
    const [newMedia, setNewMedia] = useState({
        src:"",
        name:"",
        fn:(src: any)=>{return src}
    } as any);
    const [valid,setValid] = useState(false);
    const [slim] = useState(props.slim ? props.slim : false);
    const [avatar] = useState(props.avatar ? props.avatar : false);
    const [disabled] = useState(props.disabled ? props.disabled : false);
    const [defaultImg] = useState(props.defaultImg ? props.defaultImg : "/assets/blank.svg")
    const MBInBytes=1048576;
    useEffect(()=>{
        
        setSelected(props.value.filter((media)=>{return media.selected})[0]);
        props.value.sort((a,b)=>{
            return a.selected! === b.selected! ? 0 : a.selected!>b.selected! ? -1 : 1 ;
        });
        setOptions(props.value);
    },[props.value]);
    useEffect(()=>{
        const optionScroll = document.getElementsByClassName("media-scroll-y")[0];
        if(optionScroll){
            optionScroll.scrollTop=0;
        }
    },[options]);
    useEffect(()=>{
        if(slim && valid){
            postMedia({});
        }
    },[newEvent]);
    const validateFile = (anEvent:React.ChangeEvent)=>{
        anEvent.persist();
        const metadata = getFileMetadata(anEvent);
        if(     props.restriction ){
            const validations: string[] = [];
            if( props.restriction?.extensions && 
                props.restriction?.extensions.indexOf(metadata?.extension.toLowerCase())===-1){
                validations.push("media must be: "+props.restriction.extensions.join(","));
                setNewEvent({target:{value:''}});
            }
            if( props.restriction?.size && 
                props.restriction?.size < metadata?.size ){
                validations.push("size limit of "+(props.restriction.size/MBInBytes)+"mb exceeded")
                setNewEvent({target:{value:''}});
            }
            if(validations.length>0){
                setMessages(validations);
                setValid(false);
                return false;
            }
        }
        setNewEvent(anEvent)
        setValid(true);
        return true;
    };
    const updateNewMedia = async (anEvent:any,key:string,value:string)=>{
        setNewMedia({
            ...newMedia,
            [key]:value
        });
    };
    const postMedia = async (anEvent:any)=>{
        const scaffoldObj = {
            src: newMedia.src,
            name: newMedia.name,
            fn:(src)=>{return src},
            event:newEvent
        } as IMedia;
        const newObj = props.post ? await props.post(
            {...scaffoldObj} as IMedia) : 
            {...scaffoldObj} as IMedia;
        const newOptions = options.map(media=>{return media});
        newOptions.push(newObj);
        setOptions(newOptions);
        setValid(false);
        setNewEvent({target:{value:''}});
        setNewMedia({
            src:"",
            name:"",
            fn:(src: any)=>{return src}
        } as any);
        await putMedia(anEvent,newObj,newOptions);
    };
    const putMedia = async (anEvent:any,aMedia:IMedia,passedOptions:any=null)=>{
        const scaffoldObj = {
            ...aMedia,
            event:anEvent
        } as IMedia;
        const updatedMedia = props.put ? await props.put(
            {...scaffoldObj} as IMedia) :
            {...scaffoldObj} as IMedia;
        const newOptions= ((passedOptions ? passedOptions! : options) as IMedia[]).map((media)=>{
            media.selected=media.src===updatedMedia.src?true:false;
            if(media.selected){
                setSelected(media);
            }
            return media;
        });
        newOptions.sort((a,b)=>{
            return a.selected! === b.selected! ? 0 : a.selected!>b.selected! ? -1 : 1 ;
        });
        setOptions(newOptions);
    };
    const getFileMetadata = (event:any)=> {
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
          return;
        }
        const name = event.target.files[0].name;
        const size = event.target.files[0].size;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        return {
            name:fileName,
            extension:ext,
            size:size
        }
    };
    return (<IonList lines="none" className="ion-no-margin ion-no-padding media-w-100">
        {/*(showData || ( !selected ) ) && 
        <IonButton 
            className="ion-no-margin media-v-align-top" 
            expand="full" 
            size="small"
            color="medium" 
            onClick={()=>setShowData(!showData)}>
                {showData?"Hide":(selected?"Modify":"Add")}
        </IonButton>*/}
        <IonItem lines="none" className={(showData ? "media-hide " : "") + "ion-no-margin"} onClick={()=>setShowData(!showData)}>
            <IonLabel position={selected?"stacked":undefined} color="medium">
                { selected ?
                    <IonImg src={selected!.fn(selected!.src)} alt={selected!.name}></IonImg> :
                    "no media selected" 
                }
            </IonLabel> 
            <IonFab horizontal="end" vertical="bottom">
                { selected && <IonBadge className="ion-align-items-center ion-margin-end" color="primary">
                    <IonLabel>
                        <h2> 
                            edit  
                            <IonIcon
                                icon={pencilOutline} 
                                size="default"/> 
                        </h2>
                    </IonLabel>
                </IonBadge> }
            </IonFab>
        </IonItem>
        {( slim ? (disabled ? (selected ? 
            <Fragment>
                <p className="ion-margin-start ion-margin-end ion-text-center">
                    <IonImg className={"media-thumbnail-preview "+(avatar?"circle":"")+(selected?"":(defaultImg.indexOf(".svg")>-1?" media-thumbnail-svg":""))} src={selected?selected.fn(selected.src):defaultImg}/>
                    <IonFab horizontal="end" vertical="bottom">
                        <IonBadge hidden={disabled} className="ion-align-items-center ion-margin-end" color="primary">
                            <IonLabel >
                                Upload Image
                                <IonIcon
                                    icon={pencilOutline} 
                                    size="default"/> 
                            </IonLabel>
                        </IonBadge>
                    </IonFab>
                    {(!disabled && <input value={newEvent?.target?.value} 
                                            className="media-hidden-file" 
                                            type="file" 
                                            accept="image/x-png,image/gif,image/jpeg" 
                                            onChange={((event)=>{
                                                const result = validateFile(event);
                                            })}/>)}
                </p>
                { messages.length > 0  && 
                <IonItem>
                    {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
                </IonItem> }  
            </Fragment> :  <Fragment></Fragment> ) :
             <Fragment>
             <p className="ion-margin-start ion-margin-end ion-text-center">
                 <IonImg className={"media-thumbnail-preview "+(avatar?"circle":"")+(selected?"":(defaultImg.indexOf(".svg")>-1?" media-thumbnail-svg":""))} src={selected?selected.fn(selected.src):defaultImg}/>
                 <IonFab horizontal="end" vertical="bottom">
                     <IonBadge hidden={disabled} className="ion-align-items-center ion-margin-end" color="primary">
                         <IonLabel >
                             Upload Image
                             <IonIcon
                                 icon={pencilOutline} 
                                 size="default"/> 
                         </IonLabel>
                     </IonBadge>
                 </IonFab>
                 {(!disabled && <input value={newEvent?.target?.value} className="media-hidden-file" type="file" onChange={((event)=>{
                     const result = validateFile(event);
                 })}/>)}
             </p>
             { messages.length > 0  && 
             <IonItem>
                 {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
             </IonItem> }  
         </Fragment> ) : 
            <Fragment></Fragment>
        )}
        {( !slim && <h5 className="ion-no-margin ion-margin-top" ><IonText color="medium">Add New Image</IonText></h5>)}
        {( !slim && showData && <IonItemGroup className="media-new ion-margin-top ion-padding" color="primary">
                <IonLabel position="stacked" className="ion-margin-start ion-margin-top" color="medium">Media File <IonText color="danger">*</IonText></IonLabel>
                <IonButton className="ion-margin-horizontal" expand="block" color="primary">
                    <IonIcon color="light" icon={imageOutline}/>
                    <IonLabel color="light" className="ion-margin-start">{(newEvent?.target?.value ? "media selected" : "browse media "+(props.restriction && props.restriction.size ? "( "+(props.restriction.size/MBInBytes)+"mb limit )":""))}</IonLabel>
                    <input value={newEvent?.target?.value} className="media-hidden-file" type="file" onChange={((event)=>{
                        const result = validateFile(event);
                    })}/>  
                </IonButton>
                <IonItem lines="none">
                    <IonLabel position="stacked" color="medium">Media Name <IonText color="danger">*</IonText></IonLabel>
                    <IonInput placeholder="Enter new media name" value={newMedia.name} onIonChange={(event)=>updateNewMedia(event,"name",event.detail.value!)}></IonInput>  
                </IonItem>
                <IonButton 
                    expand="full" 
                    disabled={newMedia.name && newEvent ? false : true } 
                    color="primary" size="small" 
                    onClick={(event)=>{postMedia(event)}}>
                    upload and select
                </IonButton> 
            {messages.length > 0  && 
            <IonItem>
                {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
            </IonItem>}
        </IonItemGroup> )}

        {( !slim && <h5 className="ion-no-margin ion-margin-top" ><IonText color="medium">Select From Existing Images</IonText></h5> )}
        {( !slim && showData && <div className="ion-margin-bottom media-scroll-y">
            {( showData && options.map(media=>{
                return (
                    <IonItemGroup key={media.src+media.name} className="ion-margin-vertical media-file-option" onClick={(event)=>putMedia(event, media)}>
                        <IonItem lines="none" className="clickable">
                            <IonImg src={media.fn(media.src)}></IonImg>
                            <IonFab horizontal="start" vertical="top">
                                <IonBadge className="ion-align-items-center ion-margin-end" color="dark">
                                    <IonLabel>
                                        ID: {media.src}
                                    </IonLabel>
                                </IonBadge>
                            </IonFab>
                            <IonFab horizontal="end" vertical="top">
                                { media?.selected ? <IonBadge className="ion-align-items-center ion-margin-end" color="primary">
                                    <IonLabel>
                                        <h2>
                                            selected
                                            <IonIcon 
                                            icon={checkmarkOutline} 
                                            size="default"/>
                                        </h2>
                                    </IonLabel>
                                </IonBadge> : <IonBadge className="ion-align-items-center ion-margin-end" color="medium">
                                    <IonLabel>
                                        <h2>
                                            select
                                        </h2>
                                    </IonLabel>
                                </IonBadge>}
                            </IonFab>
                            <div className="media-thumbnail-text">
                                <IonText color="dark">{media.name}</IonText>
                            </div>
                        </IonItem>
                    </IonItemGroup>);
                }))}
            { options.length === 0 && <IonLabel color="medium">No uploaded media found</IonLabel>}
            </div>
        )}
    </IonList>)
};

export default MediaWidget