import React, { useState, useEffect, useContext } from 'react';
import './AboutContainer.css';
import { IonContent, IonImg, IonGrid, IonRow, IonCol, IonLabel, IonList, IonListHeader, IonItem, IonText, IonAlert, IonCard } from '@ionic/react';
import { Deploy } from 'cordova-plugin-ionic';
import { Plugins } from '@capacitor/core';
import { AppContext } from '../contexts/AppContext';
const AboutContainer: React.FC = () => {
    const { Device } = Plugins;
    const [version, setVersion] = useState('');
    const [available, setAvailable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showPrivacyAlert, setShowPrivacyAlert] = useState(false);
    const { state, } = useContext(AppContext);

    const onInit = () =>{ 
        const init = async () => {
            const info = await Device.getInfo();
            if (info.appVersion){          
                const currentVersion = await Deploy.getCurrentVersion();
                if (currentVersion){
                    setVersion(`${info.appVersion}.${info.appBuild}.${currentVersion.buildId}`);
                    const update = await Deploy.checkForUpdate();
                    setAvailable(update.available);
                }
            } 
        };
        init();
    }
    useEffect(onInit, []);

    const mailtoClicked = ()=> {
        window.open('mailto:service@globalserviceinstitute.org');
    };
    
    // Buggy
    // const reloadApp = async () => {
    //     console.log('reloading')
    //     await Deploy.reloadApp();
    //     const update = await Deploy.checkForUpdate()
    //     setAvailable(update.available);
    // };

    const aboutContents = [
        {
          Body: state?.Lookup?.Contents?.ABOUT_1_BODY
        },
        {
          Body: state?.Lookup?.Contents?.ABOUT_2_BODY
        },
        {
          Body: state?.Lookup?.Contents?.ABOUT_3_BODY
        }
    ];
    return (
        <IonContent>
            <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Confirm'}
            message={'You are leaving the app to view the official website of Global Service Institute, would you like to continue?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: blah => {
                    }
                },
                {
                    text: 'Continue',
                    handler: () => {
                        const url = "https://globalserviceinstitute.org";
                        window.open(url,'_blank');
                    }
                }
            ]}
            />
            <IonAlert
            isOpen={showPrivacyAlert}
            onDidDismiss={() => setShowPrivacyAlert(false)}
            header={'Confirm'}
            message={'You are leaving the app to view the privacy policies, would you like to continue?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: blah => {
                    }
                },
                {
                    text: 'Continue',
                    handler: () => {
                        const url = "https://globalserviceinstitute.org/privacy/";
                        window.open(url,'_blank');
                    }
                }
            ]}
            />
            <IonGrid className="ion-no-padding ion-margin-bottom">
                <IonRow>
                    <IonCol>
                        <IonImg src="assets/logo2.png" onClick={() => setShowAlert(true)} className="gs clickable"></IonImg> 
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonCard>
                <IonGrid className="ion-no-padding ion-margin-bottom">
                    <IonRow>
                        <IonCol color="medium" className="divder">
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-top ion-padding-start ion-padding-end">
                            <IonLabel color="dark">{ aboutContents[0]?.Body ? aboutContents[0]?.Body : "The Global Service app revolutionizes the concept of service for greater world good."}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-top ion-padding-start ion-padding-end">
                            <IonLabel color="dark">{ aboutContents[1]?.Body ? aboutContents[1]?.Body : "Bringing together global citizens to provide exceptional service with organizations and non-for-profits to encourage those who will impact their communities and far beyond." }</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-top ion-padding-start ion-padding-end">
                            <IonLabel color="dark">{ aboutContents[2]?.Body ? aboutContents[2]?.Body : "This unprecedented initiative collaborates with Long Island University, one of the first institutions in the U.S. integrating this extraordinary character-building service curation, to make an impact on the world." }</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-start ion-padding-end">
                            <IonImg src="assets/about-image-1.jpg"></IonImg> 
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding ion-margin-top ion-margin-bottom">
                            <IonImg src="assets/liu.png"></IonImg> 
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol color="medium" className="divder">
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonList>
                                <IonListHeader lines="none" className="list-heading-wrapper">
                                    <IonLabel><IonText className="list-heading"><h5><strong>More Information</strong></h5></IonText></IonLabel>
                                </IonListHeader>
                                <IonItem onClick={() => setShowAlert(true)} className="clickable">
                                    <IonLabel>
                                        <a className="list-link">GlobalServiceInstitute.org</a>
                                    </IonLabel>
                                </IonItem>
                                <IonItem lines="none" onClick={() => mailtoClicked()} className="clickable">
                                    <IonLabel>
                                        <a className="list-link">Contact Us</a>
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonList>
                                <IonListHeader lines="none"  className="list-heading-wrapper">
                                    <IonLabel><IonText className="list-heading"><h5><strong>Privacy</strong></h5></IonText></IonLabel>
                                </IonListHeader>
                                <IonItem lines="none" onClick={() => setShowPrivacyAlert(true)} className="clickable">
                                    <IonLabel>
                                        <a className="list-link">Privacy Notice</a>
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding" hidden={!version}>
                        <IonCol>     
                            <IonLabel color="medium"><small>Version: {version}</small></IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding" hidden={!version}>
                        <IonCol>     
                            <IonLabel color="medium" hidden={available}><small>There are currently no updates available.</small></IonLabel>
                            <IonLabel color="medium" hidden={!available}><small>Latest version will be automatically updated after restarting the app.</small></IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
        </IonContent>
    );
}

export default AboutContainer;