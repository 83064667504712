import React, { useState, useEffect, useContext } from 'react';
import './AdminContentContainer.css';
import { useHistory } from 'react-router';
import { IonLabel, IonContent, IonGrid, IonRow, IonItem, IonCol, IonIcon, IonButton, IonList, IonText, IonTextarea, IonAlert } from '@ionic/react';
import { arrowBackCircleSharp } from 'ionicons/icons';
import { Content } from '../models/Content';
import { LookupAPI } from '../services/LookupAPI';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
interface ContainerProps {
  title: string
}
const AdminContentContainer: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const [contents,setContents] = useState([] as Content[]);
  const [showAlert,setShowAlert] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const lookupAPI = new LookupAPI(state.User);
  useEffect(()=>{
    const process = async ()=>{
      let contents = await getEditableContents();
      setContents(contents);
    };
    
    process();
    return ()=>{
      lookupAPI.cancel();
    }
  },[]);
  const getEditableContents = async ()=> {
    return (await lookupAPI.getContents()).map((content)=>{ return {...content,IsNew:false}; })
  };
  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  const handleContentChange = async (ev: any, newContent: Content)=>{
    let newContents = contents.map((content)=>{
      if(content.ContentID==newContent.ContentID){
        return newContent;
      }
      else{
        return content;
      }
    });
    setContents(newContents)
  }
  const saveContentsClicked = async (ev: any) =>{
    let itemsToSave = contents.filter(content=>content.IsNew);
    await Promise.all(itemsToSave.map(async (content)=>{
      await lookupAPI.updateContent(content);
    }))
    setContents(await getEditableContents());
    const response = await lookupAPI.getLookup();
    dispatch({ type: AppActionTypes.LOOKUP, payload: { Lookup: response } } as AppAction);
    dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"App Contents Updated"}} as AppAction);
  }
  const askSaveContentsClicked = async (ev:any)=>{
    setShowAlert(true);
  }
  return (
    <IonContent>      
      <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Confirm'}
            message={`Are you sure you would like to update the following fields: ${contents.filter(content=>content.IsNew).map((content)=>content.Name).join(", ")}`}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary'
                },
                {
                    text: 'Continue',
                    handler: saveContentsClicked
                }
            ]}
            />
      <IonGrid>
        <IonRow>
          <IonCol>     
            <IonLabel>
              <h1>
                <IonButton className="slim-button"
                    color="medium"
                    fill="clear"
                    size="large"
                    onClick={(event) => goBackClicked(event)}>
                    <IonIcon icon={arrowBackCircleSharp} />
                </IonButton>
                <strong>Manage Content</strong>
              </h1>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="danger" >
              <h6 className="ion-margin-start  ion-margin-end ">
                <strong>
                  WARNING: You are in the main content editor. Improper use of this section may result in complete application failure.
                </strong>
              </h6>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList className="ion-no-margin ion-no-padding ion-padding-end">
          { contents.map((content:Content)=>{
              return (<IonItem key={content.ContentID}>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText color="medium">
                        {content.Name}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonTextarea placeholder={content.Description} className="ion-no-padding" value={content.Value} onIonChange={(ev)=>{ handleContentChange(ev,{ ...content,Value:ev.detail.value!,IsNew:true})} }></IonTextarea>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>);
            }) }
            <IonButton class="ion-margin-start ion-margin-top ion-margin-bottom" size="default" expand="full" onClick={(ev)=>askSaveContentsClicked(ev)}>Update Contents</IonButton>
        </IonList>
    </IonContent>
  );
};

export default AdminContentContainer;
