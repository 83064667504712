import React from 'react';
import './AdminViewControl.css';
import { useHistory, useParams } from 'react-router';
import { IonLabel,  IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
interface ContainerProps {
  title: string
}
const AdminViewControl: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const params = useParams<{ workbench: string; view: string; }>();
  const changeView = (view:string) => {
    history.push(`/admin/${params.workbench.toLowerCase()}/${view.toLowerCase()}`);
  }
  return (
    <IonToolbar>
      <IonSegment value={params.view}  onIonChange={e => changeView(e.detail.value!) } color="primary">
        <IonSegmentButton value="unverified">
          <IonLabel>Unverified</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="verified">
          <IonLabel>Verified</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="all">
          <IonLabel>All</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </IonToolbar>
  );
};

export default AdminViewControl;
