import React, { useContext, useEffect } from 'react';
import './SearchContainer.css';
import { IonContent } from '@ionic/react';
import CausesList from './CausesList';
import { useHistory } from 'react-router';
import OrganizationsList from './OrganizationsList';
import { Coordinate } from '../models/Coordinate';
import { Plugins } from '@capacitor/core';
import { AppAction, AppActionTypes, AppContext } from '../contexts/AppContext';
const { Geolocation } = Plugins;

interface ContainerProps {
  viewType?:string
}
const SearchContainer: React.FC<ContainerProps> = (props:ContainerProps) => {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  let viewType = props?.viewType as string;
  if (!viewType){
    const passedParams = history.location?.state as ContainerProps;
    if (passedParams?.viewType){
      viewType = passedParams?.viewType;
    }
  }
 

  const onInit = () =>{ 
      const initCoord = async () => {
        try {
          const info =  await Geolocation.getCurrentPosition();
          const coord = (info?.coords as Coordinate);
          if (coord){          
            dispatch({ type: AppActionTypes.UPDATE_COORDINATE, payload: { Coordinate: coord} } as AppAction);
          }
        }
        catch {
          // do nothing
        }
      };
      initCoord();
  }
  useEffect(onInit, []);


  return (
    <IonContent className="ion-padding-top">
      {(viewType === 'organizations-list') && <OrganizationsList></OrganizationsList>}
      {(viewType !== 'organizations-list') && <CausesList filter={viewType} key=""/>}
    </IonContent>  
  );
};

export default SearchContainer;
