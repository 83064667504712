import React, { useContext, useEffect, Fragment, useState } from 'react';
import './SendMessage.css';
import {
    IonList,
    IonLabel, IonItem,
    IonButton, IonIcon, IonItemDivider, IonTextarea, IonText, IonInput, IonAlert, IonLoading
} from '@ionic/react';

import { useHistory, useParams } from 'react-router';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { CauseAPI } from '../services/CauseAPI';
import { arrowBackCircleSharp } from 'ionicons/icons';
import { MessageDTO } from '../models/MessageDTO';
import { Cause } from '../models/Cause';
import { MessageAPI } from '../services/MessageAPI';
interface ContainerProps { }
const SendMessageContainer: React.FC<ContainerProps> = () => {
    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const params = useParams<{ causeId: string; }>();
    const [cause, setCause] = useState<Cause>({} as Cause);
    const causeAPI = new CauseAPI(state.User);
    const messageAPI = new MessageAPI(state.User);
    const [message, setMessage] = useState<MessageDTO>({} as MessageDTO);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [isTest, setIsTest] = useState(true);
    const onInit = () => {
        const process = async () => {
            try {
                const cause = await causeAPI.getCause(parseInt(params.causeId));
                setCause(cause);
            }
            catch (err){
                dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error",Error:err}} as AppAction);
            }
            finally {
                // ?
            }
        };
        process();

        return () => {
            causeAPI.cancel();
        }
    };

    useEffect(onInit, []);
    const sendClicked = (ev: any, isTest: boolean) => {
        ev.preventDefault();
        setIsTest(isTest);
        setShowConfirmation(true);
    };
    const goBackClicked = async (ev: any) => {
        ev.preventDefault();
        history.goBack();
    };
    const doBroadcast = async (isTest:Boolean = true) => {
        try {
            if (!isTest && cause.Attendees === 0){
                dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Error",Error:{ErrorType:'Recipients Not Found',message:"No Attendee(s)"}}} as AppAction);
                return;
            }
            let payload = {...message, Test: isTest} as MessageDTO;
            setShowLoading(true);
            let resultDto = await messageAPI.broadcastMessage(cause.CauseID, payload);
            if (resultDto.status !== 'ok'){
                dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Server Error",Error: resultDto.message}} as AppAction);
            }
            setShowSuccess(true);
            setShowLoading(false);
        }
        catch (err){
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error",Error:err}} as AppAction);
        }
        finally {
            setShowLoading(false);
        }
    };
    return (
        <Fragment>
            <IonList>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait... this might take 30-60 seconds.'}
                />
                <IonAlert
                    isOpen={showConfirmation}
                    onDidDismiss={() => setShowConfirmation(false)}
                    header={'Confirmation'}
                    message={`Are you sure to send ${isTest ? 'Test' : 'Live'} Email(s)?`}
                    buttons={['Cancel', {
                        text: 'Proceed',
                        handler: () => {
                            if (isTest) {
                                doBroadcast(true);
                            }
                            else {
                                doBroadcast(false);
                            }
                        }
                    }]}
                />
                <IonAlert
                isOpen={showSuccess}
                onDidDismiss={() => setShowSuccess(false)}
                header={'Success'}
                message={'Message has been successfully sent'}
                buttons={[
                    {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => { }
                    },
                    {
                        text: 'OK',
                        handler: () => {
                            if (!isTest){
                                history.goBack();
                            }
                        }
                    }
                ]}
                />
                <IonItem class="ion-no-padding">
                    <IonLabel>
                        <h1>
                            <IonButton className="slim-button"
                                color="medium"
                                fill="clear"
                                size="large"
                                onClick={(event) => goBackClicked(event)}>
                                <IonIcon icon={arrowBackCircleSharp} />
                            </IonButton>
                            <strong>Send Emails to Attendees</strong>
                        </h1>
                    </IonLabel>
                </IonItem>
                <h5 className="ion-text-center">{cause?.Title}</h5>
                <h6 className="ion-text-center ion-no-margin"><IonText color="medium">{cause?.Attendees} Attendee(s)</IonText></h6>
                <IonItemDivider className="narrow-divider"></IonItemDivider>
                <IonItem>
                    <IonLabel>Subject:</IonLabel>
                    <IonInput
                        placeholder="Enter email subject here (Max 200 characters)"
                        value={message.Title}
                        maxlength={200}
                        onIonChange={e => setMessage({ ...message, Title: e.detail.value! })}></IonInput>
                </IonItem>
                <IonItem lines="none">
                    <IonLabel>Body:</IonLabel>
                    <IonTextarea
                        rows={10}
                        cols={20}
                        placeholder="Enter email body text here"
                        maxlength={2000}
                        value={message.Body}
                        onIonChange={e => setMessage({ ...message, Body: e.detail.value! })}></IonTextarea>
                </IonItem>
                <IonItem class="ion-no-padding">
                    <IonLabel color="medium ion-text-right"><small>Max 2000 characters ({message.Body?.length || 0} / 2000)</small></IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Test / Additional Email(s):</IonLabel>
                    <IonInput
                        placeholder="Comma separated if more than one"
                        value={message.AdditionalRecipients}
                        onIonChange={e => setMessage({ ...message, AdditionalRecipients: e.detail.value! })}></IonInput>
                </IonItem>
                <br />
                <IonButton disabled={message.AdditionalRecipients?.length === 0 
                                     || message.Title?.length === 0 
                                     || message.Body?.length === 0} 
                            expand="full" 
                            onClick={(e) => sendClicked(e, true)}>Send Test Emails</IonButton>
                <IonText color="medium" class="ion-padding-start">* We strongly recommend to send test emails before the live broadcast</IonText>
                <br />
                <br />
                <IonButton disabled={message.Title?.length === 0 || message.Body?.length === 0} 
                            expand="full" 
                            onClick={(e) => sendClicked(e, false)} >Send LIVE Email to All {cause.Attendees} Attendee(s)</IonButton>
            </IonList>
            <br />
            <br />
            <br />
        </Fragment>
    );
};
export default SendMessageContainer;
