import React from 'react';
import './WelcomeContainer.css';
import { IonImg, IonContent, IonCard, IonCardHeader, IonButton,  IonCol, IonRow, IonGrid, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
interface ContainerProps {
  name: string;
}
// note: https://developer.liu.edu:8443/display/WD/Volunteer+App
const WelcomeContainer: React.FC<ContainerProps> = ({ name }) => {
  const history=useHistory();
  const loginClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('login')
  };
  return (
    <IonContent>
      <IonImg src="assets/logo.png" className="logo"></IonImg>
      <IonGrid className="ion-no-margin ion-margin-bottom">
        <IonRow>
          <IonCol>
            <h5 color="dark" className="ion-text-center ion-text-capitalize ion-no-margin">
              <strong>
                <small>
                  Bringing together global citizens to 
                  <br/>
                  provide exceptional service
                </small>
              </strong>
            </h5>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 className="ion-text-center ion-text-capitalize ion-no-margin">
              <IonText color="secondary"
                className="clickable"
                onClick={(event) => loginClicked(event)}>
                <strong>
                  <small>
                    Already have an account? Log in
                  </small>
                </strong>
              </IonText>
            </h5>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonCard routerLink="register/volunteer" className="ion-no-margin ion-margin-horizontal">
        <IonImg src="assets/volunteer.jpg"></IonImg>
        <hr className="ion-no-margin gold-separator"/>
        <IonCardHeader className="ion-no-padding">
          <IonButton 
            expand="full" 
            color="primary" 
            className="ion-text-center ion-no-margin">
            <h6 className="ion-text-uppercase ion-no-margin">
              <strong>
                <small>join as a volunteer</small>
              </strong>
            </h6>
          </IonButton>
        </IonCardHeader>
      </IonCard>
      <IonCard routerLink="register/organization">
        <IonImg src="assets/organization.jpg"></IonImg>
        <hr className="ion-no-margin gold-separator"/>
        <IonCardHeader className="ion-no-padding">
          <IonButton 
            expand="full" 
            color="primary" 
            className="ion-text-center ion-no-margin">
            <h6 className="ion-text-uppercase ion-no-margin">
              <strong>
                <small>join as an organization</small>
              </strong>
            </h6>
          </IonButton>
        </IonCardHeader>
      </IonCard>
    </IonContent>
  );
};

export default WelcomeContainer;
