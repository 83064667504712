import React, { useState, useContext } from 'react';
import './VerificationContainer.css';
import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { UserAPI } from '../services/UserAPI';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { ErrorTypes } from '../services/BaseAPI';
interface ContainerProps {
  role: string;
}
const VerificationContainer: React.FC<ContainerProps> = () => {
  const userApi = new UserAPI();
  const { state, dispatch } = useContext(AppContext);
  const [code1, setCode1] = useState<number>();
  const [code2, setCode2] = useState<number>();
  const [code3, setCode3] = useState<number>();
  const [code4, setCode4] = useState<number>();
  const [message, setMessage] = useState<string>();
  const history = useHistory();
  const [reSendCodeHidden, setReSendCodeHidden] = useState(true);
  let [codeInput1,setCodeInput1] = useState<any>();
  let [codeInput2,setCodeInput2] = useState<any>();
  let [codeInput3,setCodeInput3] = useState<any>();
  let [codeInput4,setCodeInput4] = useState<any>();

  const setCode = async (code: number, stepCurrent: number, stepNext: number) => {
    if (stepCurrent === 1){
      setCode1(code);
      codeInput2.setFocus();
    }
    else if (stepCurrent === 2){
      setCode2(code);
      codeInput3.setFocus();
    }
    else if (stepCurrent === 3){
      setCode3(code);
      codeInput4.setFocus();
    }
    else if (stepCurrent === 4){
      setCode4(code);
    }
  }
  const verifyClicked = async (ev: any) => {
    ev.preventDefault();

    if (!code1 && !code2 && !code3 && !code4) {
      setMessage("Please enter code");
      return;
    }

    try{
      const pin = [code1, code2, code3, code4].join("");
      console.log("UserNonce",state.UserNonce);
      const currentUsr = await userApi.forgotPasswordVerifyPin({...state.UserNonce, Pin: pin});
      console.log(currentUsr);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: currentUsr } } as AppAction);
      dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Pin Verified"}} as AppAction);
      setTimeout(() => {
        history.push('password');
      }, 3000);
      
    }
    catch(err){
      if(err.ErrorType===ErrorTypes.NETWORK){
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error Encountered",Error:err}} as AppAction);
      }
      else{
        if(err.message.indexOf("limit exceeded") > -1){
          dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Your pin verification failed too many times, please request a new pin"}} as AppAction);
          setReSendCodeHidden(false);
        }
        else{
          dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Your pin verification failed, please try again",Error:err}} as AppAction);
        }
        setMessage("");
      }
    }
    finally{

    }
  };

  const forgotPasswordClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('forgotpassword')
  };

  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-padding-start">
          <IonCol>
            <IonLabel><h1><strong>Verification</strong></h1></IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="ion-padding">
          <IonCol>
          <IonLabel className="ion-text-start" color="medium">Please enter the verification code that was sent to your email <IonText color="danger">*</IonText></IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
          <IonInput className="code" type="tel" id="code1" placeholder="*" clearInput={true} min="0" max="9" maxlength={1} ref={ (input) => { setCodeInput1(input) } } onIonChange={e => setCode(parseInt(e.detail.value!, 10), 1, 2)} autofocus={true}></IonInput>
          </IonCol>
          <IonCol>
          <IonInput className="code" type="tel" id="code2" placeholder="*" clearInput={true} min="0" max="9" maxlength={1} ref={ (input) => { setCodeInput2(input) } } onIonChange={e => setCode(parseInt(e.detail.value!, 10), 2, 3)}></IonInput>
          </IonCol>
          <IonCol>
          <IonInput className="code" type="tel" id="code3" placeholder="*" clearInput={true} min="0" max="9" maxlength={1} ref={ (input) => { setCodeInput3(input) } } onIonChange={e => setCode(parseInt(e.detail.value!, 10), 3, 4)}></IonInput>
          </IonCol>
          <IonCol>
          <IonInput className="code" type="tel" id="code4" placeholder="*" clearInput={true} min="0" max="9" maxlength={1} ref={ (input) => { setCodeInput4(input) } } onIonChange={e => setCode(parseInt(e.detail.value!, 10), 4, 1)}></IonInput>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList lines="full" className="ion-padding ion-text-center">
        <IonButton expand="full" hidden={!reSendCodeHidden} className="ion-margin-top" color="primary" onClick={(event) => verifyClicked(event)}>Verify Code</IonButton>
        <IonButton expand="full" hidden={reSendCodeHidden} className="ion-margin-top" color="primary" onClick={(event) => forgotPasswordClicked(event)}>Re-send Code</IonButton>
        <IonItem lines="none">
          <IonText color="danger">{message}</IonText>
        </IonItem>
        <IonLabel hidden={!reSendCodeHidden} className="text-link clickable ion-text-center ion-padding-top" color="primary" onClick={(event) => forgotPasswordClicked(event)}>Forgot Password</IonLabel>
      </IonList>
    </IonContent>
  );
};

export default VerificationContainer;
