import React, { useEffect, useContext } from 'react';
import './UserOrganizationContainer.css';
import { useHistory } from 'react-router';
import { IonContent, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonText, 
  IonCardTitle, IonCard, IonImg, IonIcon, IonCardHeader, 
  IonCardContent } from '@ionic/react';
import { AppContext } from '../contexts/AppContext';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { Organization } from '../models/Organization';
import { CauseAPI } from '../services/CauseAPI';
import { arrowBackCircleSharp, listOutline, businessOutline } from 'ionicons/icons';
import { FileAPI } from '../services/FileAPI';
import { Utilities } from '../services/Utilities';
interface ContainerProps {
}
const UserOrganizationContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { state, } = useContext(AppContext);
  const orgApi = new OrganizationAPI(state.User);
  const causeApi = new CauseAPI(state.User);
  const fileApi = new FileAPI(state.User);

  const onInit = () => { 
    return ()=>{
      causeApi.cancel();
      orgApi.cancel();
      fileApi.cancel();
    }
  }
  useEffect(onInit, []);

  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  const detailsClicked = async (ev:any,organization:Organization)=>{
    history.push('../../organization/profile',{organization:organization});
  }
  const causeListClicked = async (ev:any,organization:Organization)=>{
    history.push('../../organization/'+organization.OrgID+'/causes');
  }
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-padding">
          <IonCol>
            <IonLabel>
              <h1>
                <IonButton className="slim-button"
                    color="medium"
                    fill="clear"
                    size="large"
                    onClick={(event) => goBackClicked(event)}>
                    <IonIcon icon={arrowBackCircleSharp} />
                </IonButton>
                <strong>Organizations</strong>
              </h1>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {
              state?.User?.Organizations?.map( (organization: Organization) => {
                return <IonCard 
                      color="light"
                      key={'row-' + organization?.OrgID}>
                  <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                      <IonImg className="ion-margin" src={organization.PhotoID !== 0 ? fileApi.buildFileEndpoint(organization.PhotoID+"") : 'assets/blank.svg'} />
                    </IonCol>
                    <IonCol></IonCol>
                  </IonRow>
                  <IonCardHeader className="ion-no-padding ion-padding-start ion-padding-end">
                    <IonCardTitle className="ion-padding-start"><a href={Utilities.getUrl(organization?.Website)} target="_blank"><h2><strong>{organization?.Name}</strong></h2></a></IonCardTitle>
                    <IonText color="dark" className="ion-padding-start">{organization?.Address}</IonText><br/>
                    <IonText color="dark" className="ion-padding-start" key={'row-addr-' + organization?.OrgID}>{organization?.City || 'Brooklyn'}, {organization?.State || 'NY'}</IonText>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonButton fill="outline" expand="block" size="small"onClick={(event)=>causeListClicked(event, organization)}><IonIcon className="icon-right-padding" icon={listOutline}></IonIcon>Edit Events</IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton fill="outline" expand="block" size="small" onClick={(event)=>detailsClicked(event, organization)}><IonIcon className="icon-right-padding" icon={businessOutline}></IonIcon>Edit Profile</IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              }) 
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default UserOrganizationContainer;
