import { BaseAPI } from './BaseAPI';
import { Lookup } from "../models/Lookup";
import { Cause } from "../models/Cause";
import { CauseSearch } from '../models/CauseSearch';
import { Organization } from '../models/Organization';
import { OrgSearch } from '../models/OrgSearch';
import { Content } from '../models/Content';
import { Utilities } from '../services/Utilities';
export class LookupAPI extends BaseAPI {
    public async getLookup(): Promise<Lookup> {
        return await this.doGet<Lookup>('lookup');
    }
    public async getContents(): Promise<Content[]> {
        return await this.doGet<Content[]>('contents');
    }
    public async updateContent(aContent:Content): Promise<Content> {
        return await this.doPut<Content,Content>('content',aContent);
    }
    public async getCauses(): Promise<Cause[]> {
        return await this.doGet<Cause[]>('lookup/causes');
    }
    public async getCause (causeId:number): Promise<Cause> {
        return await this.doGet<Cause>(`lookup/cause/${causeId}`);
    }
    public async searchCauses (aCauseSearch:CauseSearch): Promise<Cause[]> {
        const params = Utilities.buildQuery(aCauseSearch);
        return await this.doGet<Cause[]>(`lookup/search/causes?${params}`);
    }
    public async getOrganizations(orgSearch: OrgSearch): Promise<Organization[]>{
        const params = Utilities.buildQuery(orgSearch);
        return await this.doGet<Organization[]>(`lookup/search/organizations?${params}`);
    }
}