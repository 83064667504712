import React, { useContext, useState, useEffect } from 'react';
import './UserContainer.css';
import { IonSelect, IonSelectOption, IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, IonText, IonDatetime, IonBadge, IonIcon } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { FileAPI } from '../services/FileAPI';
import { User } from '../models/User';
import { TagSelector } from './TagSelector';
import { arrowBackCircleSharp } from 'ionicons/icons';
import MediaWidget from '../widgets/MediaWidget';

// Note: https://ionicframework.com/blog/using-react-hooks-in-an-ionic-react-app/
interface ContainerProps {
  user?:User
}
const UserContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId?: string; }>();
  const { state, dispatch } = useContext(AppContext);
  const passedUser = (history.location.state as ContainerProps)?.user as User;
  const [profile, setProfile] = useState<User>(state.User);
  const [isSelf, setIsSelf] = useState<boolean>(userId ? false : true);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [media,setMedia] = useState<File[]>([] as File[]);
  const userApi = new UserAPI(state.User);
  const fileApi = new FileAPI(state.User);
 
  const onInit = () => { 
    const process = async ()=>{
      let user:User;
      if(userId){
        user = passedUser;
        setIsSelf(false);
      }
      else{
        user = profile;
        setIsSelf(true);
      }
      setProfile(user);
      
      const files = (await fileApi.getFiles()).map((media)=>{ 
        return {
          name: media.Name,
          src:  media.FileID,
          fn: (src:string)=>{ return fileApi.buildFileEndpoint.bind(fileApi)(src,300,300); },
          selected: user.PhotoID === media.FileID ? true : false 
        } as any
      })
      setMedia(files);
    }
    process();
    return ()=>{
      userApi.cancel();
      fileApi.cancel();
    }
  }
  useEffect(onInit, []);

  const resendEmailVerification = ()=>{
    userApi.verifyEmail();
    dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Verification email resent"}} as AppAction);
  };
  const handleProfileChange = (name: string, value: string) => {
    let usr: any = {
      ...profile,
      [name]: value
    };
    setProfile(usr as User);
  };
  const saveProfileClicked = async (ev: any) => {
    ev.preventDefault();
    // clean up and reconstruct payload
    const usr: User = {
      ...state.User,
      PhotoID: profile.PhotoID,
      FirstName: profile.FirstName,
      LastName: profile.LastName,
      Email: profile.Email,
      Gender: profile.Gender,
      Phone: profile.Phone,
      DOB: profile.DOB,
      Tags: profile.Tags
    }
    await doProfileSave(usr);
  };
  const doProfileSave = async (user:User) =>{
    setIsLoading(true);
    // clean up and reconstruct payload
    const usr: User = user
    try {
      // validate profile
      let errors: string[] = [];
      if (!profile?.FirstName) {
        errors.push("First Name");
      }
      if (!profile?.LastName) {
        errors.push("Last Name");
      }

      if (!profile?.Email) {
        errors.push("Email");
      }
      else{
        if(!(profile?.Email.indexOf("@")>0)){
          errors.push("Email (invalid format)");
        }
      }
      if (!profile?.Phone) {
        errors.push("Phone");
      }
      if (state?.User?.UserType === "V"){
        if (!profile?.DOB) {
          errors.push("DOB");
        }
        else{
          const Today = new Date();
          const COPAMinimum = new Date(Today.getFullYear()-13,Today.getMonth(),Today.getDate()).toISOString();
          if(profile?.DOB.slice(0,10) > COPAMinimum.slice(0,10)){
            errors.push("Age Over 13 (COPPA Compliance)");
          }
        }
        if (!profile?.Gender) {
          errors.push("Gender");
        }
      }
      setMessages(errors);
      if(errors.length > 0) {
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Please review your submission"}} as AppAction);
        return;
      }
      await userApi.updateUserProfile(usr);
      setProfile(usr);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: usr } } as AppAction);
      dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"User profile updated"}} as AppAction);
    }
    catch (err) {
      console.log(err);
      alert('error (User)');
    }
    finally {
      setIsLoading(false);
    }
  }
  const selectedTagsChanged = (selectedTags: string[]) => {
    console.log('selected items', selectedTags);
    setProfile({ ...profile, Tags: selectedTags });
  };
  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-padding">
          <IonCol>
            <IonLabel>
              <h1>
              <IonButton className="slim-button"
                color="medium"
                fill="clear"
                size="large"
                onClick={(event) => goBackClicked(event)}>
                <IonIcon icon={arrowBackCircleSharp} />
              </IonButton>
              <strong>Personal Information</strong>
              </h1>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem lines="none">
                <MediaWidget 
                  disabled={!isSelf}
                  value={(media as any)}
                  post={(async (media)=>{ return await fileApi.storeFile(media)})}
                  put={(async (media)=>{ 
                    handleProfileChange('PhotoID',media.src);
                    doProfileSave({
                      ...state.User,
                      PhotoID:media.src
                    });
                    return media;
                  })}
                  restriction={({
                    extensions:["png","gif","jpg","jpeg"],
                    size:5242880
                  })}
                  slim={true}
                  avatar={true}></MediaWidget>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">First Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isSelf} placeholder="e.g. John" required value={profile.FirstName} type="text" onIonChange={e => handleProfileChange('FirstName', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Last Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isSelf} placeholder="e.g. Smith" required value={profile.LastName} type="text" onIonChange={e => handleProfileChange('LastName', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem hidden={state?.User?.UserType !== "V"}>
                <IonLabel position="stacked" color="medium">Gender <IonText color="danger">*</IonText></IonLabel>
                <IonSelect disabled={!isSelf} value={profile.Gender}
                  interface="popover"
                  placeholder="Select One"
                  onIonChange={e => handleProfileChange('Gender', e.detail.value)}>
                  <IonSelectOption value="F">Female</IonSelectOption>
                  <IonSelectOption value="M">Male</IonSelectOption>
                  <IonSelectOption value="O">Other</IonSelectOption>
                  <IonSelectOption value="U">Unspecified</IonSelectOption>
                </IonSelect>
              </IonItem>              
              <IonItem disabled={!isSelf} hidden={state?.User?.UserType !== "V"}>
                <IonLabel position="stacked" color="medium">DOB <IonText color="danger">*</IonText></IonLabel>
                <IonDatetime displayFormat="MMMM DD YYYY"
                  value={profile.DOB}
                  placeholder="Select Date"
                  onIonChange={e => handleProfileChange('DOB', e.detail.value!)}></IonDatetime>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Phone</IonLabel>
                <IonInput disabled={!isSelf} placeholder="e.g. 555-555-5555" type="tel" maxlength={20} value={profile?.Phone} onIonChange={e => handleProfileChange('Phone', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonLabel position="stacked" color="medium">Email {/* <IonText color="danger">*</IonText> */}
                {( isSelf && (state.User.IsEmailConfirmed ?
                  <IonBadge className="ion-margin-start" color="secondary">Verified</IonBadge> : 
                  <IonButton disabled={isLoading} className="ion-no-margin ion-margin-start" color="primary" onClick={resendEmailVerification}>Re-send Verification Email</IonButton>
                ))}
                </IonLabel>
                {/* <IonInput  disabled={!isSelf} placeholder="e.g. john.smith@globalserviceinstitute.org" required disabled value={profile.Email} type="email" onIonChange={e => handleProfileChange('Email', e.detail.value!)}></IonInput> */}
                <IonText className="ion-margin-top ion-margin-bottom" color={!isSelf ? "medium" : "default"}>{profile.Email}</IonText>
              </IonItem>
              {(state?.User?.UserType === "V" && 
                <TagSelector
                  title="Select your area(s) of interest"
                  tags={state?.Lookup?.Tags || []}
                  selectedTags={profile.Tags || []}
                  onChangeHandler={selectedTagsChanged}
                />
              )}
              <IonRow>
                <IonCol>
                  {(messages.length>0? <IonLabel color="medium" className="d-block">Please Enter:</IonLabel> : '')}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
                </IonCol>
              </IonRow>
              <IonButton hidden={!isSelf} disabled={isLoading} color="primary" expand="full" className="ion-margin-top" onClick={saveProfileClicked}>Save My Profile</IonButton>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default UserContainer;
