import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import './OrganizationProfileContainer.css';
import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, 
          IonButton, IonInput,  IonText, IonBadge, IonIcon, IonTextarea } from '@ionic/react';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { Organization } from '../models/Organization';
import { User } from '../models/User';
import { ErrorTypes } from '../services/BaseAPI';
import { UserAPI } from '../services/UserAPI';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { FileAPI } from '../services/FileAPI';
import { arrowBackCircleSharp } from 'ionicons/icons';
import StateWidget from '../widgets/StateWidget';
import MediaWidget from '../widgets/MediaWidget';

interface ContainerProps {
  organization? : Organization
}
const OrganizationProfileContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { orgId } = useParams<{ orgId?: string; }>();
  const { state, dispatch } = useContext(AppContext);
  const passedOrg = (history.location.state as ContainerProps)?.organization as Organization;
  const [profile, setProfile] = useState( (state.User.UserType === 'A' ? passedOrg : state.User.Organizations[0]) as Organization);
  const [isAccessible, setIsAccessible] = useState<boolean>(orgId ? false : true);
  const [messages, setMessages] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);
  const [media,setMedia] = useState<File[]>([] as File[]);
  const userApi = new UserAPI(state.User);
  const orgApi = new OrganizationAPI(state.User);
  const fileApi = new FileAPI(state.User);

  const onInit = () => { 
    const processEffect = async () => {
      if(orgId){
        setIsAccessible(false);
      }
      else{
        setIsAccessible(true);
      }
    
      const usr:User = await userApi.getUser();
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: usr }} as AppAction);
      const foundOrg = usr.UserType === 'A' ? passedOrg : usr?.Organizations?.find((organization:Organization)=>{
        return organization.OrgID===passedOrg?.OrgID
      });
      setProfile(foundOrg ? foundOrg : usr?.Organizations![0]);
      setMedia([
        {
          name: "Organization Photo",
          src:  profile?.PhotoID,
          fn: (src:string)=>{ return fileApi.buildFileEndpoint.bind(fileApi)(src,300,300); },
          selected: profile?.PhotoID === 0 ? false : true,
        } as any
      ]);
    };
    processEffect();
    return ()=>{
      userApi.cancel();
      orgApi.cancel();
      fileApi.cancel();
      console.log("cancelled");
    };
  }
  useEffect(onInit, []);
  
  const resendEmailVerification = ()=>{
    orgApi.verifyEmail(profile.OrgID);
    dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Verification email resent"}} as AppAction);
  };
  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  const handleProfileChange = (name: string, value: string) => {
    let org: any = {
      ...profile,
      [name]: value
    };
    setProfile(org as Organization);
  };
  const doProfileSave = async (org:Organization)=>{
    setIsLoading(true);
    try {
      let errors: string[] = [];
      if (!org?.Name) {
        errors.push("Name");
      }
      if (!org?.Address) {
        errors.push("Address");
      }
      if (!org?.City) {
        errors.push("City");
      }
      if (!org?.State) {
        errors.push("State");
      }
      if (!org?.Zip) {
        errors.push("Zip");
      }
      if (!org?.Phone) {
        errors.push("Phone");
      }
      if (!org?.Website) {
        errors.push("Website");
      }
      if (!org?.Email) {
        errors.push("Email");
      }
      else{
        if(!(org?.Email.indexOf("@")>0)){
          errors.push("Email (invalid format)");
        }
      }
      
      setMessages(errors);
      if (errors.length > 0) {
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE, payload:{Message:"Please review your submission"}} as AppAction);
        return;
      }
      let newUserState = state.User;
      await orgApi.updateOrgProfile(org);
      newUserState.Organizations = newUserState.Organizations.map((organization:Organization)=>{
        if(organization.OrgID===org.OrgID){
          return org;
        }
        else{
          return organization;
        }
      });
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: newUserState } } as AppAction);
      dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Organization profile updated"}} as AppAction);
    }
    catch (err) {
      if(err.ErrorType===ErrorTypes.NETWORK){
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error Encountered",Error:err}} as AppAction);
      }
      else{
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Validation Error Encountered",Error:err}} as AppAction);
      }
    }
    finally {
      setIsLoading(false);
    }
  };
  const saveProfileClicked = async (ev: any) => {
    ev.preventDefault();
    await doProfileSave(profile);
  };

  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-padding">
          <IonCol>     
            <IonLabel>
              <h1>
                <IonButton className="slim-button"
                    color="medium"
                    fill="clear"
                    size="large"
                    onClick={(event) => goBackClicked(event)}>
                    <IonIcon icon={arrowBackCircleSharp} />
                </IonButton>
                <strong>Organization Profile</strong>
              </h1>
            </IonLabel>
            <IonBadge className="ion-margin-top" color={profile?.IsConfirmed?"primary":"danger"}>{profile?.IsConfirmed?"🛡 Verified":"🛡 Unverified"}</IonBadge>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem lines="none">
                <MediaWidget 
                  disabled={!isAccessible}
                  value={(media as any)}
                  post={(async (media)=>{ return await fileApi.storeFile(media)})}
                  put={(async (media)=>{ 
                    handleProfileChange('PhotoID',media.src);
                    doProfileSave({
                      ...profile,
                      PhotoID:media.src
                    });
                    return media;
                  })}
                  restriction={({
                    extensions:["png","gif","jpg","jpeg"],
                    size:5242880
                  })}
                  slim={true}
                  defaultImg={"/assets/blank.svg"}></MediaWidget>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Organization Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} required placeholder="e.g. My Organization" type="text" value={profile?.Name} onIonChange={e => handleProfileChange('Name', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Description </IonLabel>
                <IonTextarea disabled={!isAccessible} placeholder="Let people know a little bit about your organization. Max. 2000 characters." required maxlength={2000} autoGrow={true} spellcheck={true} value={profile?.Description} onIonChange={e => handleProfileChange('Description', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Address <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} required placeholder="e.g. 1 Main Street" type="text" value={profile?.Address} onIonChange={e => handleProfileChange('Address', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Apt, Suite, Unit </IonLabel>
                <IonInput disabled={!isAccessible} placeholder="e.g. Suite 101" value={profile?.Address2} required type="text" onIonChange={e => handleProfileChange('Address2', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">City <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} placeholder="e.g. New York"value={profile?.City} required type="text" onIonChange={e => handleProfileChange('City', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">State <IonText color="danger">*</IonText></IonLabel>
                <StateWidget 
                  disabled={!isAccessible}
                  value={profile?.State}
                  onChange={e => handleProfileChange('State', e.detail.value!)}>
                </StateWidget>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Zip <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} required placeholder="e.g. 12345" type="tel" value={profile?.Zip} maxlength={5} onIonChange={e => handleProfileChange('Zip', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Phone <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} required placeholder="e.g. 555-555-5555" type="tel" value={profile?.Phone} onIonChange={e => handleProfileChange('Phone', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Website <IonText color="danger">*</IonText></IonLabel>
                <IonInput disabled={!isAccessible} required placeholder="e.g. https://www.globalserviceinstitute.org" type="text" value={profile?.Website} onIonChange={e => handleProfileChange('Website', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Email {/* <IonText color="danger">*</IonText> */}
                {(isAccessible && (profile?.IsEmailConfirmed ? 
                  <IonBadge className="ion-margin-start" color="secondary">Verified</IonBadge> : 
                  <IonButton disabled={isLoading} className="ion-no-margin ion-margin-start" color="primary" onClick={resendEmailVerification}>Re-send Verification Email</IonButton>
                ))}
                </IonLabel>
                {/* <IonInput required disabled placeholder="e.g. john.smith@globalserviceinstitute.org" type="email" value={profile?.Email} onIonChange={e => handleProfileChange('Email', e.detail.value!)}></IonInput> */}
                <IonText className="ion-margin-top ion-margin-bottom" color={!isAccessible?"medium":"default"}>{profile?.Email}</IonText>
              </IonItem>
              <IonRow>
                <IonCol>
                  {(messages.length>0? <IonLabel color="medium" className="d-block">Please Enter:</IonLabel> : '')}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
                </IonCol>
              </IonRow>
              <IonButton hidden={!isAccessible} className="ion-margin-top ion-margin-bottom" color="primary" expand="full" onClick={saveProfileClicked}>Save My Organization</IonButton>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default OrganizationProfileContainer;
