import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { ResultDTO } from "../models/ResultDTO";
import { User } from "../models/User";
import { isPlatform} from "@ionic/react";

// Reference: https://medium.com/@enetoOlveda/how-to-use-axios-typescript-like-a-pro-7c882f71e34a
// TODO: https://gist.github.com/dsherret/cf5d6bec3d0f791cef00
export enum ErrorTypes {
    NETWORK="NETWORK",
    VALIDATION="VALIDATION",
    SECURITY="SECURITY",
    CANCEL="CANCEL"
}

export class BaseAPI {
    private api: AxiosInstance;
    private source:CancelTokenSource;
    public baseUrl:string;

    public constructor (user?:User) {
        this.baseUrl =  "https://api.globalserviceinstitute.org/";
        this.source = axios.CancelToken.source();
        if (window.location.hostname.indexOf('globalserviceinstitute.org') === -1 
            && (isPlatform('mobileweb') || isPlatform('desktop')) ){
            this.baseUrl =  "http://work.liu.edu/volunteer/api/";
        }
        const config: AxiosRequestConfig = {
                timeout: 50000,
                baseURL: this.baseUrl,
                headers: {
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    "Pragma": "no-cache",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Token": user?.Token // TODO: Uppercase "Token" should be allowed here
                }
        };
        this.api = axios.create(config);
        
        // this middleware is been called right before the http request is made.
        this.api.interceptors.request.use((param: AxiosRequestConfig) => ({
            ...param,
            cancelToken: this.source?.token
        }));
        
        // // this middleware is been called right before the response is get it by the method that triggers the request
        // this.api.interceptors.response.use((param: AxiosResponse) => ({
        //     ...param
        // }), ((error: any)=>{
        //     //error = this.resolveErrorType(error);
        //     // error.constructor?.name == 'Cancel'
        //     //console.log('intercepter',error);
        //     return error;
        // }));
        
    }
    public setToken(token:string){
        this.api.defaults.headers["Token"] = token;
    }

    protected getUri (config?: AxiosRequestConfig): string {
        return this.api.getUri(config);
    }

    protected request<T, R = AxiosResponse<ResultDTO<T>>> (config: AxiosRequestConfig): Promise<R> {
        return this.api.request(config);
    }

    public async doRequest<T, D>(config: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.request<T>(config) as AxiosResponse<ResultDTO<T>>;
            const { data } = response;
            if (data?.status === 'ok') {
                return data.result as T;
            }
            throw new Error(data?.message);
        }
        catch (error) {
            throw this.resolveErrorType(error);
        }
    }


    protected get<T, R = AxiosResponse<ResultDTO<T>>> (url: string, config?: AxiosRequestConfig): Promise<R> {        
        return this.api.get(url, config);
    }

    protected async doGet<T>(url:string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.get<T>(url, config) as AxiosResponse<ResultDTO<T>>;
            const { data } = response;
            if (data?.status === 'ok') {
                return data.result as T;
            }
            throw new Error(data?.message);
        }
        catch (error) {
            throw this.resolveErrorType(error);
        }
    }

    protected delete<T, R = AxiosResponse<ResultDTO<T>>> (url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, {
            ...config,
            data:data
        });
    }

    protected async doDelete<T, D>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.delete<T>(url, JSON.stringify(payload), config) as AxiosResponse<ResultDTO<T>>;
            const { data } = response;
            if (data?.status === 'ok') {
                return data.result as T;
            }
            throw new Error(data?.message);
        }
        catch (error) {
            throw this.resolveErrorType(error);
        }
    }

    protected post<T, R = AxiosResponse<ResultDTO<T>>> (url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config);
    }

    protected async doPost<T, D>(url: string, payload: D, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.post<T>(url, JSON.stringify(payload), config) as AxiosResponse<ResultDTO<T>>;
            const { data } = response;
            if (data?.status === 'ok') {
                return data.result as T;
            }
            throw new Error(data?.message);
        }
        catch (error) {
            throw this.resolveErrorType(error);
        }
    }

    protected put<T, R = AxiosResponse<ResultDTO<T>>> (url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.put(url, data, config);
    }

    protected async doPut<T, D>(url: string, payload: D, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.put<T>(url, JSON.stringify(payload), config) as AxiosResponse<ResultDTO<T>>;
            const { data } = response;
            if (data?.status === 'ok') {
                return data.result as T;
            }
            throw new Error(data?.message);
        }
        catch (error) {
            throw this.resolveErrorType(error);
        }
    }

    // could be called onunmounted in component
    public cancel(reason?:string) {
        this.source.cancel(reason);
    }
    protected resolveErrorType(error:Error):Error{
        let newErr = (error as any);
        if( newErr["isAxiosError"] ){
            newErr["ErrorType"]=ErrorTypes.NETWORK;
        }
        else if (error.name === 'Error') {
            newErr["ErrorType"]=ErrorTypes.VALIDATION;     
        }
        else if (error.constructor?.name === 'Cancel'){
            newErr["ErrorType"]=ErrorTypes.CANCEL;
        }
        return newErr;
    }
}