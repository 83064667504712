import React, { Fragment, useState } from 'react';
import './FavoriteContainer.css';
import CausesList from './CausesList';
import { IonToolbar, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import OrganizationsList from './OrganizationsList';
interface ContainerProps {
} const FavoriteContainer: React.FC<ContainerProps> = () => {
    const [view, setView] = useState('favorite');
    return (
        <Fragment>
            <div className="ion-padding-top">
                <IonToolbar>
                    <IonSegment value={view} onIonChange={e => setView(e.detail.value!)} color="primary">
                        <IonSegmentButton value="favorite">
                            <IonLabel>Favorite</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="volunteer">
                            <IonLabel>Registered</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="org-favorite">
                            <IonLabel>Organization</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
                {(view === 'org-favorite') && <div className="ion-margin-top"><OrganizationsList option="favorite"/></div>}
                {(view !== 'org-favorite') && <CausesList filter={view} key={'tab-'+view} />}
            </div>
        </Fragment>
    );
};

export default FavoriteContainer;
