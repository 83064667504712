import { BaseAPI } from './BaseAPI'
import { Organization } from "../models/Organization";
import { User } from "../models/User";
import { UserNonce } from "../models/UserNonce";
export class OrganizationAPI extends BaseAPI {
    constructor (user:User = ({} as User)) {
        super(user);
    }
    public async getOrganizations() : Promise<Organization[]>{
        return await this.doGet<Organization[]>("organizations");
    }
    public async getFavoriteOrganizations() : Promise<Organization[]>{
        return await this.doGet<Organization[]>("search/organizations");
    }
    public async getOrganization(orgId: number): Promise<Organization> {
        return await this.doGet<Organization>(`organization/${orgId}`);
    }
    public async verifyEmail(orgId: number): Promise<UserNonce> {
        return await this.doGet<UserNonce>(`auth/organization/${orgId}/email/verify`);
    }
    public async addOrgProfile(orgProfile:Organization): Promise<Organization> {
        return await this.doPost<Organization,Organization>('organization', orgProfile);
    }
    public async updateOrgProfile (orgProfile:Organization): Promise<Organization> {
        return await this.doPut<Organization,Organization>('organization', orgProfile);
    }
    public async approveOrgProfileToggle (orgProfile:Organization): Promise<Organization> {
        return await this.doPut<Organization,Organization>('organization/approve', orgProfile);
    }
    public async verifyOrgProfileToggle (orgProfile:Organization): Promise<Organization> {
        return await this.doPut<Organization,Organization>('organization/verify', orgProfile);
    }
    public async deleteOrganization(orgProfile:Organization): Promise<Organization> {
        return await this.doDelete<Organization,Organization>('organization',orgProfile);
    }
}