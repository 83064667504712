import {BaseAPI} from './BaseAPI'
import { Cause } from "../models/Cause";
import { VolunteerDTO } from "../models/VolunteerDTO";
import { CauseSearch } from "../models/CauseSearch";
import { CauseFile } from "../models/CauseFile"; 
import { Utilities } from "../services/Utilities";
export class CauseAPI extends BaseAPI {

    public async getCauses (): Promise<Cause[]> {
        return await this.doGet<Cause[]>('causes');
    }
    public async getCause (causeId:number): Promise<Cause> {
        return await this.doGet<Cause>(`cause/${causeId}`);
    }
    public async searchCauses (aCauseSearch:CauseSearch): Promise<Cause[]> {
        const params = Utilities.buildQuery(aCauseSearch);
        return await this.doGet<Cause[]>(`search/causes?${params}`);
    }
    public async applyVolunteer (volunteerDto: VolunteerDTO): Promise<Cause[]> {
        return await this.doPost<Cause[], VolunteerDTO>('cause/volunteer', volunteerDto);
    }
    public async getVolunteers(causeId:number): Promise<VolunteerDTO[]>{
        return await this.doGet<VolunteerDTO[]>(`cause/${causeId}/volunteers`);
    }
    public async removeVolunteer (volunteerDto: VolunteerDTO): Promise<Cause[]> {
        return await this.doDelete<Cause[], VolunteerDTO>('cause/volunteer', volunteerDto);
    }
    public async addCause (cause: Cause): Promise<Cause> {
        return await this.doPost<Cause, Cause>('cause', cause);
    }
    public async updateCause (cause: Cause): Promise<Cause> {
        return await this.doPut<Cause, Cause>('cause', cause);
    }
    public async replaceCauseFile (causeFile: CauseFile): Promise<Cause>{
        return await this.doPost<Cause, CauseFile>('cause/file/replace', causeFile);
    }
}