import React, { useContext, useEffect, Fragment, useState } from 'react';
import './CauseVolunteerContainer.css';
import {
    IonCard, IonCardHeader,
    IonCardSubtitle, IonCardTitle,
    IonCardContent, IonImg, IonList, 
    IonLabel, IonSkeletonText, IonThumbnail, IonListHeader, IonItem, IonAvatar, IonSearchbar, IonToolbar, IonButton, IonIcon
} from '@ionic/react';

import { useHistory, useParams } from 'react-router';
import { AppContext, AppActionTypes, AppAction, AppNetworkStatusTypes } from '../contexts/AppContext';
import { VolunteerDTO } from '../models/VolunteerDTO';
import { LookupAPI } from '../services/LookupAPI';
import { CauseAPI } from '../services/CauseAPI';
import { FileAPI } from '../services/FileAPI';
import { arrowBackCircleSharp } from 'ionicons/icons';
import { User } from '../models/User';
interface ContainerProps {
    filter: string;
}
// Note: filter - search, favorite, manage, landing
const CauseVolunteerContainer: React.FC<ContainerProps> = ({ filter = 'search' }) => {
    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const params = useParams<{ causeId: string; }>();
    const [volunteers,setVolunteers] = useState<VolunteerDTO[]>([]);
    const [search,setSearch] = useState<string>("");
    const causeAPI = new CauseAPI(state.User);
    const lookupAPI = new LookupAPI();
    const fileAPI = new FileAPI(state.User);
    useEffect(() => { 
        const processCauses = async () => {
            dispatch({ type: AppActionTypes.START_LOADING } as AppAction);
            try {
                let res: Array<VolunteerDTO> = [];
                res = await causeAPI.getVolunteers(parseInt(params.causeId));
                setVolunteers(res);
            }
            catch (err) {
                dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR (CauseVolunteers)', Error: err}} as AppAction);
            }
            finally {
                dispatch({ type: AppActionTypes.END_LOADING } as AppAction);
            }
        }
        processCauses();
        return () => {
            causeAPI.cancel();
            lookupAPI.cancel();
            fileAPI.cancel();
        };
    }, [state?.Lookup?.Contents]);
    const detailsClicked = (ev: any, item: VolunteerDTO) => {
        ev.preventDefault();
        history.push(`../../user/profile/${item.UserID}`, {user:item as User});
    };
    const goBackClicked = async (ev: any) => {
        ev.preventDefault();
        history.goBack();
    };
    const  showSendMessage = (ev:any, causeId:number) => {
        ev.preventDefault();
        history.push(`../../message/${causeId}`)
    };
    return (
        <Fragment>
            {/* Skelton */}
            {Array(20).fill(1).map((item,idx)=><IonList key={`list-item-${idx}`} hidden={state?.NetworkStatus !== AppNetworkStatusTypes.PROGRESS}>
                <IonItem hidden={filter !== 'search'} detail={true}>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            {Array(4).fill(1).map((item,idx)=><p key={`p-${idx}`}><IonSkeletonText animated style={{ width: '100%' }} /></p>)}
                        </IonLabel>
                </IonItem>
                <IonCard hidden={filter === 'search'}>
                    <IonCardHeader>
                        <IonThumbnail style={{width:'100%', height:'100px'}}>
                            <IonSkeletonText animated />
                        </IonThumbnail>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonLabel>
                            {Array(5).fill(1).map((item,idx)=><p key={`p-${idx}`}><IonSkeletonText animated style={{ width: '100%' }} /></p>)}
                        </IonLabel>
                    </IonCardContent>
                </IonCard>               
            </IonList>)}
            <IonToolbar>
                <IonSearchbar value={search} onKeyUp={(e:any)=>setSearch(e.target.value!)}></IonSearchbar>
            </IonToolbar>
            <IonListHeader className="causes-search-header">
                <IonButton className="slim-button list-header-inline"
                    color="light"
                    size="default"
                    onClick={(event) => goBackClicked(event)}>
                    <IonIcon icon={arrowBackCircleSharp} />
                </IonButton>
                <IonLabel>EVENT VOLUNTEERS ({volunteers.length})</IonLabel>
            </IonListHeader>
            <IonList className="causes-list">
            {/* Repeater */}
            {   volunteers.filter((volunteer:VolunteerDTO)=>{
                    return (
                        volunteer?.FirstName?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
                        volunteer?.LastName?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
                        volunteer?.Email?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
                        volunteer?.Phone?.toLowerCase().indexOf(search?.toLowerCase()) > -1
                    );
                }).map((item: VolunteerDTO, index: number) =>
                    <IonItem detail={true} 
                                className="causes-search-item"
                                hidden={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS || filter !== 'search'} 
                                key={'row-' + item?.CUID} 
                                onClick={((event) => detailsClicked(event, item))}>
                        <IonAvatar slot="start">
                            {(<IonImg src={item?.PhotoID > 0 ? fileAPI.buildFileEndpoint(item?.PhotoID+"",30,30):"/assets/profile.jpg"}></IonImg>)}
                        </IonAvatar>
                        <IonLabel>
                            <h3 className="causes-search-datetime">{item?.FirstName+" "+item?.LastName}</h3>
                            <h2>{item?.Email || 'N/A'}</h2>
                            <h3 className="causes-search-location">{item?.Phone || 'N/A'}</h3>
                            {/* <p>{trimDesc(item?.Body)?.split("\n").map((line, idx) => <React.Fragment key={'line-' + idx}>{line}<br /></React.Fragment>)}</p> */}
                        </IonLabel>
                    </IonItem>
                )} 
            </IonList>
            <IonButton 
                hidden={volunteers.length === 0 || state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS || state?.User?.UserType === "V"}
                expand="full" 
                onClick={(e)=>showSendMessage(e, parseInt(params.causeId))} >Send Message to All Attendee(s)</IonButton>
            {/* Not Found */}
            {(volunteers.length === 0 &&
            <IonCard>
                <IonCardContent className="ion-text-center">
                    <IonCardTitle className="ion-margin-bottom" color="muted">
                        No Results
                    </IonCardTitle>
                    <IonCardSubtitle>
                        {filter} item(s) not found
                    </IonCardSubtitle>
                </IonCardContent>
            </IonCard> 
            )}
            <br/>
            <br/>
            <br/>
        </Fragment>
    );
};
export default CauseVolunteerContainer;
