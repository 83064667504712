import React, { useState, useEffect } from 'react';
import { IonList, IonItem, IonLabel, IonChip, IonIcon } from '@ionic/react';
import { checkmarkCircle, addCircle } from 'ionicons/icons';
interface ContainerProps {
    tags: string[],
    selectedTags: string[],
    title: string,
    onChangeHandler: (selectedTags: string[]) => void
}
export const TagSelector: React.FC<ContainerProps> = ({ tags, title, selectedTags, onChangeHandler }) => {
    const [items, setItems] = useState([] as string[]);
    useEffect(()=>{
        setItems(selectedTags);
    },[selectedTags])
    const toggleClicked = (ev: any, selectedItem: string, isChecked: boolean) => {
        ev.preventDefault();
        console.log(ev, selectedItem, isChecked);
        let newItems = [];
        if (isChecked) {
            newItems = items.concat(selectedItem);
        }
        else {
            newItems = items.filter(item => item !== selectedItem);
        }
        setItems(newItems);
        onChangeHandler(newItems);
    };

    return (
        <IonList>
            <IonItem lines="none">
                <IonLabel color="medium">{title}</IonLabel>
            </IonItem>
            {(tags.map(item =>{
                const isIncluded = items.includes(item);
                return <IonChip key={'chip-' + item}
                    onClick={event => toggleClicked(event, item, !isIncluded)}
                    outline={!isIncluded} color={isIncluded ? 'primary' : 'medium'}>
                    <IonIcon icon={isIncluded ? checkmarkCircle : addCircle}></IonIcon>
                    <IonLabel>{item}</IonLabel>
                </IonChip>
                }
            ))}
        </IonList>
    );
};