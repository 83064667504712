import React, { useContext, useEffect, useState, useRef } from 'react';
import './DetailContainer.css';
import { Share, ShareOptions } from '@capacitor/core';
import {
    IonContent, IonRow, IonCol, IonButton, IonFab,
    IonLabel, IonBadge, IonIcon, IonImg, 
    IonGrid, IonAlert, IonLoading, isPlatform, IonList, IonItem, IonAvatar, IonText, IonCard
} from '@ionic/react';
import { shareSocial, arrowBackCircleSharp, navigateCircleOutline, heartOutline, heart, 
        shieldCheckmarkOutline, briefcaseOutline, busOutline, timeOutline, 
        bookmarkOutline, personCircleOutline, pinOutline, calendarOutline } from 'ionicons/icons';
import { UserAPI } from '../services/UserAPI';
import { FileAPI } from '../services/FileAPI';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { useHistory } from 'react-router';
import { AppContext, AppActionTypes, AppAction, AppNetworkStatusTypes } from '../contexts/AppContext';
import { Cause } from '../models/Cause';
import { FavoriteDTO } from '../models/FavoriteDTO';
import { Organization } from '../models/Organization';
import { VolunteerDTO } from '../models/VolunteerDTO';
import { CauseAPI } from '../services/CauseAPI';
import AutoLinkWidget from '../widgets/AutoLinkWidget';
import { Utilities } from '../services/Utilities';
// Note: failed to pass props from .push, not sure why { cause } does not work}
interface ContainerProps {
    cause: Cause
}
interface ContainerProps {
    organization? : Organization
}
const DetailContainer: React.FC<ContainerProps> = () => {
    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();
    const [item,setItem] = useState((history.location.state as ContainerProps)?.cause as Cause);
    const [showAlert, setShowAlert] = useState(false);
    const [showWebsiteAlert, setShowWebsiteAlert] = useState(false);
    const [org, setOrg] = useState({} as Organization);
    const userAPI = new UserAPI(state?.User);
    const causeAPI = new CauseAPI(state?.User);
    const fileAPI = new FileAPI(state?.User);
    const organizationAPI = new OrganizationAPI(state?.User);
    
    const onInit = () => { 
        const process = async() =>{
            if (item){
                const newOrg = await organizationAPI.getOrganization(item.OrgID);
                setOrg(newOrg);
            }
        }
        process();
        return () => {
            userAPI.cancel();
            causeAPI.cancel();
            fileAPI.cancel();
        }
    }
    useEffect(onInit, []);

    const onInitCause = () => { 
        if (!(history.location.state as ContainerProps)?.cause){
            // if item is only one, this must be manipulated at the page level. use that item obviously.
            if (state?.Causes?.length === 1){
                setItem(state?.Causes[0]);
                const process = async ()=>{
                    const newOrg = await organizationAPI.getOrganization(state?.Causes[0].OrgID);
                    setOrg(newOrg);
                }
                process();
            }
        }
    }
    useEffect(onInitCause, [state?.Causes]);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const goBackClicked = async (ev: any) => {
        ev.preventDefault();
        history.goBack();
    };
    const shareClicked = async (ev: any) => {
        if (isPlatform('mobileweb') || isPlatform('desktop')){
            textAreaRef.current?.select();
            document.execCommand('copy');
            ev.target.focus();
            dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"URL copied into your clipboard!"}} as AppAction);
        }
        else {
            const shareObj = {
                title: 'Sharing',
                text: `Sharing ${item?.Title} https://app.globalserviceinstitute.org/Details/${item?.CauseID}`,
                url: `https://app.globalserviceinstitute.org/Details/${item?.CauseID}`,
                dialogTitle: 'Sharing via Global Service Institute'
            } as ShareOptions;
            await Share.share(shareObj);
        }
    };
    const favoriteClicked = async (ev: any, inItem: Cause) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!state?.User?.UserID) {
            history.push('../register/volunteer');
            return;
        }
        const payload: FavoriteDTO = {
            UserID: state?.User?.UserID,
            CauseID: inItem.CauseID
        };
        try {
            if (inItem.IsFavorite) {
                await userAPI.deleteFavorite(payload);
            }
            else {
                await userAPI.addFavorite(payload);
            }
            setItem(prevState=>({
                ...prevState,
                IsFavorite: !prevState.IsFavorite
            }));
        }
        catch (err) {
            console.log(err);
            alert('error (Detail)');
        }
        finally {
            // TODO: spinner off
        }
    };
    const volunteerClicked = async (ev: any, causeId: number) => {
        ev.preventDefault();
        if (!state?.User?.UserID) {
          history.push('../../register/volunteer');
        }
        else {
          dispatch({type:AppActionTypes.START_LOADING} as AppAction);
          try {
            const payload = {
              UserID: state?.User?.UserID,
              CauseID: causeId,
            } as VolunteerDTO;
            if (item?.IsVolunteer) {
              await causeAPI.removeVolunteer(payload);
            }
            else {
              await causeAPI.applyVolunteer(payload);
            }
            dispatch({
              type: AppActionTypes.UPDATE_CAUSE_VOLUNTEER,
              payload: { CauseID: item?.CauseID, IsVolunteer: !item?.IsVolunteer }
            });
            if (item?.IsVolunteer) {          
              dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE,payload:{Message:'You are registered for ' + item?.Title + '. Thank you!'}} as AppAction);
            }
            else {
              dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE,payload:{Message:'Sorry that you cannot make it. Hope to see you again soon.'}} as AppAction);
            }
    
          }
          catch (err) {
            dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:'ERROR - Volunteer', Error: err}} as AppAction);
          }
          finally {
            dispatch({type:AppActionTypes.END_LOADING} as AppAction);
          }
        }
    };
    const organizationClicked = async (ev: any, org: Organization) => {
        history.push(`../../organization/details/${org.OrgID}`);
    };

    return (
        <IonContent slot="fixed" className="ion-no-padding">
            <IonLoading isOpen={state?.NetworkStatus === AppNetworkStatusTypes.PROGRESS} message={'Loading ...'} />
            {( textAreaRef &&  <textarea className="clipboard-text" ref={textAreaRef} value={`https://app.globalserviceinstitute.org/?redirect=/details/${item?.CauseID}`} onChange={()=>undefined}></textarea> )}
            <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Confirm'}
            message={'You are leaving the app to view the map, would you like to continue?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary'
                },
                {
                    text: 'Continue',
                    handler: () => {
                        const url = `https://bing.com/maps/default.aspx?cp=${ item?.Lat }~${ item?.Lng}&lvl=15&sp=point.${ item?.Lat }_${ item?.Lng}_${ item?.Title}`;
                        window.open(url,'_blank');
                    }
                }
            ]}
            />
            <IonAlert
            isOpen={showWebsiteAlert}
            onDidDismiss={() => setShowWebsiteAlert(false)}
            header={'Confirm'}
            message={'You are leaving the app to view ' + org?.Website + ', would you like to continue?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: blah => {
                    }
                },
                {
                    text: 'Continue',
                    handler: () => {
                        const url = org?.Website.toString();
                        window.open(Utilities.getUrl(url),'_blank');
                    }
                }
            ]}
            />
            <IonCard>
            <IonGrid className="ion-no-padding">
                <IonRow>
                    <IonCol>
                        <IonFab horizontal="start" className="card-toolbar">
                            <IonButton className="arrowBackCircleSharp-button"
                                color={"medium"}
                                fill="clear"
                                size="large"
                                onClick={(event) => goBackClicked(event)}>
                                <IonIcon icon={arrowBackCircleSharp} />
                            </IonButton>
                        </IonFab>
                        <IonFab 
                            horizontal="end"
                            className="card-toolbar-right card-toolbar">
                            <IonButton className="fab-button" 
                                        color={"medium"}
                                        fill="clear" 
                                        size="large"
                                        onClick={(event) => shareClicked(event)}>
                                <IonIcon icon={shareSocial}  slot="end" />
                            </IonButton> 
                            <IonButton className="fab-button" 
                                        color={"medium"}
                                        fill="clear" 
                                        size="large"
                                        onClick={(event)=>favoriteClicked(event, item)}>
                                <IonIcon icon={item?.IsFavorite ? heart : heartOutline} />
                            </IonButton> 
                        </IonFab>

                        <IonRow className="detail-container  ion-padding-bottom">
                            <IonCol>
                                <IonList class="ion-no-padding">
                                    <IonItem lines="none">
                                        <IonAvatar className="clickable" slot="start" onClick={(ev:any)=>{organizationClicked(ev,org)}}>
                                            {(
                                                (org && org?.PhotoID && org?.PhotoID!==0) ? 
                                                <IonImg src={fileAPI.buildFileEndpoint(org?.PhotoID+"",36,36)}></IonImg> : 
                                                <IonImg src="assets/organization-photo.jpg"></IonImg>
                                            )} 
                                        </IonAvatar>
                                        <IonLabel className="heading">
                                            <IonText color="dark"><h1><strong>{item?.OrgName}</strong></h1></IonText>
                                            <IonText color="primary"><h3><strong>{item?.Title}</strong></h3></IonText>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {((item && item?.FileID!==-1) ? <IonImg className="ion-padding" src={fileAPI.buildFileEndpoint(item?.FileID+"",375,150)}></IonImg> : <IonRow className="ion-margin"></IonRow>)} 
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top ion-padding-bottom">
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={pinOutline} className="ion-padding-end" /> <span>{item?.Address} {item?.City}, {item?.State} {item?.Zip}</span>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top">
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={calendarOutline} className="ion-padding-end" /> 
                                    <span>
                                    {(item?.EventStart === '0001-01-01T00:00:00' ? 'Date & Time N/A' : Utilities.getDateTimeRange(item?.EventStart!,item?.EventEnd!).pretty)}
                                    </span>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top " hidden={!item?.Body}>
                            <IonCol>
                                <IonLabel>
                                    <strong>ABOUT THE EVENT</strong> 
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start  ion-padding-bottom">
                            <IonCol>
                                <AutoLinkWidget text={item?.Body}></AutoLinkWidget>
                            </IonCol>
                        </IonRow>
                        <IonRow 
                            className={`ion-margin-start ion-margin-end ion-no-padding ion-padding-start  ion-padding-bottom ${item?.NoRegistration ? "ion-padding-top ion-padding-bottom ion-padding-end" : ""} register-bg`}>
                            <IonCol 
                                hidden={item?.NoRegistration}
                                className="ion-align-self-center">
                                <IonButton disabled={state?.NetworkStatus !== AppNetworkStatusTypes.READY}
                                    fill="solid"
                                    size="large"
                                    color={item?.IsVolunteer ? 'danger' : 'warning'}
                                    expand="block" 
                                    className="ion-no-margin"
                                    onClick={(event) => volunteerClicked(event, item?.CauseID)}>{item?.IsVolunteer ? 'UNREGISTER' : 'VOLUNTEER'}</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top" hidden={!item?.Requirements}>
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={shieldCheckmarkOutline} /> <strong>Specifications</strong> 
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start  ion-padding-bottom" hidden={!item?.Requirements}>
                            <IonCol>
                                <IonLabel>
                                    <AutoLinkWidget text={item?.Requirements}></AutoLinkWidget>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top" hidden={!item?.Bring}>
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={briefcaseOutline} /> <strong>What to Bring</strong>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end  ion-padding-bottom" hidden={!item?.Bring}>
                            <IonCol>
                                <IonLabel>
                                    
                                    <AutoLinkWidget text={item?.Bring}></AutoLinkWidget>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top" hidden={!item?.Amenities}>
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={bookmarkOutline} /> <strong>Amenities</strong>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end  ion-padding-bottom" hidden={!item?.Amenities}>
                            <IonCol>
                                <IonLabel>
                                    <AutoLinkWidget text={item?.Amenities}></AutoLinkWidget>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top" hidden={!item?.Transportation}>
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={busOutline} /> <strong>Transportation</strong>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end  ion-padding-bottom" hidden={!item?.Transportation}>
                            <IonCol>
                                <IonLabel>
                                    <AutoLinkWidget text={item?.Transportation}></AutoLinkWidget>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top">
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={navigateCircleOutline} /> <strong>Direction</strong>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end  ion-padding-bottom">
                            <IonCol>
                                <a className="location-decoration" onClick={() => setShowAlert(true)} target="_blank">
                                {( item && item?.Lat && item?.Lng && <IonImg className="ion-padding-bottom" src={'https://atlas.microsoft.com/map/static/png?subscription-key=CD4eqpFPgSNsvKKI2BNQAj-9GOVkM7tIB0x5qWjwsOw&api-version=1.0&zoom=10&center=' + item?.Lng + ',' + item?.Lat + '&height=300&width=600&pins=default||' + item?.Lng + '%20' + item?.Lat}></IonImg>)}
                                {item?.Address} {item?.City}, {item?.State} {item?.Zip}
                                </a>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-top" hidden={!item?.ContactName}>
                            <IonCol>
                                <IonLabel>
                                    <IonIcon icon={personCircleOutline} /> <strong>Contact</strong>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-start ion-padding-end  ion-padding-bottom" hidden={!item?.ContactName}>
                            <IonCol>
                                <IonLabel>
                                    <h3>{(item?.ContactName)}</h3>
                                    <p>{(item?.ContactTitle)}</p>
                                    <p><a href={'mailto:'+(item?.ContactEmail)}>{(item?.ContactEmail)}</a></p>
                                    <p><a href={'tel:'+(item?.ContactPhone)}>{(item?.ContactPhone)}</a></p>
                                    <p><a className="underline clickable" onClick={() => setShowWebsiteAlert(true)}>{Utilities.getUrl(org.Website)}</a></p>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding">
                            <IonCol>
                                {(item?.Tags || item?.Tags)?.map((tag) => <IonBadge className="tag" color="primary" key={'tag-' + tag}>{tag}</IonBadge>)}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
            </IonGrid>
            </IonCard>
        </IonContent>
    );
};

export default DetailContainer;
