import React, { useState } from 'react';
import './StateWidget.css';
import { IonSelect, IonSelectOption } from '@ionic/react';
interface ContainerProps{
    value:string,
    onChange: (ev:any)=>void,
    disabled?: boolean
}
const StateWidget : React.FC<ContainerProps> = (props)=>{
  const [disabled] = useState(props.disabled ? props.disabled : false );
  return (<IonSelect 
    disabled={disabled}
    value={props?.value}
    interface="popover"
    placeholder="Select a State"
    onIonChange={e => props.onChange(e)}>
    <IonSelectOption value="AL">Alabama</IonSelectOption>
    <IonSelectOption value="AK">Alaska</IonSelectOption>
    <IonSelectOption value="AZ">Arizona</IonSelectOption>
    <IonSelectOption value="AR">Arkansas</IonSelectOption>
    <IonSelectOption value="CA">California</IonSelectOption>
    <IonSelectOption value="CO">Colorado</IonSelectOption>
    <IonSelectOption value="CT">Connecticut</IonSelectOption>
    <IonSelectOption value="DE">Delaware</IonSelectOption>
    <IonSelectOption value="DC">District Of Columbia</IonSelectOption>
    <IonSelectOption value="FL">Florida</IonSelectOption>
    <IonSelectOption value="GA">Georgia</IonSelectOption>
    <IonSelectOption value="HI">Hawaii</IonSelectOption>
    <IonSelectOption value="ID">Idaho</IonSelectOption>
    <IonSelectOption value="IL">Illinois</IonSelectOption>
    <IonSelectOption value="IN">Indiana</IonSelectOption>
    <IonSelectOption value="IA">Iowa</IonSelectOption>
    <IonSelectOption value="KS">Kansas</IonSelectOption>
    <IonSelectOption value="KY">Kentucky</IonSelectOption>
    <IonSelectOption value="LA">Louisiana</IonSelectOption>
    <IonSelectOption value="ME">Maine</IonSelectOption>
    <IonSelectOption value="MD">Maryland</IonSelectOption>
    <IonSelectOption value="MA">Massachusetts</IonSelectOption>
    <IonSelectOption value="MI">Michigan</IonSelectOption>
    <IonSelectOption value="MN">Minnesota</IonSelectOption>
    <IonSelectOption value="MS">Mississippi</IonSelectOption>
    <IonSelectOption value="MO">Missouri</IonSelectOption>
    <IonSelectOption value="MT">Montana</IonSelectOption>
    <IonSelectOption value="NE">Nebraska</IonSelectOption>
    <IonSelectOption value="NV">Nevada</IonSelectOption>
    <IonSelectOption value="NH">New Hampshire</IonSelectOption>
    <IonSelectOption value="NJ">New Jersey</IonSelectOption>
    <IonSelectOption value="NM">New Mexico</IonSelectOption>
    <IonSelectOption value="NY">New York</IonSelectOption>
    <IonSelectOption value="NC">North Carolina</IonSelectOption>
    <IonSelectOption value="ND">North Dakota</IonSelectOption>
    <IonSelectOption value="OH">Ohio</IonSelectOption>
    <IonSelectOption value="OK">Oklahoma</IonSelectOption>
    <IonSelectOption value="OR">Oregon</IonSelectOption>
    <IonSelectOption value="PA">Pennsylvania</IonSelectOption>
    <IonSelectOption value="RI">Rhode Island</IonSelectOption>
    <IonSelectOption value="SC">South Carolina</IonSelectOption>
    <IonSelectOption value="SD">South Dakota</IonSelectOption>
    <IonSelectOption value="TN">Tennessee</IonSelectOption>
    <IonSelectOption value="TX">Texas</IonSelectOption>
    <IonSelectOption value="UT">Utah</IonSelectOption>
    <IonSelectOption value="VT">Vermont</IonSelectOption>
    <IonSelectOption value="VA">Virginia</IonSelectOption>
    <IonSelectOption value="WA">Washington</IonSelectOption>
    <IonSelectOption value="WV">West Virginia</IonSelectOption>
    <IonSelectOption value="WI">Wisconsin</IonSelectOption>
    <IonSelectOption value="WY">Wyoming</IonSelectOption>
</IonSelect>)
}
export default StateWidget;