import React, { Fragment } from 'react';
import './MyVolunteerContainer.css';
import CausesList from './CausesList';
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';
import { arrowBackCircleSharp } from 'ionicons/icons';
import { useHistory } from 'react-router';
interface ContainerProps {
} const MyVolunteerContainer: React.FC<ContainerProps> = () => {
    const history = useHistory();
    const goBackClicked = async (ev: any) => {
        ev.preventDefault();
        history.goBack();
    };
    return (
        <Fragment>
            <IonGrid className="ion-no-padding">
                <IonRow className="ion-padding">
                    <IonCol>
                        <IonLabel>
                            <h1>
                                <IonButton className="slim-button"
                                    color="medium"
                                    fill="clear"
                                    size="large"
                                    onClick={(event) => goBackClicked(event)}>
                                    <IonIcon icon={arrowBackCircleSharp} />
                                </IonButton>
                                <strong>Registered</strong>
                            </h1>
                        </IonLabel>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <CausesList filter="volunteer" />
        </Fragment>
    );
};

export default MyVolunteerContainer;
