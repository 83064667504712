import { BaseAPI } from './BaseAPI'
import { AuthDTO } from "../models/AuthDTO";
import { User } from "../models/User";
import { PasswordDTO } from "../models/PasswordDTO";
import { Cause } from "../models/Cause";
import { UserTag } from "../models/UserTag";
import { FavoriteDTO } from "../models/FavoriteDTO";
import { UserNonce } from "../models/UserNonce";

export class UserAPI extends BaseAPI {
    // user object is optional at login screen
    constructor(user: User = ({} as User)) {
        super(user);
    }

    public async registerUserProfile(user: User): Promise<User> {
        return await this.doPost<User, User>('user', user);
    }
    public async loginUser(auth: AuthDTO): Promise<User> {
        return await this.doPost<User, AuthDTO>('auth', auth);
    }
    public async verifyEmail(): Promise<UserNonce> {
        return await this.doGet<UserNonce>('auth/email/verify');
    }
    public async forgotPassword(user: User): Promise<UserNonce> {
        return await this.doPost<UserNonce, User>('auth/reset', user);
    }
    public async forgotPasswordVerifyPin(userNonce: UserNonce): Promise<User> {
        return await this.doPost<User, UserNonce>('auth/reset/verify', userNonce);
    }
    public async updateUserProfile(userProfile: User): Promise<User> {
        return await this.doPut<User, User>('user', userProfile);
    }
    public async updatePassword(password: PasswordDTO): Promise<User> {
        return await this.doPut<User, PasswordDTO>('user/password', password);
    }
    public async getFavorite(): Promise<Cause[]> {
        return await this.doGet<Cause[]>('user/favorite');
    }
    public async addFavorite(favoriteDto: FavoriteDTO): Promise<Cause> {
        return await this.doPost<Cause, FavoriteDTO>('user/favorite', favoriteDto);
    }
    public async getUserTag(): Promise<UserTag[]> {
        return await this.doGet<UserTag[]>('user/tag');
    }
    public async deleteFavorite(favoriteDto: FavoriteDTO): Promise<Cause> {
        return await this.doDelete<Cause,FavoriteDTO>('user/favorite', favoriteDto);
    }
    public async addUserTag(userTag: UserTag): Promise<UserTag> {
        return await this.doPost<UserTag,UserTag>('user/tag', userTag);
    }
    public async deleteUserTag(userTag: UserTag): Promise<UserTag> {
        return await this.doDelete<UserTag,UserTag>('user/tag', userTag);
    }
    public async getUser(): Promise<User> {
        return await this.doGet<User>('user');
    }
    public async getMyVolunteer(): Promise<Cause[]> {
        return await this.doGet<Cause[]>('user/volunteer');
    }
}