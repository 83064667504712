import React, {  useState, useContext, useEffect } from 'react';
import './ContentContainer.css';
import { IonContent, IonImg, IonToolbar, IonButtons, IonBackButton, IonLabel, IonItem, IonText } from '@ionic/react';
import { AppContext } from '../contexts/AppContext';
import { UserAPI } from '../services/UserAPI';
import { ContentItem } from '../models/ContentItem';
import { useHistory } from 'react-router';
import { FileAPI } from '../services/FileAPI';

interface ContainerProps {
}

const ContentContainer: React.FC<ContainerProps> = () => {
    const { state } = useContext(AppContext);
    const userAPI = new UserAPI(state?.User);
    const fileAPI = new FileAPI(state?.User);
    const history = useHistory();
    // allowing any type here
    //Object.keys(state?.Lookup?.Contents).filter((key:String)=>{ return key.indexOf("TAG_ITEM_") > -1; });
    const [items, setItems] = useState<ContentItem[]>([]);
    const onInit = () => {
        if (state?.Lookup?.Contents) {
            let initItems = [];
            for (let i = 1; i < 100; i++) {
                const id = state?.Lookup?.Contents[`TAG_ITEM_${i}_ID`];
                const title = state?.Lookup?.Contents[`TAG_ITEM_${i}_TITLE`];
                if (id || title) {
                    initItems.push({ ID: id, Title: title } as ContentItem);
                }
                else {
                    break;
                }
            }
            setItems(initItems);
        }
        return ()=>{
            fileAPI.cancel();
        }
    }
    useEffect(onInit, [state?.Lookup]);
    const itemClicked = (ev:any, tagName:String) => {
        ev.preventDefault();
        history.push('./search', {filter:'tag',tag:tagName});
        return false;
    }
    return (
        <IonContent slot="fixed">
            <IonToolbar>
                <IonButtons slot="start">
                <IonBackButton defaultHref="../" text="Back" />
                </IonButtons>
            </IonToolbar>
            <div className="ion-padding">
            <IonLabel>{state?.Lookup?.Contents?.TAG_INTRO}</IonLabel>
            </div>
            {items.map((content: ContentItem, index: Number) =>
                <div className="clickable" key={'content-card-' + index} onClick={(ev)=>{return itemClicked(ev, content?.Title)}}>
                    <IonImg src={fileAPI.buildFileEndpoint(content?.ID+"",400,200)}></IonImg>
                    <IonItem lines="none" className="ion-text-uppercase link" detail>
                        <strong>{content?.Title}</strong>
                        <IonText slot="end"><small>Events</small></IonText>
                    </IonItem>
                </div>
            )}
        </IonContent>
    );
};

export default ContentContainer;
