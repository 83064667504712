import React, { Fragment, useState, useContext, useEffect } from 'react';
import './RegisterContainer.css';
import {  IonSelect, IonDatetime, IonSelectOption, IonContent, IonList, IonItem,
          IonLabel, IonGrid, IonRow, IonCol, IonButton, IonInput, IonText,
          IonBadge, IonTitle, IonImg } from '@ionic/react';
import { useParams, useHistory } from 'react-router';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { TagSelector } from './TagSelector';
import { UserAPI } from '../services/UserAPI';
import { OrganizationAPI } from '../services/OrganizationAPI';
import { User } from '../models/User';
import { PasswordDTO } from '../models/PasswordDTO';
import { Organization } from '../models/Organization';
import StateWidget from '../widgets/StateWidget';
import { FileAPI } from '../services/FileAPI';

interface ContainerProps {
  role: string;
}
// note: https://developer.liu.edu:8443/display/WD/Volunteer+App
const RegisterContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { role } = useParams<{ role: string; }>();
  const { state, dispatch } = useContext(AppContext);
  const [profile, setProfile] = useState<User>({
    Organizations: [{
      State:"NY"
    }] as Organization[],
    Tags: [] as string[]
  } as User);
  const [messages, setMessages] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);
  let userApi = new UserAPI(state.User);
 
  const onInit = () => { 
    return ()=>{
      userApi.cancel();
    }
  }
  useEffect(onInit, []);

  const handleProfileChange = (name: string, value: string) => {
    let usr: any = {
      ...profile,
      [name]: value
    };
    setProfile(usr as User);
  };
  const handleOrganizationChange = (name: string, value: string) => {
    let usr: any = {
      ...profile,
      Organizations:profile.Organizations!,
      Tags:profile.Tags!
    }
    if (profile.Organizations!.length>0) {
      usr.Organizations[0][name] = value;
    }
    else {
      let org: any = {
        [name]: value
      };
      usr.Organizations = [org];
    }
    setProfile(usr as User);
  };
  const registerProfileClicked = async (ev: any) => {
    ev.preventDefault();
    setIsLoading(true);
    const usr: User = {
      ...profile,
      UserType: (role?.toLocaleLowerCase() === "volunteer" ? "V" : "O"),
      Organizations: profile.Organizations,
      Tags: profile.Tags
    }
    const pass: PasswordDTO = {
      Password: usr.Password!
    }
    try {
      // validate profile
      let errors: string[] = [];
      if (!profile?.FirstName) {
        errors.push("First Name");
      }
      if (!profile?.LastName) {
        errors.push("Last Name");
      }


      if (!profile?.Email) {
        errors.push("Email");
      }
      else{
        if(!(profile?.Email.indexOf("@")>0)){
          errors.push("Email (invalid email)");
        }
      }
      if (!profile?.Phone) {
        errors.push("Phone");
      }
      if (role?.toLocaleLowerCase() === "volunteer"){
        if (!profile?.DOB) {
          errors.push("DOB");
        }
        else{
          const Today = new Date();
          const COPAMinimum = new Date(Today.getFullYear()-13,Today.getMonth(),Today.getDate()).toISOString();
          if(profile?.DOB.slice(0,10) > COPAMinimum.slice(0,10)){
            errors.push("Age Over 13 (COPPA Compliance)");
          }
        }
        if (!profile?.Gender) {
          errors.push("Gender");
        }
      }
      if (!profile?.Password) {
        errors.push("Password");
      }
      if (role?.toLocaleLowerCase() === 'organization') {
        if (profile.Organizations!.length===0){
          errors.push("All required organization details");
        }
        else{
          if(profile?.Organizations!.length===1){
            if(!profile?.Organizations![0].Name){
              errors.push("Organization Name");
            }
            if(!profile?.Organizations![0].Address){
              errors.push("Organization Address");
            }
            if(!profile?.Organizations![0].City){
              errors.push("Organization City");
            }
            if(!profile?.Organizations![0].State){
              errors.push("Organization State");
            }
            if(!profile?.Organizations![0].Zip){
              errors.push("Organization Zip");
            }
            if (!profile?.Organizations![0].Website) {
              errors.push("Organization Website");
            }
          }
        }
      }

      // validate tags
      if (usr.UserType === 'V' && (!profile?.Tags || profile?.Tags?.length === 0)) {
        errors.push("Please select at least one area of interests");
      }

      // validate password
      if (profile?.Password?.length! < 6) {
        errors.push("Password needs to be at least 6 characters.");
      }

      if (errors.length > 0) {
        setMessages(errors);
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE, payload:{Message:"Please review your submission"}} as AppAction);
        return;
      }
      const userResponse: User = await userApi.registerUserProfile(usr);
      userApi.setToken(userResponse.Token);
      await userApi.verifyEmail();
      const orgApi = new OrganizationAPI(userResponse);
      usr!.Organizations! = usr.UserType==='O' ? await Promise.all(usr!.Organizations!.map(async (organization:Organization) => {
        //register organizations if available
        organization.Email = profile.Email;
        organization.Phone = profile.Phone;
        organization.Website = profile?.Organizations![0].Website;
        organization = await orgApi.addOrgProfile(organization);
        await orgApi.verifyEmail(organization.OrgID);
        return organization;
      })) : [];
      const registeredUser : User = {
        ...profile,
        ...userResponse,
        UserID: userResponse.UserID,
        Token: userResponse.Token,
        UserType: userResponse.UserType,
        Tags: usr.Tags,
        Organizations: usr.Organizations
      };
      setProfile(registeredUser);
      dispatch({ type: AppActionTypes.UPDATE_PROFILE, payload: { User: registeredUser } } as AppAction);

      await userApi.updatePassword(pass);
      if (role?.toLocaleLowerCase() === "volunteer"){
        history.push('../Search');
      }
      else {
        /* Organization Profile Image Add */
        history.push('../Profile');
      }
    }
    catch (err) {
      console.log(err);
      dispatch({type:AppActionTypes.SHOW_WARNING_MESSAGE, payload:{Message:"Invalid registration information"}} as AppAction);
      setMessages([err.message]);
    }
    finally {
      setIsLoading(false);
    }
  };
  const selectedTagsChanged = (selectedTags: string[]) => {
    let usr: any = {
      ...profile,
      Tags: selectedTags
    }
    setProfile(usr as User);
  };
  const loginClicked = async (ev: any) => {
    ev.preventDefault();
    history.push('../login')
  };
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonImg src="assets/logo.png" className="logo"></IonImg>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center ion-margin-bottom">
          <IonCol>
            <IonTitle 
              color="medium" 
              className="ion-text-capitalize ion-padding-top">{role} Registration</IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              {{ role }.role.toLowerCase() === "organization" && (
                <Fragment>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">Organization Name <IonText color="danger">*</IonText></IonLabel>
                    <IonInput placeholder="e.g. My Organization" value={profile?.Organizations![0]?.Name} required type="text" onIonChange={e => handleOrganizationChange('Name', e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">Address <IonText color="danger">*</IonText></IonLabel>
                    <IonInput placeholder="e.g. 1 Main Street" value={profile?.Organizations![0]?.Address} required type="text" onIonChange={e => handleOrganizationChange('Address', e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">Apt, Suite, Unit </IonLabel>
                    <IonInput placeholder="e.g. Suite 101" value={profile?.Organizations![0]?.Address2} required type="text" onIonChange={e => handleOrganizationChange('Address2', e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">City <IonText color="danger">*</IonText></IonLabel>
                    <IonInput placeholder="e.g. New York" value={profile?.Organizations![0]?.City} required type="text" onIonChange={e => handleOrganizationChange('City', e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">State <IonText color="danger">*</IonText></IonLabel>
                    <StateWidget value={profile?.Organizations![0]?.State}
                      onChange={e => handleOrganizationChange('State', e.detail.value!)}>
                    </StateWidget>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="medium">Zip <IonText color="danger">*</IonText></IonLabel>
                    <IonInput placeholder="e.g. 12345" value={profile?.Organizations![0]?.Zip} required type="tel" maxlength={5} onIonChange={e => handleOrganizationChange('Zip', e.detail.value!)}></IonInput>
                  </IonItem>
                </Fragment>
              )}
              <IonItem>
                <IonLabel position="stacked" color="medium">First Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. John" value={profile?.FirstName} required type="text" autocomplete="off" onIonChange={e => handleProfileChange('FirstName', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Last Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. Smith" value={profile?.LastName} required type="text" autocomplete="off" onIonChange={e => handleProfileChange('LastName', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem hidden={role?.toLocaleLowerCase()  === "organization"}>
                <IonLabel position="stacked" color="medium">Gender <IonText color="danger">*</IonText></IonLabel>
                <IonSelect value={profile?.Gender}
                  interface="popover"
                  placeholder="Select One"
                  onIonChange={e => handleProfileChange('Gender', e.detail.value!)}>
                  <IonSelectOption value="F">Female</IonSelectOption>
                  <IonSelectOption value="M">Male</IonSelectOption>
                  <IonSelectOption value="O">Other</IonSelectOption>
                  <IonSelectOption value="U">Unspecified</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem hidden={role?.toLocaleLowerCase() === "organization"}>
                <IonLabel position="stacked" color="medium">DOB <IonText color="danger">*</IonText></IonLabel>
                <IonDatetime value={profile?.DOB} displayFormat="MMMM DD YYYY" placeholder="Select Date" onIonChange={e => handleProfileChange('DOB', e.detail.value!)}></IonDatetime>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Phone <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. 555-555-5555" value={profile?.Phone} required type="tel" onIonChange={e => handleProfileChange('Phone', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem hidden={role?.toLocaleLowerCase() !== "organization"}>
                <IonLabel position="stacked" color="medium">Website <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. https://www.globalserviceinstitute.org" value={profile?.Organizations![0]?.Website} required type="text" onIonChange={e => handleOrganizationChange('Website', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Email <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="e.g. john.smith@globalserviceinstitute.org" value={profile?.Email} required type="email" onIonChange={e => handleProfileChange('Email', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonLabel position="stacked" color="medium">Password <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="Must have at least 6 characters" value={profile?.Password} required type="password" onIonChange={e => handleProfileChange('Password', e.detail.value!)}></IonInput>
              </IonItem>
              {role.toLowerCase() === "volunteer" && (
                <Fragment>
                  <br />
                  <TagSelector title="Tell us your area(s) of interest"
                    tags={state?.Lookup?.Tags || []}
                    selectedTags={profile?.Tags || []}
                    onChangeHandler={selectedTagsChanged}
                  />
                </Fragment>
              )}
              <IonRow>
                <IonCol>
                  {(messages.length>0? <IonLabel color="medium" className="d-block">Please Enter:</IonLabel> : '')}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
                </IonCol>
              </IonRow>
              <IonButton disabled={isLoading} expand="full" className="ion-margin-top" color="primary" onClick={registerProfileClicked}>Sign Up</IonButton>
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center ion-margin-top ion-padding-top">
          <IonCol>
            <IonLabel color="medium">... or You already have an account?</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center ion-margin-top ion-margin-bottom">
          <IonCol>
            <IonLabel className="text-link clickable" color="primary" onClick={(event) => loginClicked(event)}>Click here to Login</IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default RegisterContainer;
