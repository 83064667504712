import React, { useEffect, useState, useContext } from 'react';
import './CauseProfileContainer.css';
import { useParams, useHistory } from 'react-router';
import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, 
          IonButton, IonInput,  IonText, IonSelect, IonSelectOption,
          IonBadge, IonIcon, IonTextarea, IonDatetime, IonToggle, IonCheckbox, IonModal } from '@ionic/react';
import { AppContext, AppActionTypes, AppAction } from '../contexts/AppContext';
import { Cause } from '../models/Cause';
import { File } from '../models/File';
import { Organization } from '../models/Organization';
import { CauseAPI } from '../services/CauseAPI';
import { FileAPI } from '../services/FileAPI';
import { arrowBackCircleSharp } from 'ionicons/icons';
import MediaWidget from '../widgets/MediaWidget';
import { OrganizationAPI } from '../services/OrganizationAPI';
import StateWidget from '../widgets/StateWidget';
import { TagSelector } from './TagSelector';


interface ContainerProps{}
const CauseProfileContainer : React.FC<ContainerProps> = ()=>{
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const params = useParams<{ orgId: string; causeId: string; }>();
  const [messages, setMessages] = useState<string[]>([]);
  /* const [isLoading, setIsLoading] = useState<boolean>(false); */
  const [profile,setProfile] = useState<Cause>({
    StartOn:new Date().toISOString().slice(0,19),
    EndOn:'0001-01-01T00:00:00',
    EventStart:'0001-01-01T00:00:00',
    EventEnd:'0001-01-01T00:00:00',
    NoRegistration:false,
    Tags:[] as string[]
  } as Cause);
  const [optionalFeatures,setOptionalFeatures] = useState<boolean>(true);
  const [showModal,setShowModal] = useState<boolean>(false);
  const [isLoaded,setIsLoaded] = useState(false);
  const [media,setMedia] = useState<File[]>([] as File[]);
  const [selectedMedia,setSelectedMedia] = useState<File>({} as File);
  const [availableOrganizations, setAvailableOrganizations] = useState<Organization[]>([])
  const causeApi = new CauseAPI(state.User);
  const fileApi = new FileAPI(state.User);
  const orgApi = new OrganizationAPI(state.User);
  
  const onInit = () => { 
    const process = async ()=>{
      setIsLoaded(false);
      let selectedFileID:number;
      if(params.causeId){
        const cause = (await causeApi.searchCauses({CauseID:parseInt(params.causeId), isPublic:false}))[0];
        selectedFileID = cause.FileID;
        cause.EventStart = cause.EventStart === '0001-01-01T00:00:00' ? cause.EventStart : cause.EventStart+"Z";
        cause.EventEnd = cause.EventEnd === '0001-01-01T00:00:00' ? cause.EventEnd : cause.EventEnd+"Z";
        setProfile(cause);
        const selectedOrganizations = state.User.UserType==='A' ? await orgApi.getOrganizations() :  state.User.Organizations.map((organization:Organization)=>{
          return {
            OrgID:organization.OrgID,
            Name:organization.Name,
            Checked:organization.OrgID===cause.OrgID
          }
        });
        setAvailableOrganizations(selectedOrganizations);
      }
      else{
        const selectedOrganizations = state.User.Organizations.map((organization:Organization)=>{
          return {
            OrgID:organization.OrgID,
            Name:organization.Name,
            Checked:false
          }
        });
        setAvailableOrganizations(selectedOrganizations);
        setProfile({
          ...profile,
          OrgID: selectedOrganizations[0]?.OrgID,
          ContactName:state.User.FirstName+' ' + state.User.LastName,
          ContactEmail:state.User.Email,
          ContactPhone:state.User.Phone
        });
      }
      const causeFiles = params.causeId ? (await fileApi.getCauseFiles({CauseID:params.causeId} as any)).map((media)=>{
        return {
          name: media.Name,
          src:  media.FileID,
          fn: fileApi.buildFileEndpoint.bind(fileApi),
          selected: selectedFileID === media.FileID ? true : false 
        } as any
      }) : [];
      const userFiles = (await fileApi.getFiles()).map((media)=>{ 
        return {
          name: media.Name,
          src:  media.FileID,
          fn: fileApi.buildFileEndpoint.bind(fileApi),
          selected: selectedFileID === media.FileID ? true : false 
        } as any
      })
      const files = userFiles.filter((media)=>{
        return causeFiles.findIndex((causeMedia)=>{
          return causeMedia.src === media.src
        }) === -1;
      }).concat(causeFiles);
      setMedia(files);
      const newSelectedFile = files?.find((file)=>{return file?.src===selectedFileID});
      if(newSelectedFile){
        setSelectedMedia({
          FileID:newSelectedFile.src,
          Name:newSelectedFile.name
        });
      }
      setTimeout(()=>{
        setIsLoaded(true);
      },500);
    }
    try {
      //setIsLoading(true);
      process();
    }
    catch(err){
      dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE,payload:{Message:"Network Error",Error:err}} as AppAction);
    }
    finally{
      //setIsLoading(false);
    }
    return ()=>{
      causeApi.cancel();
      fileApi.cancel();
      orgApi.cancel();
    }
  }
  useEffect(onInit, []);
  
  const leaveSaving = async (ev:any)=>{
    saveProfileClicked(ev);
    setShowModal(false);
    history.push(`../../../details/${profile.CauseID}`, { cause: profile });
  };
  const leaveWithoutSaving = async (ev:any)=>{
    setShowModal(false)
    history.push(`../../../details/${profile.CauseID}`, { cause: profile });
  };
  const yearsForward = (years:number)=>{
    let setDate = new Date();
    setDate.setFullYear(setDate.getFullYear()+years);
    return setDate.toISOString().slice(0,10);
  };
  const goBackClicked = async (ev: any) => {
    ev.preventDefault();
    history.goBack();
  };
  const selectedTagsChanged = (selectedTags: string[]) => {
    //console.log('selected items', selectedTags);
    setProfile({ ...profile, Tags: selectedTags });
  };
  const handleProfileChange = (name:string,value:any)=>{
    //console.log(name,value)
    let cause: any = {
      [name]: value
    };
    setProfile((prevCause)=>({ 
      ...prevCause,
      ...cause as Cause
    }));
  };
  const saveProfileClicked = async (ev:any)=>{
    ev.preventDefault();
    //setIsLoading(true);
    //console.log(profile);
    try{
      const errors = []
      if(!profile?.Title){
        errors.push("Title")
      }
      if(!profile?.Body){
        errors.push("Body")
      }
      if(!profile?.Address){
        errors.push("Address")
      }
      if(!profile?.City){
        errors.push("City")
      }
      if(!profile?.State){
        errors.push("State")
      }
      if(!profile?.Zip){
        errors.push("Zip")
      }
      if(profile?.StartOn && profile?.EndOn && 
        profile?.StartOn !== "0001-01-01T00:00:00" && profile?.EndOn !== "0001-01-01T00:00:00" && 
        profile?.StartOn > profile?.EndOn){
        errors.push("Invalid Advertising Start/End Date")
      }
      if(profile?.EventStart && profile?.EventEnd && 
        profile?.EventStart !== "0001-01-01T00:00:00" && profile?.EventEnd !== "0001-01-01T00:00:00" && 
        profile?.EventStart > profile?.EventEnd){
        errors.push("Invalid Event Start/End Date")
      }
      if(profile?.Tags?.length > 3 ){
        errors.push("Maximum of 3 tags available")
      }
      if(!selectedMedia.FileID){
        errors.push("Cause must have an image")
      }
      setMessages(errors);
      if (errors.length > 0) {
        dispatch({type:AppActionTypes.HANDLE_ERROR_MESSAGE, payload:{Message:"Please review your submission"}} as AppAction);
        return;
      }
      
      profile.StartOn=profile.StartOn===""?"0001-01-01T00:00:00":profile.StartOn;
      profile.EndOn=profile.EndOn===""?"0001-01-01T00:00:00":profile.EndOn;
      if(params.causeId){
        const result = await causeApi.updateCause(profile);
        result.EventStart = result.EventStart === '0001-01-01T00:00:00' ? result.EventStart : result.EventStart+"Z";
        result.EventEnd = result.EventEnd === '0001-01-01T00:00:00' ? result.EventEnd : result.EventEnd+"Z";
        if(selectedMedia.FileID){
          await causeApi.replaceCauseFile({FileID:selectedMedia.FileID!,CauseID:profile.CauseID});
        }
        setProfile({...result} as Cause);
        dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Event updated"}} as AppAction);
      }
      else{
        const result = await causeApi.addCause(profile);
        if(selectedMedia.FileID){
          await causeApi.replaceCauseFile({FileID:selectedMedia.FileID,CauseID:result.CauseID});
          setSelectedMedia({} as File);
        }
        setProfile({} as Cause);
        dispatch({type:AppActionTypes.SHOW_REGULAR_MESSAGE, payload:{Message:"Event added"}} as AppAction);
      }
    }
    catch(error){
      console.log(error);
      alert(error);
    }
    finally{
      //setIsLoading(false);
    }
  };
  const toggleEventSchedule = (checked:boolean)=>{
    if(checked){
      setProfile((prevCause)=>({ 
        ...prevCause,
        ...{
          EventStart:"0001-01-01T00:00:00",
          EventEnd:"0001-01-01T00:00:00"
        } as Cause
      }));
    }
    else if(isLoaded && (profile.EventStart === "0001-01-01T00:00:00" || profile.EventEnd === "0001-01-01T00:00:00")){
      let today = new Date();
      today.setMinutes(0);
      today.setSeconds(0);
      let start=today.toISOString();
      today.setMinutes(30);
      let end=today.toISOString();
      setProfile((prevCause)=>({ 
        ...prevCause,
        ...{
          EventStart:start,
          EventEnd:end
        } as Cause
      }));
    }
  };


  const viewVolunteers = async  (ev: any, causeId: number) => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(`../../../cause/${causeId}/volunteers`);
  };

  return (
    <IonContent slot="fixed">
      <IonModal isOpen={showModal}>
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <IonCol className="ion-margin-horizontal">
              <h1 className="ion-text-center"><IonText color="dark">One moment...</IonText></h1>
              <IonText color="medium" >You have unsaved changes. Would you like to save before leaving?</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="success" size="large" onClick={(e) => leaveSaving(e)}>Yes</IonButton>
            </IonCol> 
            <IonCol>
              <IonButton expand="block" color="danger" size="large" onClick={(e) => leaveWithoutSaving(e)}>No</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="medium" size="large" onClick={(e) => setShowModal(false)}>Cancel</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <IonGrid>
        <IonRow className="ion-padding">
          <IonCol>     
            <IonLabel>
              <h1>
                <IonButton className="slim-button"
                    color="medium"
                    fill="clear"
                    size="large"
                    onClick={(event) => goBackClicked(event)}>
                    <IonIcon icon={arrowBackCircleSharp} />
                </IonButton>
                <strong>{ !params.causeId ? 'Add Event' : 'Edit Event'}</strong>
                
              </h1>
            </IonLabel>
          </IonCol>
          <IonCol size="4">
                <IonButton
                    fill="clear"
                    hidden={!params.causeId || state?.User?.UserType !=='V' && !(state?.User?.Organizations?.find((org:Organization)=>{return profile?.OrgID === org.OrgID})) }
                    size="small"
                    onClick={(event) => viewVolunteers(event, profile?.CauseID)}>View Attendee(s)
                </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem hidden={state?.User?.UserType === 'A'}>
                <IonLabel>{availableOrganizations.find((org:Organization)=>{
                  return org.OrgID === parseInt(params.orgId);
                })?.Name}</IonLabel>
              </IonItem>
              <IonItem hidden={state?.User?.UserType !== 'A'}>
                <IonLabel position="stacked" color="medium">Organization <IonText color="danger">*</IonText></IonLabel>
                <IonSelect value={profile.OrgID}
                  interface="popover"
                  placeholder="Select an Organization"
                  onIonChange={e => handleProfileChange('OrgID', e.detail.value!)}
                  disabled={params.causeId?true:false}>
                  {(availableOrganizations.map((organization:any)=>{ 
                    return (<IonSelectOption key={organization.OrgID} value={organization.OrgID} defaultChecked={organization.Checked}>{organization.Name}</IonSelectOption>) 
                  }))}
                </IonSelect>
              </IonItem>
              <IonItem hidden={true} lines="none">
                <IonToggle checked={optionalFeatures} onClick={(e)=>setOptionalFeatures(!optionalFeatures)}/><IonLabel color="medium" className="ion-margin-start">Optional Features</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Event Name <IonText color="danger">*</IonText></IonLabel>
                <IonInput placeholder="Name of your event" required type="text" maxlength={400} value={profile?.Title} onIonChange={e => handleProfileChange('Title', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Description <IonText color="danger">*</IonText></IonLabel>
                <IonTextarea placeholder="Let your volunteers know a little bit about your event. Max. 2000 characters." required maxlength={2000} autoGrow={true} spellcheck={true} value={profile?.Body} onIonChange={e => handleProfileChange('Body', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Requirements </IonLabel>
                <IonTextarea placeholder="List any items the volunteers must have before signing up." maxlength={400} autoGrow={true} spellcheck={true} value={profile?.Requirements} onIonChange={e => handleProfileChange('Requirements', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">What to Bring </IonLabel>
                <IonTextarea placeholder="List any items the volunteers should bring with them to the event." maxlength={400} autoGrow={true} spellcheck={true} value={profile?.Bring} onIonChange={e => handleProfileChange('Bring', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Amenities </IonLabel>
                <IonTextarea placeholder="Are there any amenities you would like the volunteers to know about?" maxlength={400} autoGrow={true} spellcheck={true} value={profile?.Amenities} onIonChange={e => handleProfileChange('Amenities', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Transportation </IonLabel>
                <IonTextarea placeholder="e.g. Times Square Station (1, 2, 3, 7, A, E, N, and Q train)" maxlength={400} autoGrow={true} spellcheck={true} value={profile?.Transportation} onIonChange={e => handleProfileChange('Transportation', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem hidden={true}>
                <IonLabel position="stacked" color="medium">Date & Time </IonLabel>
                <IonTextarea placeholder="e.g. July 1, 2020 (9:00am-5:00pm)"  maxlength={400} autoGrow={true} spellcheck={true} value={profile?.TimeDescription} onIonChange={e => handleProfileChange('TimeDescription', e.detail.value!)}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Address <IonText color="danger">*</IonText></IonLabel>
                <IonInput required placeholder="e.g. 1 Main Street"  maxlength={400} type="text" value={profile?.Address} onIonChange={e => handleProfileChange('Address', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">City <IonText color="danger">*</IonText></IonLabel>
                <IonInput required placeholder="e.g. New York" type="text"  maxlength={100} value={profile?.City} onIonChange={e => handleProfileChange('City', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">State <IonText color="danger">*</IonText></IonLabel>
                <StateWidget value={profile?.State}
                  onChange={e => handleProfileChange('State', e.detail.value!)}>
                </StateWidget>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonLabel position="stacked" color="medium">Zip <IonText color="danger">*</IonText></IonLabel>
                <IonInput required placeholder="e.g. 12345" type="tel"  maxlength={5} value={profile?.Zip} onIonChange={e => handleProfileChange('Zip', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Contact Name </IonLabel>
                <IonInput placeholder="e.g. John Smith"  maxlength={100} value={profile?.ContactName} onIonChange={e => handleProfileChange('ContactName', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Contact Title </IonLabel>
                <IonInput placeholder="e.g. Event Coordinator"  maxlength={100} value={profile?.ContactTitle} onIonChange={e => handleProfileChange('ContactTitle', e.detail.value!)}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="medium">Contact Email </IonLabel>
                <IonInput placeholder="e.g. john.smith@globalserviceinstitute.org" type="email" maxlength={100} value={profile?.ContactEmail} onIonChange={e => handleProfileChange('ContactEmail', e.detail.value!)}></IonInput>
              </IonItem> 
              <IonItem className="ion-margin-bottom">
                <IonLabel position="stacked" color="medium">Contact Phone </IonLabel>
                <IonInput placeholder="e.g. 555-555-5555" type="tel" maxlength={20} value={profile?.ContactPhone} onIonChange={e => handleProfileChange('ContactPhone', e.detail.value!)}></IonInput>
              </IonItem> 
              <IonItem className="ion-margin-bottom">
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium">Advertising Start </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-margin">
                      <IonDatetime
                        className="ion-no-padding ion-padding-top ion-padding-bottom" 
                        value={profile?.StartOn === "0001-01-01T00:00:00" ? "" : profile?.StartOn} 
                        displayFormat="MMMM DD YYYY" min={new Date().getFullYear().toString()} max={yearsForward(5)}
                        placeholder="Start cause visibility (optional)"
                        onIonChange={e => (e.detail.value! === "" ? "" : handleProfileChange('StartOn', e.detail.value!))}></IonDatetime>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium">Advertising End </IonLabel>
                    </IonCol>
                    {/*}
                    <IonCol size="auto" className="ion-no-margin">
                      <IonText color="medium" className="ion-margin-start"><small>never expire</small></IonText> 
                    </IonCol>
                    <IonCol size="auto" className="ion-no-margin">
                     <IonCheckbox 
                        className={"ion-no-margin ion-margin-start" + (profile?.EndOn === "0001-01-01T00:00:00" ? " ion-margin-bottom" : "")}
                        checked={( profile?.EndOn === "0001-01-01T00:00:00" ? true:false  )} 
                      onIonChange={e => e.detail.checked! ? handleProfileChange("EndOn","0001-01-01T00:00:00") : handleProfileChange("EndOn",new Date().toISOString().slice(0,19)) }/> 
                </IonCol>*/}
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-margin">
                      { /*profile?.EndOn !== "0001-01-01T00:00:00" &&*/
                      <IonDatetime
                        className="ion-no-padding ion-padding-top ion-padding-bottom" 
                        value={profile?.EndOn === "0001-01-01T00:00:00" ? yearsForward(1)+"T00:00:00" : profile?.EndOn} 
                        displayFormat="MMMM DD YYYY" min={new Date().getFullYear().toString()}  max={yearsForward(5)}
                        placeholder="End cause visibility (optional)" 
                        onIonChange={e => (e.detail.value! === "" ? "" : handleProfileChange('EndOn', e.detail.value!))}></IonDatetime>
                      }
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium"></IonLabel>
                    </IonCol>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonText color="medium" className=""><small>No Event Time</small></IonText> 
                    </IonCol>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonCheckbox 
                        className={"ion-no-margin ion-margin-start ion-margin-bottom"}
                        checked={( profile?.EventStart === "0001-01-01T00:00:00" ? true:false  )} 
                        onIonChange={e => toggleEventSchedule(e.detail.checked!) }/>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem className="ion-margin-bottom" hidden={profile?.EventStart === "0001-01-01T00:00:00"?true:false}>
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium">Event Start</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-margin">
                      { profile?.EventStart !== "0001-01-01T00:00:00" &&
                      <IonDatetime
                        className="ion-no-padding ion-padding-top ion-padding-bottom" 
                        value={profile?.EventStart === "0001-01-01T00:00:00" ? "" : profile?.EventStart} 
                        displayFormat="MMMM DD YYYY hh:mm A" min={new Date().getFullYear().toString()}  max={yearsForward(5)}
                        minuteValues="0,15,30,45" placeholder="End cause visibility (optional)" 
                        onIonChange={e => (e.detail.value! === "" ? "" : handleProfileChange('EventStart', (new Date(e.detail.value!)).toISOString()))}></IonDatetime>
                      }
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem className="ion-margin-bottom" hidden={profile?.EventStart === "0001-01-01T00:00:00"?true:false}>
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow >
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium">Event End </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-margin">
                      { profile?.EventEnd !== "0001-01-01T00:00:00" &&
                      <IonDatetime
                        className="ion-no-padding ion-padding-top ion-padding-bottom" 
                        value={profile?.EventEnd === "0001-01-01T00:00:00" ? "" : profile?.EventEnd} 
                        displayFormat="MMMM DD YYYY hh:mm A" min={new Date().getFullYear().toString()}  max={yearsForward(5)}
                        minuteValues="0,15,30,45" placeholder="End cause visibility (optional)"
                        onIonChange={e => (e.detail.value! === "" ? "" : handleProfileChange('EventEnd', (new Date(e.detail.value!)).toISOString()))}></IonDatetime>
                      }
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem className="ion-margin-bottom">
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonLabel color="medium"></IonLabel>
                    </IonCol>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonText color="medium" className=""><small>Allow Registration</small></IonText> 
                    </IonCol>
                    <IonCol size="auto" className="ion-no-margin">
                      <IonCheckbox 
                        className={"ion-no-margin ion-margin-start ion-margin-bottom"}
                        checked={( profile?.NoRegistration ? false:true  )} 
                        onIonChange={e => handleProfileChange("NoRegistration",!e.detail.checked) }/>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>

              <IonLabel className="ion-margin-start" position="stacked" color="medium">Thumbnail <IonText color="danger">*</IonText></IonLabel>
              <IonItem lines="none">
                <MediaWidget 
                  value={(media as any)}
                  post={(async (media)=>{ return await fileApi.storeFile(media)})}
                  put={(async (media)=>{ 
                    setSelectedMedia({
                      FileID:media.src,
                      Name:media.name,
                      Extension:media.extension
                    });
                    return media;
                  })}
                  restriction={({
                    extensions:["png","gif","jpg","jpeg"],
                    size:5242880
                  })}>
                </MediaWidget>
              </IonItem>
              <TagSelector
                title="Area(s) of interest (max 3)"
                tags={state?.Lookup?.Tags || []}
                selectedTags={profile.Tags || []}
                onChangeHandler={selectedTagsChanged}
              />
              <IonRow>
                <IonCol>
                  {(messages.length>0? <IonLabel color="medium" className="d-block">Please Enter:</IonLabel> : '')}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {(messages.map((msg, index) => <IonBadge key={index} color="danger" className="requirement">{msg}</IonBadge>))}
                </IonCol>
              </IonRow>
              <IonButton className="ion-margin-top" color="primary" expand="full" onClick={saveProfileClicked}>
                { !params.causeId ? 'Add Event' : 'Update Event'}
              </IonButton>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
}
export default CauseProfileContainer;