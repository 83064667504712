import { BaseAPI } from './BaseAPI'
import { User } from "../models/User";
import { ResultDTO } from '../models/ResultDTO';
import { MessageDTO } from '../models/MessageDTO';

export class MessageAPI extends BaseAPI {
    // user object is optional at login screen
    constructor(user: User = ({} as User)) {
        super(user);
    }

    public async broadcastMessage(causeId:number, message:MessageDTO): Promise<ResultDTO> {
        return await this.doPost<ResultDTO, MessageDTO>(`/cause/${causeId}/broadcast/`, message);
    }
}