import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import './OrganizationCausesContainer.css';
import { IonContent, IonRow, IonCol, IonLabel, IonGrid, IonButton, IonIcon } from '@ionic/react';
import CausesList from './CausesList';
import { arrowBackCircleSharp } from 'ionicons/icons';
interface ContainerProps {
}
const OrganizationCauseContainer: React.FC<ContainerProps> = () => {
    const history = useHistory();
    useEffect(()=>{
    },[]);
    const goBackClicked = async (ev: any) => {
        ev.preventDefault();
        history.goBack();
    };
    return (
    <IonContent>
        <IonGrid className="ion-no-padding">
            <IonRow className="ion-padding">
                <IonCol>    
                    <IonLabel>
                        <h1>
                            <IonButton className="slim-button"
                                color="medium"
                                fill="clear"
                                size="large"
                                onClick={(event) => goBackClicked(event)}>
                                <IonIcon icon={arrowBackCircleSharp} />
                            </IonButton>
                            <strong>Organization Events</strong>
                        </h1>
                    </IonLabel> 
                </IonCol>
            </IonRow>
        </IonGrid>
        <CausesList filter="manage"/>
    </IonContent>
    );
};

export default OrganizationCauseContainer;
