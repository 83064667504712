import {
    IonIcon,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
} from '@ionic/react';

import React, { useContext, Fragment } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import {
    personCircleOutline, logInOutline,
    heartOutline,
    searchOutline,
    checkmarkOutline,
    globeOutline,
    ellipsisHorizontalOutline
} from 'ionicons/icons';
import './Tabs.css';
import { AppContext } from '../contexts/AppContext';
import Page from '../pages/Page';
import { useQuery } from '../hooks/CustomHooks';

const Tabs: React.FC = () => {
    const { state, } = useContext(AppContext);
    const loggedIn = state?.User?.UserID;
    const query = useQuery();
    const history = useHistory();
    const redirect = query.get('redirect');
    if (redirect) {
        history.push(redirect);
    }
    return (
        <Fragment>
            {/* Note: Do not put if-statement in IonTabButton level. use hidden property */}
            <IonTabs>
                <IonRouterOutlet>
                    <Route path="/:name/:id?" component={Page} exact />
                    <Redirect from="/" to="/Home" exact />
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                    <IonTabButton tab="home" href="/Home">
                        <IonIcon src="assets/icon/home-outline-1.svg"></IonIcon>
                    </IonTabButton>
                    <IonTabButton tab="search" href="/Search">
                        <IonIcon icon={searchOutline} />
                    </IonTabButton>
                    <IonTabButton hidden={loggedIn} tab="login" href="/Login">
                        <IonIcon icon={logInOutline} />
                    </IonTabButton>
                    {/* <IonTabButton hidden={loggedIn} tab="welcome" href="/Home">
                        <IonIcon icon={checkmarkOutline} />
                    </IonTabButton> */}
                    <IonTabButton hidden={!loggedIn} tab="favorite" href="/Favorite">
                        <IonIcon icon={heartOutline} />
                    </IonTabButton>
                    <IonTabButton hidden={!loggedIn} tab="profile" href="/Profile">
                        <IonIcon icon={personCircleOutline} />
                    </IonTabButton>
                    <IonTabButton hidden={state?.User?.UserType!=='A'} tab="admin" href="/admin/organization/unverified">
                        <IonIcon icon={globeOutline} />
                    </IonTabButton>
                    <IonTabButton tab="about" href="/About">
                        <IonIcon icon={ellipsisHorizontalOutline} />
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        </Fragment>
    );
};

export default Tabs;
